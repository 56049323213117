<template>
  <div class="listGamesComponentContainer">
    <div class="listGamesComponentContent">
      <div class="listGamesListingContainer">
        <div v-if="games.length" class="listGamesListingContent">
          <div class="listGamesListingLink">
            <div class="listGamesListingItem">
              <div class="medium">Date</div>
              <div class="medium d-none d-md-block">Competition</div>
              <div class="large">{{ $t("game") }}</div>
              <div class="tiny">{{ $t("players") }}</div>
              <div class="customSmall" v-if="getCurrentScout.isManager">
                Scout
              </div>
              <div class="tiny d-none d-md-block">Type</div>
              <div class="tiny">
                {{ $t("status") }}
              </div>
            </div>
          </div>
          <router-link
            v-for="(game, gameIndex) in games"
            :key="gameIndex"
            :to="`/game/${game.id}`"
            @click="markCurrentGameAsSeen(game)"
            class="listGamesListingLink"
          >
            <div
              :class="{
                listGamesListingItem: true,
                passed: isPassed(game),
                highlighted: isHighlited(game),
              }"
            >
              <div class="medium">
                {{ formatDate(game.date, false, "DD/MM/YYYY - HH:mm") }}
              </div>
              <div class="medium d-none d-md-block">
                {{ game.competitionLabel ? game.competitionLabel : "N/A" }}
              </div>
              <div class="large">{{ game.teamHome }} - {{ game.teamAway }}</div>
              <div class="tiny text-center">
                {{ getCurrentUserPlayersToDisplay(game) }}
              </div>
              <div
                class="customSmall"
                v-if="game.addedBy && getCurrentScout.isManager"
              >
                {{ identifyScoutById(game.addedBy, "lastname") }}
              </div>
              <div
                class="customSmall"
                v-else-if="
                  !game.addedBy &&
                  game.scoutAssigned &&
                  getCurrentScout.isManager
                "
              >
                {{ identifyScoutById(game.scoutAssigned, "lastname") }}
              </div>
              <div class="tiny d-none d-md-block">
                {{ $t(game.location) }}
              </div>
              <div
                class="tiny"
                v-if="
                  getCurrentScout.isManager &&
                  (getCurrentUserPlayersToDisplay(game) !== 0 ||
                    game.note?.length) &&
                  !isMarkedAsSeen(game) &&
                  game.date > readLimit
                "
              >
                {{ $t("reportStatus.validatedAndToRead") }}
              </div>
              <div
                class="tiny"
                v-else-if="
                  getCurrentScout.isManager &&
                  (((getCurrentUserPlayersToDisplay(game) !== 0 ||
                    game.note?.length) &&
                    isMarkedAsSeen(game)) ||
                    game.date <= readLimit)
                "
              >
                {{ $t("reportStatus.validatedAndRead") }}
              </div>
              <div
                v-else-if="
                  game.date > readLimit &&
                  !getCurrentScout.isManager &&
                  (game.note || getCurrentUserPlayersToDisplay(game))
                "
                class="tiny"
              >
                {{ $t("reportStatus.validated") }}
              </div>
              <div class="tiny" v-else-if="isToObserve(game)">
                {{ $t("reportStatus.toObserve") }}
              </div>
              <div class="tiny" v-else-if="game.date <= readLimit">
                {{ $t("reportStatus.validated") }}
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <p v-if="!games.length">
        {{ $t("no.resultForFilteredGames") }}
        {{ $t("retryWithOtherFilters") }} {{ $t("or") }}
        <span @click="resetFilters()">{{ $t("resetFilters") }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import mixinDates from "../mixins/mixinDates";
import mixinScouts from "../mixins/mixinScouts";
import db from "../db";
export default {
  name: "ListGames",
  mixins: [mixinDates, mixinScouts],
  props: {
    games: { type: Array, default: () => [] },
    readLimit: { type: Number, default: 1698793200 },
  },
  emits: ["resetFilters"],
  computed: {
    ...mapGetters(["getCompanies", "getCurrentScout"]),
  },
  methods: {
    isToObserve(game) {
      return (
        (game.date >= this.readLimit &&
          !this.getCurrentScout.isManager &&
          !game.note &&
          this.getCurrentUserPlayersToDisplay(game) === 0) ||
        (this.getCurrentScout.isManager &&
          (game.note || !this.getCurrentUserPlayersToDisplay(game)) &&
          game.date >= this.readLimit)
      );
    },
    resetFilters() {
      this.$emit("resetFilters");
    },
    isPassed(game) {
      if (!this.getCurrentScout.isManager) {
        return false;
      }
      return (
        (this.isMarkedAsSeen(game) &&
          (this.getCurrentUserPlayersToDisplay(game) !== 0 ||
            game.note?.length)) ||
        game.date <= this.readLimit
      );
    },
    isHighlited(game) {
      if (game.date && game.date <= this.readLimit) {
        return false;
      }
      if (this.getCurrentScout.isManager) {
        return (
          (this.getCurrentUserPlayersToDisplay(game) !== 0 ||
            game.note?.length) &&
          !this.isMarkedAsSeen(game)
        );
      } else {
        return (
          this.getCurrentUserPlayersToDisplay(game) === 0 && !game.note?.length
        );
      }
    },
    isMarkedAsSeen(game) {
      return game && game[`seenBy${this.getCurrentScout.id.toString()}`];
    },
    markCurrentGameAsSeen(game) {
      if (!game || !game.id) {
        return;
      }
      const seenBy = {};
      seenBy[`seenBy${this.getCurrentScout.id.toString()}`] = true;
      db.collection("V3games")
        .doc(game.id.toString())
        .set(seenBy, { merge: true });
      this.$router.push({ path: `/game/${game.id}` });
    },
    getGameStatus(game) {
      let isValidReport = false;
      if (
        game.companyGame &&
        game.companyGame === this.getCurrentScout?.company &&
        game.note
      ) {
        isValidReport = true;
      } else if (this.getCurrentScout.company && this.getCompanies[0].id) {
        this.getCompanies.forEach((company) => {
          if (
            game[`${company.id}Players`] &&
            game[`${company.id}Players`].length
          ) {
            isValidReport = true;
          }
        });
        return isValidReport
          ? this.$t("validReport")
          : this.$t("noValidReport");
      } else if (
        this.getCurrentScout.isManager &&
        game[`${this.getCurrentScout.company}Players`] &&
        game[`${this.getCurrentScout.company}Players`].length
      ) {
        return this.$t("validReport");
      } else if (
        !this.getCurrentScout.isManager &&
        game[`${this.getCurrentScout.id}Players`] &&
        game[`${this.getCurrentScout.id}Players`].length
      ) {
        return this.$t("validReport");
      }
      return this.$t("noValidReport");
    },
    getCurrentUserPlayersToDisplay(game) {
      if (this.getCurrentScout.company === this.getCompanies[0].id) {
        return game.playersLength || 0;
      } else if (this.getCurrentScout.isManager) {
        return game[`${this.getCurrentScout.company}PlayersLength`] || 0;
      } else {
        return game[`${this.getCurrentScout.id}PlayersLength`] || 0;
      }
    },
  },
};
</script>
<style lang="scss" scope>
@import "../assets/scss/variables.scss";
.listGamesComponentContent > p {
  color: black;
  text-align: center;
}
.listGamesComponentContent > p span {
  color: #060052;
  text-decoration: underline;
  cursor: pointer;
}
.listGamesComponentContainer {
  color: white;
  padding-bottom: 30px;
}

.listGamesComponentContent {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 10px;
}

.listGamesListingLink {
  background: white;
  display: block;
  width: 100%;
}

.listGamesListingLink:not(:first-of-type) {
  cursor: pointer;
}

.listGamesListingItem {
  display: flex;
  border-radius: 4px;
  color: $blueDark;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 16px 10px;
  border: 1px solid #f0f0f0;
  white-space: nowrap;
  border-radius: 4px;
  margin: 12px 0;
  background: white;
}

.listGamesListingItem > div:first-of-type {
  padding-left: 10px;
}

.listGamesListingItem.highlighted {
  font-weight: bold;
  background-color: #e3e0ff;
  position: relative;
}

.listGamesListingItem.passed {
  background-color: #f0f0f0;
}

.listGamesListingItem.highlighted:before {
  content: "";
  display: block;
  width: 8px;
  height: 100%;
  background: $blueDark;
  position: absolute;
  left: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.listGamesListingContainer {
  overflow-x: auto;
}

.listGamesListingContent
  div.listGamesListingLink:first-of-type
  .listGamesListingItem {
  background-color: #141414;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: white;
  font-weight: bold;
  display: flex;
  padding: 12px 10px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  font-size: 0.9rem;
}

.listGamesListingContent {
  padding: 24px;
  background: white;
  width: 100%;
}

.listGamesListingContent
  .listGamesListingLink:first-of-type
  .listGamesListingItem
  .tiny,
.listGamesListingContent .tiny {
  flex: 1;
  min-width: 100px;
}

.listGamesListingContent
  .listGamesListingLink:first-of-type
  .listGamesListingItem
  .customSmall,
.listGamesListingContent .customSmall {
  flex: 2;
}

.listGamesListingContent
  .listGamesListingLink:first-of-type
  .listGamesListingItem
  .medium,
.listGamesListingContent .medium {
  flex: 3;
}
.listGamesListingContent
  .listGamesListingLink:first-of-type
  .listGamesListingItem
  .large,
.listGamesListingContent .large {
  flex: 5;
}

.listGamesListingLink:hover {
  text-decoration: none;
}

.listGamesListingItem > div,
.listGamesListingContent
  .listGamesListingLink:first-of-type
  .listGamesListingItem
  > div {
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 992px) {
  .listGamesListingLink {
    width: 100%;
  }

  .listGamesListingContent {
    width: fit-content;
    min-width: 100%;
  }
  .listGamesListingContent
    .listGamesListingLink:first-of-type
    .listGamesListingItem {
    width: 100%;
  }
  .listGamesListingContent
    .listGamesListingLink:first-of-type
    .listGamesListingItem
    .tiny,
  .listGamesListingContent .tiny {
    min-width: 100px;
  }
}

@media screen and (max-width: 576px) {
  .listGamesListingContent
    .listGamesListingLink:first-of-type
    .listGamesListingItem,
  .listGamesListingLink {
    font-size: 1rem;
  }
}
</style>
