<template>
  <div>
    <b-sidebar
      id="sidebarFilterPlayers"
      backdrop-variant="dark"
      :title="$t('filterSearch')"
      v-model="sidebarDisplayStatus"
      backdrop
      shadow
    >
      <div class="px-3 py-2">
        <b-form @submit.prevent="filterSearch">
          <b-form-group id="technicalData" class="text-left">
            <legend
              :class="{
                'col-form-label': true,
                'opened': toggleableFilter['technical'] === true,
              }"
              @click="toggleFilter('technical')"
            >
              {{ $t("data.technical") }}
              <span v-if="!toggleableFilter['technical']">
                {{ getContainerFilterCounter("technical") }}
              </span>
            </legend>
            <div
              v-if="toggleableFilter['technical']"
              class="collapsableContainer"
            >
              <b-row class="filtersTitleContainer">
                <b-col>
                  {{ $t("filters.position") }}
                  <span
                    v-if="
                      maxPositions - positionsSelected.length !== maxPositions
                    "
                  >
                    ({{ maxPositions - positionsSelected.length }}
                    {{
                      $tc(
                        "moreMax",
                        maxPositions - positionsSelected.length || 1
                      )
                    }})
                  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="filterContainer">
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="goalkeeper"
                      :checked="positionsSelected.includes('Goalkeeper')"
                      @click="togglePositionsSelected('Goalkeeper')"
                    >
                      {{ $t("position.goalkeeper") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="rightBack"
                      :checked="positionsSelected.includes('Right Back')"
                      @click="togglePositionsSelected('Right Back')"
                    >
                      {{ $t("position.rightBack") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="rightCentreBack"
                      :checked="positionsSelected.includes('Right Centre Back')"
                      @click="togglePositionsSelected('Right Centre Back')"
                    >
                      {{ $t("position.rightCentreBack") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="centreBack"
                      :checked="positionsSelected.includes('Centre Back')"
                      @click="togglePositionsSelected('Centre Back')"
                    >
                      {{ $t("position.centreBack") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="leftCentreBack"
                      :checked="positionsSelected.includes('Left Centre Back')"
                      @click="togglePositionsSelected('Left Centre Back')"
                    >
                      {{ $t("position.leftCentreBack") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="leftBack"
                      :checked="positionsSelected.includes('Left Back')"
                      @click="togglePositionsSelected('Left Back')"
                    >
                      {{ $t("position.leftBack") }}
                    </label>
                  </div>

                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="rightWingBack"
                      :checked="positionsSelected.includes('Right Wing Back')"
                      @click="togglePositionsSelected('Right Wing Back')"
                    >
                      {{ $t("position.rightWingBack") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="leftWingBack"
                      :checked="positionsSelected.includes('Left Wing Back')"
                      @click="togglePositionsSelected('Left Wing Back')"
                    >
                      {{ $t("position.leftWingBack") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="rightDefensiveMidfielder"
                      :checked="
                        positionsSelected.includes('Right Defensive Midfielder')
                      "
                      @click="
                        togglePositionsSelected('Right Defensive Midfielder')
                      "
                    >
                      {{ $t("position.rightDefensiveMidfielder") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="centreDefensiveMidfielder"
                      :checked="
                        positionsSelected.includes(
                          'Centre Defensive Midfielder'
                        )
                      "
                      @click="
                        togglePositionsSelected('Centre Defensive Midfielder')
                      "
                    >
                      {{ $t("position.centreDefensiveMidfielder") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="leftDefensiveMidfielder"
                      :checked="
                        positionsSelected.includes('Left Defensive Midfielder')
                      "
                      @click="
                        togglePositionsSelected('Left Defensive Midfielder')
                      "
                    >
                      {{ $t("position.leftDefensiveMidfielder") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="rightMidfielder"
                      :checked="positionsSelected.includes('Right Midfielder')"
                      @click="togglePositionsSelected('Right Midfielder')"
                    >
                      {{ $t("position.rightMidfielder") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="rightCentreMidfielder"
                      :checked="
                        positionsSelected.includes('Right Centre Midfielder')
                      "
                      @click="
                        togglePositionsSelected('Right Centre Midfielder')
                      "
                    >
                      {{ $t("position.rightCentreMidfielder") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="centreMidfielder"
                      :checked="positionsSelected.includes('Centre Midfielder')"
                      @click="togglePositionsSelected('Centre Midfielder')"
                    >
                      {{ $t("position.centreMidfielder") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="leftCentreMidfielder"
                      :checked="
                        positionsSelected.includes('Left Centre Midfielder')
                      "
                      @click="togglePositionsSelected('Left Centre Midfielder')"
                    >
                      {{ $t("position.leftCentreMidfielder") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="leftMidfielder"
                      :checked="positionsSelected.includes('Left Midfielder')"
                      @click="togglePositionsSelected('Left Midfielder')"
                    >
                      {{ $t("position.leftMidfielder") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="rightWing"
                      :checked="positionsSelected.includes('Right Wing')"
                      @click="togglePositionsSelected('Right Wing')"
                    >
                      {{ $t("position.rightWing") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="rightAttackingMidfielder"
                      :checked="
                        positionsSelected.includes('Right Attacking Midfielder')
                      "
                      @click="
                        togglePositionsSelected('Right Attacking Midfielder')
                      "
                    >
                      {{ $t("position.rightAttackingMidfielder") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="centreAttackingMidfielder"
                      :checked="
                        positionsSelected.includes(
                          'Centre Attacking Midfielder'
                        )
                      "
                      @click="
                        togglePositionsSelected('Centre Attacking Midfielder')
                      "
                    >
                      {{ $t("position.centreAttackingMidfielder") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="leftAttackingMidfielder"
                      :checked="
                        positionsSelected.includes('Left Attacking Midfielder')
                      "
                      @click="
                        togglePositionsSelected('Left Attacking Midfielder')
                      "
                    >
                      {{ $t("position.leftAttackingMidfielder") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="leftWing"
                      :checked="positionsSelected.includes('Left Wing')"
                      @click="togglePositionsSelected('Left Wing')"
                    >
                      {{ $t("position.leftWing") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="rightCentreForward"
                      :checked="
                        positionsSelected.includes('Right Centre Forward')
                      "
                      @click="togglePositionsSelected('Right Centre Forward')"
                    >
                      {{ $t("position.rightCentreForward") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="centreForward"
                      :checked="positionsSelected.includes('Centre Forward')"
                      @click="togglePositionsSelected('Centre Forward')"
                    >
                      {{ $t("position.centreForward") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="leftCentreForward"
                      :checked="
                        positionsSelected.includes('Left Centre Forward')
                      "
                      @click="togglePositionsSelected('Left Centre Forward')"
                    >
                      {{ $t("position.leftCentreForward") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      class="playersPositions"
                      id="secondaryStriker"
                      :checked="positionsSelected.includes('Secondary Striker')"
                      @click="togglePositionsSelected('Secondary Striker')"
                    >
                      {{ $t("position.secondaryStriker") }}
                    </label>
                  </div>
                </b-col>
              </b-row>
              <b-row class="filtersTitleContainer">
                <b-col>
                  {{ $t("filters.foot") }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="filterContainer">
                  <div class="playerSelectContainer">
                    <label
                      :class="{
                        playerFoot: true,
                        isSelected: footSelected === 'left',
                      }"
                      id="leftfoot"
                      @click="changeFootSelected('left')"
                    >
                      {{ $t("left") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      :class="{
                        playerFoot: true,
                        isSelected: footSelected === 'right',
                      }"
                      id="rightFoot"
                      @click="changeFootSelected('right')"
                    >
                      {{ $t("right") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      :class="{
                        playerFoot: true,
                        isSelected: footSelected === 'both',
                      }"
                      id="ambidextrousFoot"
                      @click="changeFootSelected('both')"
                    >
                      {{ $t("ambidextrous") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      :class="{
                        playerFoot: true,
                        isSelected: footSelected === '',
                      }"
                      id="footNoMatter"
                      @click="changeFootSelected('')"
                    >
                      {{ $t("no.matter") }}
                    </label>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-form-group>
          <b-form-group id="contractualData" class="text-left">
            <legend
              :class="{
                'col-form-label': true,
                'opened': toggleableFilter['contractual'] === true,
              }"
              @click="toggleFilter('contractual')"
            >
              {{ $t("data.contractual") }}
              <span v-if="!toggleableFilter['contractual']">
                {{ getContainerFilterCounter("contractual") }}
              </span>
            </legend>
            <div
              v-if="toggleableFilter['contractual']"
              class="collapsableContainer"
            >
              <b-row class="filtersTitleContainer">
                <b-col>
                  {{ $t("filters.price") }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="filterContainer">
                  <b-row class="filterKeyboardSelector">
                    <b-col>
                      Min
                      <span
                        contenteditable="true"
                        :class="{ edited: priceRange[0] !== 0 }"
                        @blur="changeInputCursor($event, 'priceRange', 0)"
                      >
                        {{ getRangeByIndex(0, priceRange) }}
                      </span>
                    </b-col>
                    <b-col>
                      Max
                      <span
                        contenteditable="true"
                        :class="{ edited: priceRange[1] !== maxPrice }"
                        @blur="changeInputCursor($event, 'priceRange', 1)"
                      >
                        {{ getRangeByIndex(1, priceRange) }}
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <div
                    class="resetButtonContainer"
                    v-if="priceRange[0] != 0 || priceRange[1] != maxPrice"
                  >
                    <button
                      class="resetButton"
                      @click="resetFilter('priceRange', 0, maxPrice)"
                    >
                      {{ $t("filters.reset") }}
                    </button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="filtersTitleContainer">
                <b-col>
                  {{ $t("filters.endContractInMonths") }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="filterContainer">
                  <b-row class="filterKeyboardSelector">
                    <b-col>
                      Min
                      <span
                        contenteditable="true"
                        :class="{ edited: endContractRange[0] !== 0 }"
                        @blur="changeInputCursor($event, 'endContractRange', 0)"
                      >
                        {{ getRangeByIndex(0, endContractRange) }}
                      </span>
                    </b-col>
                    <b-col>
                      Max
                      <span
                        contenteditable="true"
                        :class="{
                          edited: endContractRange[1] !== maxEndContract,
                        }"
                        @blur="changeInputCursor($event, 'endContractRange', 1)"
                      >
                        {{ getRangeByIndex(1, endContractRange) }}
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <div
                    class="resetButtonContainer"
                    v-if="
                      endContractRange[0] != 0 ||
                      endContractRange[1] != maxEndContract
                    "
                  >
                    <button
                      class="resetButton"
                      @click="resetFilter('endContractRange', 0, 96)"
                    >
                      {{ $t("filters.reset") }}
                    </button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-form-group>
          <b-form-group id="athleticalData" class="text-left">
            <legend
              :class="{
                'col-form-label': true,
                'opened': toggleableFilter['status'] === true,
              }"
              @click="toggleFilter('status')"
            >
              {{ getStatusAndData() }}
              <span v-if="!toggleableFilter['status']">
                {{ getContainerFilterCounter("status") }}
              </span>
            </legend>
            <div v-if="toggleableFilter['status']" class="collapsableContainer">
              <b-row class="filtersTitleContainer">
                <b-col>
                  {{ $t("filters.commentedGamesIdsLength") }}
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="playerSelectContainer">
                    <label
                      :class="{
                        bucketPlayers: true,
                        isSelected: displayBucketPlayers,
                      }"
                      @click="displayBucketPlayers = !displayBucketPlayers"
                    >
                      {{ $t("gameViewlegend.bucketPlayer") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      :class="{
                        bucketPlayers: true,
                        isSelected: displayNotCommentedPlayers,
                      }"
                      @click="
                        displayNotCommentedPlayers = !displayNotCommentedPlayers
                      "
                    >
                      {{ $t("playerStatus.notCommentedPlayers") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      :class="{
                        bucketPlayers: true,
                        isSelected: displayCommentedPlayers,
                      }"
                      @click="
                        displayCommentedPlayers = !displayCommentedPlayers
                      "
                    >
                      {{ $t("playerStatus.commentedPlayers") }}
                    </label>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-form-group>
          <b-form-group id="personalData" class="text-left">
            <legend
              :class="{
                'col-form-label': true,
                'opened': toggleableFilter['personal'] === true,
              }"
              @click="toggleFilter('personal')"
            >
              {{ $t("data.personal") }}
              <span v-if="!toggleableFilter['personal']">
                {{ getContainerFilterCounter("personal") }}
              </span>
            </legend>
            <div
              v-if="toggleableFilter['personal']"
              class="collapsableContainer"
            >
              <b-row class="filtersTitleContainer">
                <b-col>
                  {{ $t("birth") }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="filterContainer">
                  <b-row class="filterKeyboardSelector">
                    <b-col>
                      Min
                      <span
                        contenteditable="true"
                        :class="{ edited: yearsRange[0] !== 1971 }"
                        @blur="changeInputCursor($event, 'yearsRange', 0)"
                      >
                        {{ getRangeByIndex(0, yearsRange) }}
                      </span>
                    </b-col>
                    <b-col>
                      Max
                      <span
                        contenteditable="true"
                        :class="{
                          edited: yearsRange[1] !== currentYear,
                        }"
                        @blur="changeInputCursor($event, 'yearsRange', 1)"
                      >
                        {{ getRangeByIndex(1, yearsRange) }}
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    class="resetButtonContainer"
                    v-if="
                      yearsRange[0] !== 1971 || yearsRange[1] !== currentYear
                    "
                  >
                    <button
                      class="resetButton"
                      @click="resetFilter('yearsRange', 1971, currentYear)"
                    >
                      {{ $t("filters.reset") }}
                    </button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="filtersTitleContainer">
                <b-col>
                  {{ $t("filters.category") }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="filterContainer">
                  <div class="playerSelectContainer">
                    <label
                      :class="{
                        playersCategory: true,
                        isSelected: communalFilter === 'ue',
                      }"
                      id="communal"
                      @click="communalFilter = 'ue'"
                    >
                      {{ $t("communal") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      :class="{
                        playersCategory: true,
                        isSelected: communalFilter === 'non-ue',
                      }"
                      id="communal"
                      @click="communalFilter = 'non-ue'"
                    >
                      {{ $t("extraCommunal") }}
                    </label>
                  </div>
                  <div class="playerSelectContainer">
                    <label
                      :class="{
                        playersCategory: true,
                        isSelected: communalFilter === '',
                      }"
                      id="communal"
                      @click="communalFilter = ''"
                    >
                      {{ $t("no.matter") }}
                    </label>
                  </div>
                </b-col>
              </b-row>
              <b-row class="filtersTitleContainer">
                <b-col>
                  {{ $t("filters.height") }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="filterContainer">
                  <b-row class="filterKeyboardSelector">
                    <b-col>
                      Min
                      <span
                        contenteditable="true"
                        :class="{ edited: heightRange[0] !== 0 }"
                        @blur="changeInputCursor($event, 'heightRange', 0)"
                      >
                        {{ getRangeByIndex(0, heightRange) }}
                      </span>
                    </b-col>
                    <b-col>
                      Max
                      <span
                        contenteditable="true"
                        :class="{ edited: heightRange[1] !== maxHeight }"
                        @blur="changeInputCursor($event, 'heightRange', 1)"
                      >
                        {{ getRangeByIndex(1, heightRange) }}
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    class="resetButtonContainer"
                    v-if="heightRange[0] != 0 || heightRange[1] != maxHeight"
                  >
                    <button
                      class="resetButton"
                      @click="resetFilter('heightRange', 0, maxHeight)"
                    >
                      {{ $t("filters.reset") }}
                    </button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="filtersTitleContainer">
                <b-col>
                  {{ $t("filters.weight") }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="filterContainer">
                  <b-row class="filterKeyboardSelector">
                    <b-col>
                      Min
                      <span
                        contenteditable="true"
                        :class="{ edited: weightRange[0] !== 0 }"
                        @blur="changeInputCursor($event, 'weightRange', 0)"
                      >
                        {{ getRangeByIndex(0, weightRange) }}
                      </span>
                    </b-col>
                    <b-col>
                      Max
                      <span
                        contenteditable="true"
                        :class="{ edited: weightRange[1] !== maxWeight }"
                        @blur="changeInputCursor($event, 'weightRange', 1)"
                      >
                        {{ getRangeByIndex(1, weightRange) }}
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    class="resetButtonContainer"
                    v-if="weightRange[0] != 0 || weightRange[1] != maxWeight"
                  >
                    <button
                      class="resetButton"
                      @click="resetFilter('weightRange', 0, maxWeight)"
                    >
                      {{ $t("filters.reset") }}
                    </button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-form-group>
          <b-form-group id="athleticalData" class="text-left">
            <legend
              :class="{
                'col-form-label': true,
                'opened': toggleableFilter['athletical'] === true,
              }"
              @click="toggleFilter('athletical')"
            >
              {{ $t("data.athletical") }}
              <span v-if="!toggleableFilter['athletical']">
                {{ getContainerFilterCounter("athletical") }}
              </span>
            </legend>
            <div
              v-if="toggleableFilter['athletical']"
              class="collapsableContainer"
            >
              <b-row class="filtersTitleContainer">
                <b-col>
                  {{ $t("filters.maxSpeed") }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="filterContainer">
                  <b-row class="filterKeyboardSelector">
                    <b-col>
                      Min
                      <span
                        contenteditable="true"
                        :class="{ edited: maxSpeedRange[0] !== 0 }"
                        @blur="changeInputCursor($event, 'maxSpeedRange', 0)"
                      >
                        {{ getRangeByIndex(0, maxSpeedRange) }}
                      </span>
                    </b-col>
                    <b-col>
                      Max
                      <span
                        contenteditable="true"
                        :class="{ edited: maxSpeedRange[1] !== 100 }"
                        @blur="changeInputCursor($event, 'maxSpeedRange', 1)"
                      >
                        {{ getRangeByIndex(1, maxSpeedRange) }}
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    class="resetButtonContainer"
                    v-if="maxSpeedRange[0] != 0 || maxSpeedRange[1] != 100"
                  >
                    <button
                      class="resetButton"
                      @click="resetFilter('maxSpeedRange', 0, 100)"
                    >
                      {{ $t("filters.reset") }}
                    </button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="filtersTitleContainer">
                <b-col>
                  {{ $t("filters.totalDist") }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="filterContainer">
                  <b-row class="filterKeyboardSelector">
                    <b-col>
                      Min
                      <span
                        contenteditable="true"
                        :class="{ edited: totalDistRange[0] !== 0 }"
                        @blur="changeInputCursor($event, 'totalDistRange', 0)"
                      >
                        {{ getRangeByIndex(0, totalDistRange) }}
                      </span>
                    </b-col>
                    <b-col>
                      Max
                      <span
                        contenteditable="true"
                        :class="{ edited: totalDistRange[1] !== 100 }"
                        @blur="changeInputCursor($event, 'totalDistRange', 1)"
                      >
                        {{ getRangeByIndex(1, totalDistRange) }}
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    class="resetButtonContainer"
                    v-if="totalDistRange[0] != 0 || totalDistRange[1] != 100"
                  >
                    <button
                      class="resetButton"
                      @click="resetFilter('totalDistRange', 0, 100)"
                    >
                      {{ $t("filters.reset") }}
                    </button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="filtersTitleContainer">
                <b-col>
                  {{ $t("filters.distMoreThan20kmh") }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="filterContainer">
                  <b-row class="filterKeyboardSelector">
                    <b-col>
                      Min
                      <span
                        contenteditable="true"
                        :class="{ edited: distMoreThan20kmhRange[0] !== 0 }"
                        @blur="
                          changeInputCursor($event, 'distMoreThan20kmhRange', 0)
                        "
                      >
                        {{ getRangeByIndex(0, distMoreThan20kmhRange) }}
                      </span>
                    </b-col>
                    <b-col>
                      Max
                      <span
                        contenteditable="true"
                        :class="{ edited: distMoreThan20kmhRange[1] !== 100 }"
                        @blur="
                          changeInputCursor($event, 'distMoreThan20kmhRange', 1)
                        "
                      >
                        {{ getRangeByIndex(1, distMoreThan20kmhRange) }}
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    class="resetButtonContainer"
                    v-if="
                      distMoreThan20kmhRange[0] != 0 ||
                      distMoreThan20kmhRange[1] != 100
                    "
                  >
                    <button
                      class="resetButton"
                      @click="resetFilter('distMoreThan20kmhRange', 0, 100)"
                    >
                      {{ $t("filters.reset") }}
                    </button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="filtersTitleContainer">
                <b-col>
                  {{ $t("filters.nbAccelerationMore3mPerSec") }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="filterContainer">
                  <b-row class="filterKeyboardSelector">
                    <b-col>
                      Min
                      <span
                        contenteditable="true"
                        :class="{
                          edited: accelerationMoreThan3mRange[0] !== 0,
                        }"
                        @blur="
                          changeInputCursor(
                            $event,
                            'accelerationMoreThan3mRange',
                            0
                          )
                        "
                      >
                        {{ getRangeByIndex(0, accelerationMoreThan3mRange) }}
                      </span>
                    </b-col>
                    <b-col>
                      Max
                      <span
                        contenteditable="true"
                        :class="{
                          edited: accelerationMoreThan3mRange[1] !== 100,
                        }"
                        @blur="
                          changeInputCursor(
                            $event,
                            'accelerationMoreThan3mRange',
                            1
                          )
                        "
                      >
                        {{ getRangeByIndex(1, accelerationMoreThan3mRange) }}
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    class="resetButtonContainer"
                    v-if="
                      accelerationMoreThan3mRange[0] != 0 ||
                      accelerationMoreThan3mRange[1] != 100
                    "
                  >
                    <button
                      class="resetButton"
                      @click="
                        resetFilter('accelerationMoreThan3mRange', 0, 100)
                      "
                    >
                      {{ $t("filters.reset") }}
                    </button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-form-group>
          <div v-if="getActiveFiltersLength" class="submitContainer">
            <b-button
              class="doRequest submit mt-4"
              type="submit"
              variant="primary"
            >
              {{ $t("search.dot") }}
            </b-button>
          </div>
        </b-form>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import mixinTranslation from "../mixins/mixinTranslation";
import moment from "moment";
export default {
  name: "SidebarFilterPlayers",
  mixins: [mixinTranslation],
  data() {
    return {
      displayBucketPlayers: false,
      displayNotCommentedPlayers: false,
      displayCommentedPlayers: false,
      maxPositions: 8,
      sidebarDisplayStatus: false,
      positionsSelected: [],
      filtersSelected: [],
      footSelected: null,
      toggleableFilter: {
        technical: true,
        contractual: true,
        status: true,
        personal: true,
        athletical: true,
      },
      priceRange: [0, 200],
      endContractRange: [0, 96],
      heightRange: [0, 150],
      weightRange: [0, 120],
      maxSpeedRange: [0, 100],
      totalDistRange: [0, 100],
      distMoreThan20kmhRange: [0, 100],
      accelerationMoreThan3mRange: [0, 100],
      yearsRange: [1971, parseInt(moment().format("YYYY"))],
      maxEndContract: 96,
      maxWeight: 120,
      maxPrice: 200,
      maxHeight: 150,
      communalFilter: null,
      isActivePriceRange: false,
      isActiveYearsRange: false,
      isActiveEndContractRange: false,
      isActiveHeightRange: false,
      isActiveWeightRange: false,
      isActiveMaxSpeedRange: false,
      isActiveTotalDistRange: false,
      isActiveDistMoreThan20kmh: false,
      isActiveAccelerationMoreThan3m: false,
    };
  },
  emits: [
    "clearReload",
    "adaptMatchingResults",
    "doMountRequest",
    "toggleSidebarFromSidebar",
  ],
  props: {
    displaySidebar: Boolean,
    propsDisplayCommentedPlayers: Boolean,
    currentYear: Number,
    reloadData: Boolean,
  },
  mounted() {
    this.checkDefaultFilters();
  },
  computed: {
    ...mapState(["currentScoutId", "locale"]),
    ...mapActions(["setPlayerSearchFilters"]),
    ...mapGetters(["getPlayersFilters"]),
    getActiveFiltersLength() {
      const self = this;
      let filtersCounter = [
        self.positionsSelected.length !== 0,
        self.footSelected && self.footSelected.length !== 0,
        self.displayBucketPlayers ||
          self.displayCommentedPlayers ||
          self.displayNotCommentedPlayers,
        self.communalFilter && self.communalFilter.length !== 0,
        self.isActivePriceRange,
        self.isActiveYearsRange,
        self.isActiveEndContractRange,
        self.isActiveHeightRange,
        self.isActiveWeightRange,
        self.isActiveMaxSpeedRange,
        self.isActiveTotalDistRange,
        self.isActiveDistMoreThan20kmh,
        self.isActiveAccelerationMoreThan3m,
      ].filter((element) => {
        return element === true;
      });
      return filtersCounter.length;
    },
  },
  methods: {
    checkDefaultFilters() {
      if (Object.keys(this.getPlayersFilters).length) {
        this.accelerationMoreThan3mRange =
          this.getPlayersFilters.accelerationMoreThan3mRange;
        this.communalFilter = this.getPlayersFilters.communalFilter;
        this.displayBucketPlayers = this.getPlayersFilters.displayBucketPlayers;
        this.displayCommentedPlayers =
          this.getPlayersFilters.displayCommentedPlayers;
        this.displayNotCommentedPlayers =
          this.getPlayersFilters.displayNotCommentedPlayers;
        this.distMoreThan20kmhRange =
          this.getPlayersFilters.distMoreThan20kmhRange;
        this.endContractRange = this.getPlayersFilters.endContractRange;
        this.footSelected = this.getPlayersFilters.footSelected;
        this.heightRange = this.getPlayersFilters.heightRange;
        this.isActiveAccelerationMoreThan3m =
          this.getPlayersFilters.isActiveAccelerationMoreThan3m;
        this.isActiveDistMoreThan20kmh =
          this.getPlayersFilters.isActiveDistMoreThan20kmh;
        this.isActiveEndContractRange =
          this.getPlayersFilters.isActiveEndContractRange;
        this.isActiveHeightRange = this.getPlayersFilters.isActiveHeightRange;
        this.isActiveMaxSpeedRange =
          this.getPlayersFilters.isActiveMaxSpeedRange;
        this.isActivePriceRange = this.getPlayersFilters.isActivePriceRange;
        this.isActiveTotalDistRange =
          this.getPlayersFilters.isActiveTotalDistRange;
        this.isActiveWeightRange = this.getPlayersFilters.isActiveWeightRange;
        this.isActiveYearsRange = this.getPlayersFilters.isActiveYearsRange;
        this.maxSpeedRange = this.getPlayersFilters.maxSpeedRange;
        this.positionsSelected = this.getPlayersFilters.positionsSelected;
        this.priceRange = this.getPlayersFilters.priceRange;
        this.totalDistRange = this.getPlayersFilters.totalDistRange;
        this.weightRange = this.getPlayersFilters.weightRange;
        this.yearsRange = this.getPlayersFilters.yearsRange;
      }
    },
    clearFields() {
      this.displayBucketPlayers = false;
      this.displayNotCommentedPlayers = false;
      this.displayCommentedPlayers = false;
      this.maxPositions = 8;
      this.sidebarDisplayStatus = false;
      this.positionsSelected = [];
      this.filtersSelected = [];
      this.footSelected = null;
      this.toggleableFilter = {
        technical: true,
        contractual: true,
        status: true,
        personal: true,
        athletical: true,
      };
      this.priceRange = [0, 200];
      this.endContractRange = [0, 96];
      this.heightRange = [0, 150];
      this.weightRange = [0, 120];
      this.maxSpeedRange = [0, 100];
      this.totalDistRange = [0, 100];
      this.distMoreThan20kmhRange = [0, 100];
      this.accelerationMoreThan3mRange = [0, 100];
      this.yearsRange = [1971, parseInt(moment().format("YYYY"))];
      this.maxEndContract = 96;
      this.maxWeight = 120;
      this.maxPrice = 200;
      this.maxHeight = 150;
      this.communalFilter = null;
      this.isActivePriceRange = false;
      this.isActiveYearsRange = false;
      this.isActiveEndContractRange = false;
      this.isActiveHeightRange = false;
      this.isActiveWeightRange = false;
      this.isActiveMaxSpeedRange = false;
      this.isActiveTotalDistRange = false;
      this.isActiveDistMoreThan20kmh = false;
      this.isActiveAccelerationMoreThan3m = false;
      this.$emit("clearReload");
    },
    getContainerFilterCounter(filterContainer) {
      const self = this;
      let toReturn = 0;

      switch (filterContainer) {
        case "technical":
          if (self.positionsSelected.length) {
            toReturn++;
          }
          if (self.footSelected) {
            toReturn++;
          }
          break;
        case "contractual":
          if (self.isActivePriceRange) {
            toReturn++;
          }
          if (self.isActiveEndContractRange) {
            toReturn++;
          }
          break;
        case "status":
          if (self.displayBucketPlayers) {
            toReturn++;
          }
          if (self.displayNotCommentedPlayers || self.displayCommentedPlayers) {
            toReturn++;
          }
          break;
        case "personal":
          if (self.isActiveYearsRange) {
            toReturn++;
          }
          if (
            self.communalFilter === "ue" ||
            self.communalFilter === "non-ue"
          ) {
            toReturn++;
          }
          if (self.isActiveHeightRange) {
            toReturn++;
          }
          if (self.isActiveWeightRange) {
            toReturn++;
          }

          break;
        case "athletical":
          if (self.isActiveMaxSpeedRange) {
            toReturn++;
          }
          if (self.isActiveTotalDistRange) {
            toReturn++;
          }
          if (self.isActiveDistMoreThan20kmh) {
            toReturn++;
          }
          if (self.isActiveAccelerationMoreThan3m) {
            toReturn++;
          }
          break;
      }
      return toReturn ? "(" + toReturn + ")" : "";
    },
    resetFilter(element, firstValue, secondValue) {
      const self = this;
      switch (element) {
        case "priceRange":
          self.priceRange = [firstValue, secondValue];
          self.isActivePriceRange = false;
          break;
        case "yearsRange":
          self.yearsRange = [firstValue, secondValue];
          self.isActiveYearsRange = false;
          break;
        case "endContractRange":
          self.endContractRange = [firstValue, secondValue];
          self.isActiveEndContractRange = false;
          break;
        case "heightRange":
          self.heightRange = [firstValue, secondValue];
          self.isActiveHeightRange = false;
          break;
        case "weightRange":
          self.weightRange = [firstValue, secondValue];
          self.isActiveWeightRange = false;
          break;
        case "maxSpeedRange":
          self.maxSpeedRange = [firstValue, secondValue];
          self.isActiveMaxSpeedRange = false;
          break;
        case "totalDistRange":
          self.totalDistRange = [firstValue, secondValue];
          self.isActiveTotalDistRange = false;
          break;
        case "distMoreThan20kmhRange":
          self.distMoreThan20kmhRange = [firstValue, secondValue];
          self.isActiveDistMoreThan20kmh = false;
          break;
        case "accelerationMoreThan3mRange":
          self.accelerationMoreThan3mRange = [firstValue, secondValue];
          self.isActiveAccelerationMoreThan3m = false;
          break;
      }
    },
    changeInputCursor(event, element, position) {
      const self = this;
      switch (element) {
        case "priceRange":
          self.priceRange =
            position === 1
              ? [
                  self.priceRange[0],
                  parseFloat(
                    event.target.textContent.toString().replace(",", ".")
                  ),
                ]
              : [
                  parseFloat(
                    event.target.textContent.toString().replace(",", ".")
                  ),
                  self.priceRange[1],
                ];
          self.isActivePriceRange = true;
          break;
        case "yearsRange":
          self.yearsRange =
            position === 1
              ? [self.yearsRange[0], parseFloat(event.target.textContent)]
              : [parseFloat(event.target.textContent), self.yearsRange[1]];

          self.isActiveYearsRange = true;
          break;
        case "endContractRange":
          self.endContractRange =
            position === 1
              ? [self.endContractRange[0], parseFloat(event.target.textContent)]
              : [
                  parseFloat(event.target.textContent),
                  self.endContractRange[1],
                ];

          self.isActiveEndContractRange = true;
          break;
        case "maxSpeedRange":
          self.maxSpeedRange =
            position === 1
              ? [
                  self.maxSpeedRange[0],
                  parseFloat(
                    event.target.textContent.toString().replace(",", ".")
                  ),
                ]
              : [
                  parseFloat(
                    event.target.textContent.toString().replace(",", ".")
                  ),
                  self.maxSpeedRange[1],
                ];

          self.isActiveMaxSpeedRange = true;
          break;
        case "totalDistRange":
          self.totalDistRange =
            position === 1
              ? [
                  self.totalDistRange[0],
                  parseFloat(
                    event.target.textContent.toString().replace(",", ".")
                  ),
                ]
              : [
                  parseFloat(
                    event.target.textContent.toString().replace(",", ".")
                  ),
                  self.totalDistRange[1],
                ];

          self.isActiveTotalDistRange = true;
          break;
        case "accelerationMoreThan3mRange":
          self.accelerationMoreThan3mRange =
            position === 1
              ? [
                  self.accelerationMoreThan3mRange[0],
                  parseFloat(
                    event.target.textContent.toString().replace(",", ".")
                  ),
                ]
              : [
                  parseFloat(
                    event.target.textContent.toString().replace(",", ".")
                  ),
                  self.accelerationMoreThan3mRange[1],
                ];

          self.isActiveAccelerationMoreThan3m = true;
          break;
        case "distMoreThan20kmhRange":
          self.distMoreThan20kmhRange =
            position === 1
              ? [
                  self.distMoreThan20kmhRange[0],
                  parseFloat(
                    event.target.textContent.toString().replace(",", ".")
                  ),
                ]
              : [
                  parseFloat(
                    event.target.textContent.toString().replace(",", ".")
                  ),
                  self.distMoreThan20kmhRange[1],
                ];

          self.isActiveDistMoreThan20kmh = true;
          break;
        case "heightRange":
          self.heightRange =
            position === 1
              ? [
                  self.heightRange[0],
                  parseFloat(
                    event.target.textContent.toString().replace(",", ".")
                  ),
                ]
              : [
                  parseFloat(
                    event.target.textContent.toString().replace(",", ".")
                  ),
                  self.heightRange[1],
                ];

          self.isActiveHeightRange = true;
          break;
        case "weightRange":
          self.weightRange =
            position === 1
              ? [
                  self.weightRange[0],
                  parseFloat(
                    event.target.textContent.toString().replace(",", ".")
                  ),
                ]
              : [
                  parseFloat(
                    event.target.textContent.toString().replace(",", ".")
                  ),
                  self.weightRange[1],
                ];

          self.isActiveWeightRange = true;
          break;
      }
    },
    filterSearch() {
      const toReturn = {
        displayBucketPlayers: this.displayBucketPlayers,
        displayNotCommentedPlayers: this.displayNotCommentedPlayers,
        displayCommentedPlayers: this.displayCommentedPlayers,
        positionsSelected: this.positionsSelected,
        footSelected: this.footSelected,
        priceRange: this.priceRange,
        isActivePriceRange: this.isActivePriceRange,
        endContractRange: this.endContractRange,
        isActiveEndContractRange: this.isActiveEndContractRange,
        heightRange: this.heightRange,
        isActiveHeightRange: this.isActiveHeightRange,
        weightRange: this.weightRange,
        isActiveWeightRange: this.isActiveWeightRange,
        maxSpeedRange: this.maxSpeedRange,
        isActiveMaxSpeedRange: this.isActiveMaxSpeedRange,
        totalDistRange: this.totalDistRange,
        isActiveTotalDistRange: this.isActiveTotalDistRange,
        distMoreThan20kmhRange: this.distMoreThan20kmhRange,
        isActiveDistMoreThan20kmh: this.isActiveDistMoreThan20kmh,
        accelerationMoreThan3mRange: this.accelerationMoreThan3mRange,
        isActiveAccelerationMoreThan3m: this.isActiveAccelerationMoreThan3m,
        yearsRange: this.yearsRange,
        isActiveYearsRange: this.isActiveYearsRange,
        communalFilter: this.communalFilter,
      };
      this.$store.dispatch("setPlayerSearchFilters", { data: toReturn });
      this.$emit("adaptMatchingResults", toReturn);
      this.sidebarDisplayStatus = false;
    },
    getStatusAndData() {
      return this.$t("status") + " / " + this.$t("data.dot");
    },
    getRangeByIndex(index, source) {
      return !isNaN(source[index])
        ? parseFloat(source[index].toString().replace(",", "."))
        : 0;
    },
    toggleFilter(target) {
      console.log("switch", target);
      this.toggleableFilter[target] = !this.toggleableFilter[target];
    },
    togglePositionsSelected(position) {
      const self = this;
      const indexOfPosition = self.positionsSelected.indexOf(position);

      if (indexOfPosition >= 0) {
        self.positionsSelected.splice(indexOfPosition, 1);
      } else {
        if (self.positionsSelected.length === self.maxPositions) {
          return;
        }
        self.positionsSelected.push(position);
      }
      const positionIndex = self.filtersSelected.indexOf("position");
      if (positionIndex === -1) {
        self.filtersSelected.push("position");
      } else {
        if (!self.positionsSelected.length) {
          self.filtersSelected.splice(positionIndex, 1);
        }
      }
    },

    changeFootSelected(footSelected = "") {
      this.footSelected = footSelected;
    },
  },
  watch: {
    reloadData() {
      if (this.reloadData) {
        this.clearFields();
      }
    },
    displaySidebar: {
      handler(newValue, oldValue) {
        if (!newValue && oldValue && !this.getActiveFiltersLength) {
          this.$emit("doMountRequest");
        } else {
          this.displayCommentedPlayers = this.propsDisplayCommentedPlayers;
        }
        this.sidebarDisplayStatus = this.displaySidebar;
      },
    },
    sidebarDisplayStatus: {
      handler(newValue) {
        if (!newValue) {
          this.$emit("toggleSidebarFromSidebar");
        }
      },
    },
    priceRange: {
      handler(newValue, previousValue) {
        const self = this;
        if (newValue[0] != 0 || newValue[1] != self.maxPrice) {
          if (isNaN(newValue[0])) {
            newValue[0] = 0;
          }
          if (isNaN(newValue[1])) {
            newValue[1] = self.maxPrice;
          }
          self.isActivePriceRange = true;
        } else {
          self.isActivePriceRange = false;
        }
        const currentFilterIndex = self.filtersSelected.indexOf("price");

        if (newValue[0] === 0 && newValue[1] === self.maxPrice) {
          if (currentFilterIndex !== -1) {
            self.filtersSelected.splice(currentFilterIndex, 1);
          }
        } else if (
          previousValue[0] !== newValue[0] ||
          previousValue[1] !== newValue[1]
        ) {
          if (currentFilterIndex === -1) {
            self.filtersSelected.push("price");
          }
        }
      },
      deep: true,
    },
    yearsRange: {
      handler(newValue, previousValue) {
        const self = this;
        const currentFilterIndex = self.filtersSelected.indexOf("dateOfBirth");
        if (newValue[0] === 1971 && newValue[1] === self.currentYear) {
          if (currentFilterIndex !== -1) {
            self.filtersSelected.splice(currentFilterIndex, 1);
          }
        } else if (newValue[0] !== 1971 || newValue[1] !== self.currentYear) {
          if (isNaN(newValue[0])) {
            newValue[0] = 1971;
          }
          if (isNaN(newValue[1])) {
            newValue[1] = self.currentYear;
          }
          self.isActiveYearsRange = true;
        } else {
          self.isActiveYearsRange = false;
        }

        if (newValue[0] === 1971 && newValue[1] === self.currentYear) {
          if (currentFilterIndex !== -1) {
            self.filtersSelected.splice(currentFilterIndex, 1);
          }
        } else if (
          previousValue[0] !== newValue[0] ||
          previousValue[1] !== newValue[1]
        ) {
          if (currentFilterIndex === -1) {
            self.filtersSelected.push("dateOfBirth");
          }
        }
      },
      deep: true,
    },
    endContractRange: {
      handler(newValue, previousValue) {
        const self = this;
        if (newValue[0] != 0 || newValue[1] != self.maxEndContract) {
          if (isNaN(newValue[0])) {
            newValue[0] = 0;
          }
          if (isNaN(newValue[1])) {
            newValue[1] = self.maxEndContract;
          }
          self.isActiveEndContractRange = true;
        } else {
          self.isActiveEndContractRange = false;
        }

        const currentFilterIndex = self.filtersSelected.indexOf("endContract");
        if (newValue[0] === 0 && newValue[1] === self.maxEndContract) {
          if (currentFilterIndex !== -1) {
            self.filtersSelected.splice(currentFilterIndex, 1);
          }
        } else if (
          previousValue[0] !== newValue[0] ||
          previousValue[1] !== newValue[1]
        ) {
          if (currentFilterIndex === -1) {
            self.filtersSelected.push("endContract");
          }
        }
      },
      deep: true,
    },
    maxSpeedRange: {
      handler(newValue, previousValue) {
        const self = this;
        if (newValue[0] != 0 || newValue[1] != 100) {
          if (isNaN(newValue[0])) {
            newValue[0] = 0;
          }
          if (isNaN(newValue[1])) {
            newValue[1] = 100;
          }
          self.isActiveMaxSpeedRange = true;
        } else {
          self.isActiveMaxSpeedRange = false;
        }
        const currentFilterIndex = self.filtersSelected.indexOf("maxSpeed");
        if (newValue[0] === 0 && newValue[1] === 100) {
          if (currentFilterIndex !== -1) {
            self.filtersSelected.splice(currentFilterIndex, 1);
          }
        } else if (
          previousValue[0] !== newValue[0] ||
          previousValue[1] !== newValue[1]
        ) {
          if (currentFilterIndex === -1) {
            self.filtersSelected.push("maxSpeed");
          }
        }
      },
      deep: true,
    },
    totalDistRange: {
      handler(newValue, previousValue) {
        const self = this;
        if (newValue[0] != 0 || newValue[1] != 100) {
          if (isNaN(newValue[0])) {
            newValue[0] = 0;
          }
          if (isNaN(newValue[1])) {
            newValue[1] = 100;
          }
          self.isActiveTotalDistRange = true;
        } else {
          self.isActiveTotalDistRange = false;
        }

        const currentFilterIndex = self.filtersSelected.indexOf("totalDist");
        if (newValue[0] === 0 && newValue[1] === 100) {
          if (currentFilterIndex !== -1) {
            self.filtersSelected.splice(currentFilterIndex, 1);
          }
        } else if (
          previousValue[0] !== newValue[0] ||
          previousValue[1] !== newValue[1]
        ) {
          if (currentFilterIndex === -1) {
            self.filtersSelected.push("totalDist");
          }
        }
      },
      deep: true,
    },
    distMoreThan20kmhRange: {
      handler(newValue, previousValue) {
        const self = this;
        if (newValue[0] != 0 || newValue[1] != 100) {
          if (isNaN(newValue[0])) {
            newValue[0] = 0;
          }
          if (isNaN(newValue[1])) {
            newValue[1] = 100;
          }
          self.isActiveDistMoreThan20kmh = true;
        } else {
          self.isActiveDistMoreThan20kmh = false;
        }

        const currentFilterIndex =
          self.filtersSelected.indexOf("distMoreThan20kmh");
        if (newValue[0] === 0 && newValue[1] === 100) {
          if (currentFilterIndex !== -1) {
            self.filtersSelected.splice(currentFilterIndex, 1);
          }
        } else if (
          previousValue[0] !== newValue[0] ||
          previousValue[1] !== newValue[1]
        ) {
          if (currentFilterIndex === -1) {
            self.filtersSelected.push("distMoreThan20kmh");
          }
        }
      },
      deep: true,
    },
    accelerationMoreThan3mRange: {
      handler(newValue, previousValue) {
        const self = this;
        if (newValue[0] != 0 || newValue[1] != 100) {
          if (isNaN(newValue[0])) {
            newValue[0] = 0;
          }
          if (isNaN(newValue[1])) {
            newValue[1] = 100;
          }
          self.isActiveAccelerationMoreThan3m = true;
        } else {
          self.isActiveAccelerationMoreThan3m = false;
        }

        const currentFilterIndex = self.filtersSelected.indexOf(
          "nbAccelerationMore3mPerSec"
        );
        if (newValue[0] === 0 && newValue[1] === 100) {
          if (currentFilterIndex !== -1) {
            self.filtersSelected.splice(currentFilterIndex, 1);
          }
        } else if (
          previousValue[0] !== newValue[0] ||
          previousValue[1] !== newValue[1]
        ) {
          if (currentFilterIndex === -1) {
            self.filtersSelected.push("nbAccelerationMore3mPerSec");
          }
        }
      },
      deep: true,
    },
    communalFilter: {
      handler(newValue) {
        const self = this;
        const currentFilterIndex = self.filtersSelected.indexOf("category");
        if (newValue !== "ue" && newValue !== "non-ue") {
          if (currentFilterIndex !== -1) {
            self.filtersSelected.splice(currentFilterIndex, 1);
          }
        } else if (newValue === "ue" || newValue === "non-ue") {
          if (currentFilterIndex === -1) {
            self.filtersSelected.push("category");
          }
        }
      },
      deep: true,
    },
    displayBucketPlayers: {
      handler(newValue) {
        const self = this;

        const currentFilterIndex =
          self.filtersSelected.indexOf("bucketPlayers");
        if (!newValue) {
          if (currentFilterIndex !== -1) {
            self.filtersSelected.splice(currentFilterIndex, 1);
          }
        } else if (newValue === true) {
          if (currentFilterIndex === -1) {
            self.filtersSelected.push("bucketPlayers");
          }
        }
      },
      deep: true,
    },
    displayNotCommentedPlayers: {
      handler(newValue) {
        const self = this;
        if (newValue == true && this.displayCommentedPlayers == true) {
          this.displayCommentedPlayers = false;
        }
        const currentFilterIndex = self.filtersSelected.indexOf(
          "notCommentedPlayers"
        );

        if (newValue) {
          if (currentFilterIndex === -1) {
            self.filtersSelected.push("notCommentedPlayers");
          }
        } else {
          if (currentFilterIndex !== -1) {
            self.filtersSelected.splice(currentFilterIndex, 1);
          }
        }
      },
      deep: true,
    },
    displayCommentedPlayers: {
      handler(newValue) {
        const self = this;
        if (newValue && this.displayNotCommentedPlayers) {
          this.displayNotCommentedPlayers = false;
        }
        const currentFilterIndex =
          self.filtersSelected.indexOf("commentedPlayers");

        if (newValue) {
          if (currentFilterIndex === -1) {
            self.filtersSelected.push("commentedPlayers");
          }
        } else {
          if (currentFilterIndex !== -1) {
            self.filtersSelected.splice(currentFilterIndex, 1);
          }
        }
      },
      deep: true,
    },
    footSelected: {
      handler(newValue) {
        const self = this;
        const currentFilterIndex = self.filtersSelected.indexOf("foot");
        if (
          newValue === "left" ||
          newValue === "right" ||
          newValue === "both"
        ) {
          if (currentFilterIndex === -1) {
            self.filtersSelected.push("foot");
          }
        } else {
          self.filtersSelected.splice(currentFilterIndex, 1);
        }
      },
    },
    weightRange: {
      handler(newValue, previousValue) {
        const self = this;
        if (newValue[0] != 0 || newValue[1] != self.maxWeight) {
          if (isNaN(newValue[0])) {
            newValue[0] = 0;
          }
          if (isNaN(newValue[1])) {
            newValue[1] = self.maxWeight;
          }
          self.isActiveWeightRange = true;
        } else {
          self.isActiveWeightRange = false;
        }

        const currentFilterIndex = self.filtersSelected.indexOf("weight");
        if (newValue[0] === 0 && newValue[1] === self.maxWeight) {
          if (currentFilterIndex !== -1) {
            self.filtersSelected.splice(currentFilterIndex, 1);
          }
        } else if (
          previousValue[0] !== newValue[0] ||
          previousValue[1] !== newValue[1]
        ) {
          if (currentFilterIndex === -1) {
            self.filtersSelected.push("weight");
          }
        }
      },
      deep: true,
    },
    heightRange: {
      handler(newValue, previousValue) {
        const self = this;
        if (newValue[0] != 0 || newValue[1] != self.maxHeight) {
          if (isNaN(newValue[0])) {
            newValue[0] = 0;
          }
          if (isNaN(newValue[1])) {
            newValue[1] = self.maxHeight;
          }
          self.isActiveHeightRange = true;
        } else {
          self.isActiveHeightRange = false;
        }

        const currentFilterIndex = self.filtersSelected.indexOf("height");
        if (newValue[0] === 0 && newValue[1] === self.maxHeight) {
          if (currentFilterIndex !== -1) {
            self.filtersSelected.splice(currentFilterIndex, 1);
          }
        } else if (
          previousValue[0] !== newValue[0] ||
          previousValue[1] !== newValue[1]
        ) {
          if (currentFilterIndex === -1) {
            self.filtersSelected.push("height");
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scope>
@import "../assets/scss/variables.scss";

#sidebarFilterPlayers {
  width: 40%;
}

#sidebarFilterPlayers header {
  padding: 10px 20px;
  color: $blueDark;
}

#sidebarFilterPlayers header strong {
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
  position: relative;
  width: 100%;
}
#sidebarFilterPlayers header strong:after {
  position: absolute;
  left: 0;
  right: 0;
  content: "";
  height: 3px;
  width: 80px;
  display: block;
  background: #da5856;
  margin: 0 auto;
  bottom: -5px;
}

.playerSelectContainer {
  font-size: 0.8rem;
}

.submitContainer {
  text-align: center;
  position: fixed;
  bottom: 10px;
  right: 30px;
}

.btn.doRequest {
  font-size: 0.8rem;
  padding: 10px 15px;
}

.resetButtonContainer {
  margin-bottom: 20px;
  text-align: center;
}
.resetButtonContainer button {
  background-color: $blueDark;
  border-radius: 50px;
  color: white;
  border: 0;
  font-size: 0.8rem;
  padding: 10px 15px;
}

.playersPositions,
.playerFoot,
.playersCategory,
.bucketPlayers {
  border: 1px solid #847feb;
  padding: 5px 10px;
  user-select: none;
  cursor: pointer;
}

.playersPositions[checked],
.playerFoot.isSelected,
.playersCategory.isSelected,
.bucketPlayers.isSelected {
  background: #847feb;
  color: #fdfdfd;
}

.row.filterKeyboardSelector {
  display: block;
}

.row.filterKeyboardSelector .col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.row.filterKeyboardSelector .col span[contenteditable="true"] {
  border: 1px solid $blueDark;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #847feb;
  color: #343a40;
  padding: 5px 0;
}

.row.filterKeyboardSelector .col span[contenteditable="true"].edited {
  border: 1px solid $blueDark;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #847feb;
  color: white;
  padding: 5px 0;
}

legend.col-form-label {
  font-size: 1.1rem;
  font-weight: bolder;
  cursor: pointer;
  position: relative;
}

legend.col-form-label:after {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 40px;
  background-image: url(../assets/img/triangle.svg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "";
  transform: rotate(62deg);
  transition: 0.3s;
  transform-origin: center right;
}

legend.col-form-label.opened {
  border-bottom: 1px solid rgba(52, 58, 64, 0.3);
  margin-bottom: 10px;
}

legend.col-form-label.opened:after {
  transform: rotate(30deg);
}

legend.col-form-label span {
  font-size: 0.9rem;
}

.filterContainer {
  margin: 0 0 10px 0;
  columns: 2;
}

.filtersTitleContainer {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 20px;
}

fieldset legend,
fieldset .col {
  user-select: none;
}

.collapsableContainer label {
  font-weight: 500;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 576px) {
  #sidebarFilterPlayers {
    width: 100%;
  }
}
</style>
