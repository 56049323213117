import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import firebase from "./firebase";
import { firestorePlugin } from "vuefire";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/scss/master.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import VueTagManager from "vue-tag-manager";
import {
  faEye,
  faEyeSlash,
  faArrowCircleLeft,
  faPlusCircle,
  faTimesCircle,
  faInfoCircle,
  faShoePrints,
  faStar,
  faFolderOpen,
  faArrowRight,
  faColumns,
  faFutbol,
  faGhost,
  faSearch,
  faUsers,
  faCrosshairs,
  faTshirt,
  faSignOutAlt,
  faExclamationCircle,
  faQuestionCircle,
  faFilm,
  faMapPin,
  faClipboard,
  faChartBar,
  faCommentAlt,
  faPencilAlt,
  faCrown,
  faUser,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import axios from "axios";
import VueAxios from "vue-axios";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import ToggleButton from "vue-js-toggle-button";
import i18n from "./i18n";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/material.css";
import VMdDateRangePicker from "v-md-date-range-picker";
import VueSimpleAlert from "vue-simple-alert";
import vClickOutside from "v-click-outside";

const moment = require("moment");

Vue.use(VueSimpleAlert);
Vue.component("VueSlider", VueSlider);
Vue.use(VMdDateRangePicker);
require("moment/locale/fr");
require("moment/locale/en-gb");
Vue.use(firestorePlugin);
Vue.use(BootstrapVue);
Vue.use(vClickOutside);

library.add(
  faEye,
  faEyeSlash,
  faArrowCircleLeft,
  faPlusCircle,
  faTimesCircle,
  faInfoCircle,
  faShoePrints,
  faStar,
  faFolderOpen,
  faArrowRight,
  faColumns,
  faFutbol,
  faGhost,
  faSearch,
  faUsers,
  faCrosshairs,
  faTshirt,
  faSignOutAlt,
  faExclamationCircle,
  faQuestionCircle,
  faFilm,
  faMapPin,
  faClipboard,
  faChartBar,
  faCommentAlt,
  faPencilAlt,
  faCrown,
  faUser,
  faTrash
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueAxios, axios);
Vue.use(require("vue-moment"), { moment });
Vue.use(ToggleButton);
Vue.use(VueLodash, {
  lodash: lodash,
});
Vue.config.productionTip = false;
Vue.use(VueTagManager, {
  gtmId: "GTM-NX7Z6W27",
});
let app = "";

firebase.auth().onAuthStateChanged(async (currentScout) => {
  if (currentScout) {
    const accountEmail = currentScout.providerData[0].email;
    if (!store.getters.getScouts.length) {
      await store.dispatch("bindScouts");
    }
    const matchingScoutId = lodash.filter(store.getters.getScouts, {
      email: accountEmail,
    });
    let accountId = currentScout.uid;
    if (matchingScoutId[0]) {
      accountId = matchingScoutId[0].id;
    }
    store.dispatch("setInitialScoutId", accountId);
    store.dispatch("setCurrentScoutId", { uid: accountId });
    store.dispatch("bindCurrentScout");
    store.dispatch("bindCurrentScoutGames");
    store.dispatch("bindNotes");
    store.dispatch("bindCurrentScoutPlayers");
    store.dispatch("bindGamesPositions");
    store.dispatch("bindAddedPlayers");
    store.dispatch("bindShadowsPositions");
    store.dispatch("bindCompos");
    store.dispatch("bindCompanies");
    store.dispatch("loadTeams");
    store.dispatch("loadPlayers");
  }
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
