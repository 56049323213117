<template>
  <div
    :class="[
      getCurrentScout.isManager ? 'noteManagerContainer' : 'noteScoutContainer',
      note.category == 'cell' ? 'cellNote noteContainer' : 'noteContainer',
    ]"
  >
    <b-row class="noteIdentificators">
      <div
        class="deleteNote"
        v-if="!note.gameId && note.authorId == getCurrentScout.id"
        @click="deleteNote"
      >
        <font-awesome-icon icon="trash" />
      </div>
      <b-col>
        <span v-if="getCurrentScout.isManager">
          {{ getNoteAuthorName(note.authorId) }}
          <span v-if="getCurrentScout.company === getCompanies[0].id">
            ({{ getNoteAuthorCompany(note.authorId) }})
          </span>
        </span>
        <span class="noteCategory" v-if="note.category">
          {{ $t("category." + note.category) }}</span
        >
        <span class="noteDate">{{ formatDate(note.id, false, "date") }}</span>
      </b-col>
    </b-row>
    <b-row v-if="note.letterNote" class="noteContent">
      <b-col>
        <p>{{ note.comment }}</p>
      </b-col>
    </b-row>
    <b-row v-if="note.letterNote">
      <b-col class="lettersContainer">
        <b-row class="text-center">
          <b-col>
            <span
              :class="{
                ABCDNoteContainer: true,
                selected: note.letterNote == 'A',
              }"
            >
              A
            </span>
          </b-col>
          <b-col>
            <span
              :class="{
                ABCDNoteContainer: true,
                selected: note.letterNote == 'B',
              }"
            >
              B
            </span>
          </b-col>
          <b-col>
            <span
              :class="{
                ABCDNoteContainer: true,
                selected: note.letterNote == 'C',
              }"
            >
              C
            </span>
          </b-col>
          <b-col>
            <span
              :class="{
                ABCDNoteContainer: true,
                selected: note.letterNote == 'D',
              }"
            >
              D
            </span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="note.letterNote">
      <b-col>
        <p v-if="note.tacticalNote?.length">
          <span class="noteCategory">{{ $t("category.tactical") }} :</span>
          <br />
          {{ note.tacticalNote }}
        </p>
        <p v-if="note.mentalNote?.length">
          <span class="noteCategory">{{ $t("category.mental") }} :</span>
          <br />
          {{ note.mentalNote }}
        </p>
        <p v-if="note.athleticalNote?.length">
          <span class="noteCategory">{{ $t("category.athletic") }} :</span>
          <br />
          {{ note.athleticalNote }}
        </p>
        <p v-if="note.technicalNote?.length">
          <span class="noteCategory">{{ $t("category.technique") }} :</span>
          <br />
          {{ note.technicalNote }}
        </p>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <p>{{ note.comment }}</p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import db from "../db";
import mixinDates from "../mixins/mixinDates";
export default {
  name: "NoteComponent",
  mixins: [mixinDates],
  props: { note: Object },
  computed: {
    ...mapGetters(["getCurrentScout", "getScouts", "getCompanies"]),
  },
  methods: {
    deleteNote() {
      const batch = db.batch();

      const refScoutPlayerNotes = db
        .collection("V3scouts")
        .doc(this.getCurrentScout.id.toString())
        .collection("players")
        .doc(this.$route.params.playerId.toString())
        .collection("notes")
        .doc(this.note.id.toString());

      const refNote = db.collection("V3notes").doc(this.note.id.toString());

      const playerNoteRef = db
        .collection("V3players")
        .doc(this.$route.params.playerId.toString())
        .collection("notes")
        .doc(this.note.id.toString());

      batch.delete(refScoutPlayerNotes);
      batch.delete(refNote);
      batch.delete(playerNoteRef);
      batch.commit();
    },
    getNoteAuthorName(authorId) {
      const matchingScout = this.lodash.filter(this.getScouts, {
        id: authorId,
      })[0];
      return matchingScout
        ? matchingScout.firstname + " " + matchingScout.lastname
        : this.$t("undefined");
    },
    getNoteAuthorCompany(authorId) {
      const matchingScout = this.lodash.filter(this.getScouts, {
        id: authorId,
      })[0];
      return matchingScout ? matchingScout.company : "";
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/scss/variables.scss";

.noteContent p {
  margin-bottom: 0;
}

.lettersContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: white;
  margin: 10px 0;
}

.lettersContainer > .row {
  flex-direction: row;
  width: 100%;
}

.lettersContainer .col {
  display: flex;
  align-content: center;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
}

.lettersContainer span.ABCDNoteContainer {
  background: white;
  color: #9a9a9a;
  border: 4px solid #c8c8c8;
  padding: 15px;
  margin: 10px auto;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.lettersContainer span.ABCDNoteContainer.selected {
  color: $blueDark;
  border: 4px solid $blueDark;
}
.deleteNote {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  padding: 10px;
  background: #da5856;
  color: white;
  border-radius: 50%;
  z-index: 10000;
}

.cellNote .noteCategory,
.cellNote .noteIdentificators,
.cellNote {
  color: #bf953f !important;
}

.cellNote .noteCategory {
  text-transform: uppercase;
  border-bottom: 2px solid #bf953f;
  margin-bottom: 7px !important;
  display: inline-block;
}

.noteCategory {
  font-weight: bold;
}

.noteDate {
  display: block;
}

.noteContainer {
  padding: 20px;
  position: relative;
  background: white;
  border-radius: 0px 10px 10px 10px;
  color: #04004b;
  margin-bottom: 20px;
  width: 100%;
}

.noteContainer:before {
  content: "";
  position: absolute;
  top: 0;
  left: -8px;
  border-top: 40px solid white;
  border-left: 8px solid transparent;
}

.noteScoutContainer {
  width: 100%;
  display: inline-block;
}

.noteIdentificators {
  color: #8b95ea;
  margin-bottom: 12px;
  font-size: 0.875rem;
}

@media screen and (max-width: 576px) {
  .noteScoutContainer {
    width: 100%;
  }
}
</style>
