<template>
  <div>
    <ShadowPopin
      v-if="popinContent.display"
      :display="popinContent.display"
      :scenario="popinContent.scenario"
      :content="popinContent.content"
      @closePopin="closePopin"
      @updatePlayersList="updatePlayersList"
      @triggerPopin="displayAnotherPopin"
      @addPlayersToShadow="addPlayersToShadow"
      @toggleNotification="toggleNotificationByEmit"
      @setContributors="setContributors"
    />
    <section class="shadowsHeader">
      <b-row>
        <b-col>
          <h1 class="mb-3">{{ $t("nav.shadowTeam") }},</h1>
        </b-col>
      </b-row>
    </section>
    <div id="shadowPageContainer">
      <section class="shadowTitleContainer">
        <b-row>
          <b-col>
            <b-row class="shadowHeader">
              <b-col>
                <b-row>
                  <b-col v-if="selectedTeamId?.length">
                    <p class="selectedShadowNameContainer">
                      {{ newShadowTeamName }}
                    </p>
                    <span
                      class="contributorsInteractionContainer"
                      @click="triggerPopin('updateShadowName')"
                    >
                      <img src="../assets/img/pen.svg" alt="editer la shadow" />
                    </span>
                    <span
                      v-if="
                        getCurrentScout.id === selectedShadow.addedBy || true
                      "
                      class="contributorsInteractionContainer"
                      @click="triggerPopin('deleteShadow')"
                    >
                      <img
                        src="../assets/img/trash.svg"
                        alt="supprimer la shadow"
                      />
                    </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="newShadowTeamName?.length">
            {{
              $t("shadowCreatedAtBy", {
                date: formatDate(selectedShadow.date, false, [
                  "MM/DD/YYYY",
                  "DD/MM/YYYY",
                ]),
                scout: identifyScoutById(selectedShadow.addedBy, "shortenName"),
              })
            }}
            {{
              $t("lastUpdatedAt", {
                day: formatDate(selectedShadow.updatedAt, false, [
                  "MM/DD/YYYY",
                  "DD/MM/YYYY",
                ]),
                hour: formatDate(selectedShadow.updatedAt, false, "HH:mm"),
              })
            }}
          </b-col>
        </b-row>
      </section>
      <section
        v-if="selectedShadow.id"
        class="shadowContributorsAndActionsContainer"
      >
        <b-row>
          <b-col class="card">
            <b-row>
              <b-col>
                <p>
                  <b>{{ $t("filterByContributorScouts") }}</b>
                </p>

                <div v-if="!currentShadowPlayers.length">
                  {{ $t("no.contributorsYet") }}
                </div>
                <div v-else>
                  <button
                    v-for="(
                      contributorId, contributorIndex
                    ) in currentShadowContributors"
                    :key="contributorIndex"
                    :class="{
                      contributorButton: true,
                      isSelected:
                        !selectedScoutId ||
                        (selectedScoutId && selectedScoutId === contributorId),
                    }"
                    @click="selectScout(contributorId)"
                  >
                    {{ identifyScoutById(contributorId, "shortenName") }}
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col id="exportContainer" class="card">
            <b-row>
              <b-col>
                <p>
                  <b>{{ $t("exportTheShadowTeam") }}</b>
                </p>
                <div class="shadowActionsContainer">
                  <!-- <button @click="exportCSV">{{ $t("CSVFormat") }}</button> -->
                  <button @click="downloadShadow('pdf')">
                    {{ $t("PDFFormat") }}
                  </button>
                  <button @click="downloadShadow('png')">
                    {{ $t("PNGFormat") }}
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </section>
      <section v-if="selectedShadow.id" id="currentShadowContainer">
        <b-row>
          <b-col>
            <div>
              <ShadowPitchComponent
                :compoId="selectedShadow.compoId"
                :currentShadowPlayers="currentShadowPlayers"
                :selectedScoutId="selectedScoutId"
                @addPlayer="addPlayer"
              />
            </div>
          </b-col>
        </b-row>
      </section>
    </div>
    <NotificationComponent :notification="notification" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import db from "../db";
import mixinScouts from "../mixins/mixinScouts";
import mixinDates from "../mixins/mixinDates";
import mixinCompos from "../mixins/mixinCompos";
import mixinText from "../mixins/mixinText";
import ShadowPitchComponent from "../components/ShadowPitchComponent";
import ShadowPopin from "../components/ShadowPopin";
import NotificationComponent from "../components/Notification";
import moment from "moment";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export default {
  name: "Shadow-Teams",
  mixins: [mixinScouts, mixinDates, mixinCompos, mixinText],
  components: { ShadowPitchComponent, ShadowPopin, NotificationComponent },
  data() {
    return {
      selectedScoutId: null,
      currentShadowPlayers: [],
      selectedShadowId: "",
      selectedShadow: {},
      shadowsOptions: [],
      currentShadowContributors: [],
      selectedTeamId: "",
      newShadowTeamName: "",
      popinContent: {
        display: false,
        scenario: "",
        players: [],
        content: {},
      },
      notification: {
        display: false,
        type: "",
        details: "",
      },
    };
  },
  created() {
    this.selectedShadowId = this.$route.params.shadowID;
  },
  mounted() {
    if (!this.getShadows.length) {
      this.$store.dispatch("bindScoutsShadows");
    }
    if (!this.getCompanies.length) {
      this.$store.dispatch("bindCompanies");
    }
    if (this.getShadows.length && this.getCompanies.length) {
      this.setCurrentShadow();
    }
  },
  computed: {
    ...mapGetters([
      "getShadows",
      "getCurrentScout",
      "getCompanies",
      "getCurrentScopeShadows",
    ]),
  },
  methods: {
    selectScout(scoutId) {
      if (this.selectedScoutId === scoutId) {
        this.selectedScoutId = null;
      } else {
        this.selectedScoutId = scoutId;
      }
    },
    toggleNotificationByEmit(payload) {
      this.toggleNotification(payload.type, payload.details);
    },
    setSelectedShadowName() {
      const matchingShadow = this.shadowsOptions.filter((shadow) => {
        return shadow.value === this.selectedTeamId;
      });
      if (matchingShadow[0]) {
        this.selectedShadow = this.getShadows.filter((shadow) => {
          return shadow.id === matchingShadow[0].value;
        })[0];
        this.newShadowTeamName = matchingShadow[0].text;
      } else {
        this.selectedShadow = { players: [] };
        this.newShadowTeamName = "";
        this.selectedTeamId = "";
      }
    },
    setCurrentShadow() {
      this.setShadowsOptions();
      this.setSelectedShadowName();
      this.loadCurrentShadowPlayers();
    },
    checkShadowToDisplay() {
      if (
        this.popinContent.scenario === "createShadow" ||
        this.popinContent.scenario === "deleteShadow"
      ) {
        this.setCurrentShadow();
      }
    },
    toggleNotification(notificationType, notificationDetails) {
      this.notification.display = true;
      this.notification.type = notificationType;

      this.notification.details = notificationDetails.message
        ? this.$t(notificationDetails.message, notificationDetails.params)
        : this.$t(notificationDetails);
      setTimeout(() => {
        this.notification.display = false;
      }, 6000);
    },
    downloadShadow(fileType) {
      const pageContainer = document.getElementById("shadowPageContainer");
      const currentShadowContainer = document.getElementById(
        "currentShadowContainer"
      );
      const exportButtonsContainer = document.getElementById("exportContainer");
      const compoSelectorContar = document.getElementById("compoSelector");
      const contributorInteractionContainer = document.getElementsByClassName(
        "contributorsInteractionContainer"
      );
      const shadowLastUpdate = this.formatDate(
        this.selectedShadow.updatedAt,
        false,
        ["MM/DD/YYYY", "DD/MM/YYYY"]
      );
      const fileName = this.string_to_slug(
        `${this.newShadowTeamName}-${shadowLastUpdate}`
      );

      let contentToCapture;
      if (fileType === "png") {
        contentToCapture = currentShadowContainer;
      } else {
        contentToCapture = pageContainer;
        exportButtonsContainer.style.display = "none";
        compoSelectorContar.style.display = "none";
        Array.from(contributorInteractionContainer).forEach(
          (element) => (element.style.display = "none")
        );
      }

      html2canvas(contentToCapture, { scale: 2.5 }).then(function (canvas) {
        let shadowImage = canvas.toDataURL("image/png");
        if (fileType == "pdf") {
          exportButtonsContainer.style.display = "block";
          compoSelectorContar.style.display = "block";
          Array.from(contributorInteractionContainer).forEach(
            (element) => (element.style.display = "inline-flex")
          );

          const shadowPdf = new jsPDF();
          const shadowPdfWidth = shadowPdf.internal.pageSize.getWidth();
          const shadowPdfHeight = shadowPdf.internal.pageSize.getHeight();
          let imageWidth = shadowPdfWidth;
          let imageHeight = (canvas.height * shadowPdfWidth) / canvas.width;
          let marginX = 0;
          let marginY = 0;

          if (imageHeight > shadowPdfHeight) {
            imageHeight = shadowPdfHeight;
            imageWidth = (canvas.width * shadowPdfHeight) / canvas.height;
            marginX = (shadowPdfWidth - imageWidth) / 2;
          }

          shadowPdf.addImage(
            shadowImage,
            "PNG",
            marginX,
            marginY,
            imageWidth,
            imageHeight,
            undefined,
            "FAST"
          );
          shadowImage = shadowPdf.output("datauristring");
        }

        const downloadLink = document.createElement("a");
        downloadLink.href = shadowImage;
        downloadLink.download = `${fileName}.${fileType}`;
        downloadLink.click();
      });
    },
    async addPlayersToShadow(payload) {
      const self = this;
      let matchingIndexes = [];
      if (self.popinContent.content.players) {
        self.popinContent.content.players.forEach((player, playerIndex) => {
          if (player.position === self.popinContent.content.selectedPosition) {
            matchingIndexes.push(playerIndex);
          }
        });
      } else {
        self.popinContent.content.players = [];
      }

      let newPlayersForSelectedPosition =
        self.popinContent.content.players || [];
      if (matchingIndexes.length) {
        matchingIndexes = matchingIndexes.sort((a, b) => b - a);
        matchingIndexes.forEach((indexToRemove) => {
          newPlayersForSelectedPosition.splice(indexToRemove, 1);
        });
        newPlayersForSelectedPosition = [
          ...newPlayersForSelectedPosition,
          ...payload.data,
        ];
      } else {
        newPlayersForSelectedPosition = [
          ...self.popinContent.content.players,
          ...payload.data,
        ];
      }
      const now = moment().unix();
      await db
        .collection("V3shadows")
        .doc(self.selectedShadow.id.toString())
        .set(
          {
            players: newPlayersForSelectedPosition,
            updatedAt: now,
          },
          { merge: true }
        );
      self.setContributors({ contributor: self.getCurrentScout.id });

      self.currentShadowPlayers = newPlayersForSelectedPosition;
      self.popinContent.content.players = newPlayersForSelectedPosition;
      self.selectedShadow.updatedAt = now;
      self.closePopin();
    },
    displayAnotherPopin(payload) {
      this.triggerPopin(payload.target, payload.data);
    },
    async updatePlayersList(payload) {
      this.popinContent.content.players = payload.data;
      const now = moment().unix();
      await db
        .collection("V3shadows")
        .doc(this.selectedShadow.id.toString())
        .set({ players: payload.data || [], updatedAt: now }, { merge: true });

      this.selectedShadow.updatedAt = now;
    },
    addPlayer(payload) {
      this.triggerPopin("addPlayer", payload);
    },
    setContributors(payload) {
      const self = this;
      if (
        payload?.contributor &&
        !self.selectedShadow.contributors.includes(payload.contributor)
      ) {
        const newContributors = self.selectedShadow.contributors || [];
        newContributors.push(payload.contributor);
        db.collection("V3shadows")
          .doc(self.selectedShadow.id.toString())
          .set({ contributors: newContributors }, { merge: true });
      }
    },
    async closePopin(payload) {
      const self = this;
      this.setContributors(payload);

      if (payload?.players) {
        let newPlayers = self.currentShadowPlayers.filter((player) => {
          return player.position !== self.popinContent.content.selectedPosition;
        });
        payload.players.forEach((player) => {
          newPlayers.push(player);
        });
        await db
          .collection("V3shadows")
          .doc(self.selectedShadow.id.toString())
          .set({ players: newPlayers }, { merge: true });
        self.selectedShadow.players = newPlayers;
        this.loadCurrentShadowPlayers();
      }
      self.popinContent.display = false;
      if (payload?.newShadowUpdatedAt) {
        self.selectedShadow.updatedAt = payload.newShadowUpdatedAt;
      }
      if (payload?.popin) {
        const toShare = {
          message: payload.popin.details || "",
        };

        if (payload.popin.params) {
          toShare.params = {
            shadowName: payload.popin.params.shadowName,
            compo: self.getCompoById(payload.popin.params.compo, "displayName"),
          };
        }
        self.toggleNotification(payload.popin.type || "success", toShare);
      }
      if (payload?.newShadowName) {
        self.newShadowTeamName = payload.newShadowName;
      }
      self.checkShadowToDisplay();
    },
    triggerPopin(action, payload) {
      const self = this;
      self.popinContent.display = true;

      if (action) {
        self.popinContent.scenario = action;
        self.popinContent.content = self.selectedShadow;
        if (action === "addPlayer") {
          self.popinContent.content.selectedPosition = payload;
        }
      } else {
        if (payload?.newShadowName) {
          self.newShadowName = payload.newShadowName;
        }
      }
    },
    async loadCurrentShadowPlayers() {
      const self = this;
      const currentShadowPlayers = [];
      if (self.selectedShadow.players) {
        for (let i = 0; i < self.selectedShadow.players.length; i++) {
          await db
            .collection("V3players")
            .doc(self.selectedShadow.players[i].id.toString())
            .get()
            .then((playerDoc) => {
              const playerData = playerDoc.data();
              currentShadowPlayers.push({
                addedBy:
                  self.selectedShadow.players[i].addedBy ||
                  self.getCurrentScout.id.toString(),
                currentTeam: playerData.currentTeam,
                dateOfBirth: playerData.dateOfBirth,
                id: playerData.id,
                name: playerData.name,
                position: self.selectedShadow.players[i]?.position,
                price: playerData.price || "undefined",
              });
            });
        }
      }
      this.currentShadowPlayers = currentShadowPlayers || [];
      this.popinContent.content.players = currentShadowPlayers;
      this.currentShadowContributors = this.selectedShadow.contributors || [];
    },
    async setShadowsOptions() {
      let toReturn = this.getCurrentScopeShadows.map((shadow) => {
        if (shadow.addedBy && shadow.compoId) {
          return { text: shadow.label, value: shadow.id };
        }
      });

      toReturn = toReturn.filter((elementToReturn) => {
        return elementToReturn;
      });

      this.shadowsOptions = toReturn;
      if (toReturn.length) {
        const matchingShadow = this.shadowsOptions.filter((shadow) => {
          return shadow.value === this.selectedShadowId;
        });
        if (
          matchingShadow.length &&
          this.popinContent.scenario !== "createShadow"
        ) {
          this.selectedTeamId = matchingShadow[0].value;
        } else {
          this.selectedTeamId = toReturn[0].value;
        }
      }
    },
  },
  watch: {
    selectedShadow() {
      this.loadCurrentShadowPlayers();
    },
    getShadows: {
      handler() {
        if (this.getShadows.length && this.getCompanies.length) {
          this.setShadowsOptions();
        }
      },
      deep: true,
    },
    getCompanies: {
      handler() {
        if (this.getShadows.length && this.getCompanies.length) {
          this.setShadowsOptions();
        }
      },
      deep: true,
    },
    selectedTeamId() {
      this.setSelectedShadowName();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

section > .row:first-of-type {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.shadowContributorsAndActionsContainer {
  margin-top: 20px;
}

.shadowContributorsAndActionsContainer > .row:first-of-type {
  padding: 0 15px;
}

.shadowContributorsAndActionsContainer > .row {
  gap: 20px;
}

.card {
  padding: 15px;
}

h1 {
  font-weight: bold;
}

.shadowsHeader {
  background-color: $blueDark;
}

.shadowsHeader .col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.shadowsHeader .col:first-of-type {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  color: white;
  justify-content: center;
  align-items: flex-start;
}

.shadowsHeader .col:first-of-type span {
  margin-bottom: 10px;
}

.shadowTitleContainer {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.shadowTitleContainer > .row {
  margin: 10px auto;
  width: 100%;
  max-width: 1200px;
}

.shadowTitleContainer > .row:first-of-type > .col {
  display: flex;
}

.shadowTitleContainer > .row:first-of-type > .col > .row {
  flex: 1;
}

.shadowTitleContainer
  > .row:first-of-type
  > .col:first-of-type
  > .row:last-of-type
  > .col:last-of-type {
  text-align: right;
}

.shadowHeader {
  flex-direction: column;
}

.shadowHeader .row .col {
  display: flex;
  flex-direction: row;
}

.shadowHeader > .col:first-of-type {
  display: flex;
  align-items: center;
}

.selectedShadowNameContainer {
  font-size: 1.2rem;
  font-weight: bold;
  border: 1px solid black;
}

.selectedShadowNameContainer:read-only {
  border: 1px solid transparent;
  background: none;
}

select {
  max-width: 300px;
  font-weight: bold !important;
}

button {
  background-color: #060052;
  border-radius: 50px !important;
  color: white;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem !important;
  display: inline-flex;
  margin: 0 5px;
  padding: 5px 18px;
}

button:hover {
  background-color: #b34442;
}

.contributorButton {
  margin: 5px 3px;
}

.contributorButton:not(.isSelected) {
  opacity: 0.5;
}

.shadowsHeader button {
  content: "+";
  margin-right: 10px;
  font-size: 1.7rem;
}

.shadowsHeader button:before {
  content: "+";
  margin-right: 10px;
  font-size: 1.7rem;
}

.contributorsInteractionContainer {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: white;
  border: 1px solid #d0ccff;
  border-radius: 50px;
  padding: 5px;
  width: 32px;
  height: 32px;
  display: inline-flex;
  margin: 0 6px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.contributorsInteractionContainer:hover {
  background-color: #d0ccff;
}

#shadowPageContainer {
  max-width: 1264px;
  width: 100%;
  margin: 0 auto;
}

#shadowPageContainer > * {
  margin-left: 32px;
  margin-right: 32px;
}

.shadowPitchComponentContainer {
  margin: 32px 0;
}
</style>
