<template>
  <div class="login">
    <b-container fluid class="h-100">
      <b-row class="h-100">
        <b-col class="login-background h-100">
          <b-row align-h="center" class="text-center h-100">
            <b-col align-self="center">
              <img
                src="https://olstcweb.blob.core.windows.net/resources/eagleScout/eagleScout.png"
                alt="Scouting"
              />

              <div class="card-login my-5 pt-4 pb-5 px-5">
                <b-form @submit.prevent="submitViaLoginPassword">
                  <b-form-group
                    id="email"
                    label="Email address:"
                    label-for="email"
                    class="text-left"
                  >
                    <b-form-input
                      id="email"
                      v-model="form.email"
                      type="email"
                      required
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="password"
                    label="Password:"
                    label-for="password"
                    class="text-left"
                  >
                    <b-form-input
                      id="password"
                      v-model="form.password"
                      type="password"
                      required
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                  <div class="mt-2 p-4 text-left error" v-if="form.err">
                    <ul>
                      <li>
                        <font-awesome-icon
                          class="mr-2"
                          icon="exclamation-circle"
                        />
                      </li>
                      <li>
                        <p class="mb-0">{{ form.err }}</p>
                      </li>
                    </ul>
                  </div>
                  <b-button class="submit mt-4" type="submit" variant="primary">
                    Login
                  </b-button>
                </b-form>

                <button id="loginWithAzureAd" @click="submitViaSSO">
                  {{ $t("loginWithSSO") }}
                </button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import db from "../db";
import firebase from "../firebase";
import { mapGetters } from "vuex";
import lodash from "lodash";

export default {
  name: "LoginComponent",
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
        err: null,
      },
    };
  },
  methods: {
    submitViaLoginPassword(e) {
      const self = this;
      e.preventDefault();
      firebase
        .auth()
        .signInWithEmailAndPassword(self.form.email, self.form.password)
        .then(
          async () => {
            console.log("Well done ! You are now connected to the scout app");

            await db
              .collection("V3scouts")
              .doc(this.getInitialScoutId)
              .set(
                { lastConnexion: moment().format("DDMMYY") },
                { merge: true }
              );
            self.form.err = null;
            self.$router.replace("dashboard");
            firebase
              .auth()
              .currentUser.getIdToken(true)
              .then((idToken) => {
                self.$store.dispatch("setCurrentScoutToken", idToken);
              });
          },
          () => {
            self.form.err =
              "The password is invalid or the user doesn't exist.";
          }
        );
    },
    async submitViaSSO() {
      const self = this;
      self.errors = {};
      const provider = new firebase.auth.OAuthProvider("microsoft.com");
      provider.setCustomParameters({
        tenant: "44f57370-ba4b-4391-8b59-fd8d6d112ba8",
        client_id: "5245b6b3-2a84-463e-a26e-0ad612cdac60",
        response_type: "code",
        redirect_uri: "https://www.eaglescout.app/",
        client_secret: "hNU8Q~WY4OGE7yyvjdNXLUbYyNvocE-VoemQZde8", //ca4ae264-a963-470e-9752-c586df557667",
        state: 12345,
        response_mode: "query",
      });

      if (!this.getScouts.length) {
        await this.$store.dispatch("bindScouts");
      }

      firebase
        .auth()
        .signInWithPopup(provider)
        .then(async (response) => {
          const matchingScoutId = lodash.filter(this.getScouts, {
            email: response.user.providerData[0].email,
          });
          let accountId = response.user.uid;
          if (matchingScoutId[0]) {
            accountId = matchingScoutId[0].id;
          }

          db.collection("V3scouts")
            .doc(accountId.toString())
            .set({ lastConnexion: moment().format("DDMMYY") }, { merge: true });
          self.$router.replace("dashboard");
        })
        .catch((error) => {
          console.log(error);
          self.errors = { email: "Unable to find the user in the database" };
        });
    },
  },
  computed: {
    ...mapGetters(["getInitialScoutId", "getScouts"]),
  },
};
</script>
<style lang="scss">
@import "../assets/scss/variables.scss";

#loginWithAzureAd {
  margin-top: 20px;
  background-color: $blueDark !important;
  border-color: $blueDark !important;
  box-shadow: none !important;
  border-radius: 3rem;
  color: #ffffff !important;
  padding: 0.75rem 2.5rem;
  font-weight: 700;
  box-shadow: 0 2px 5.3px rgba(0, 0, 0, 0.028),
    0 6.7px 17.9px rgba(0, 0, 0, 0.042), 0 30px 80px rgba(0, 0, 0, 0.07);
  width: 100%;
}
</style>
