<template>
  <div class="games" v-if="getCurrentScout">
    <Popin v-if="isPopinOpened" />
    <div class="listGameTitleContainer">
      <div class="listGameTitleContent">
        <h1>{{ $t("gamesReports") }}</h1>
        <b-button v-if="!getCurrentScout.isManager" @click="openPopin">
          {{ $t("create.aGame") }}
        </b-button>
      </div>
    </div>
    <div class="filtersSectionContainer">
      <div class="filtersSectionContent">
        <span>{{ $t("filters.filters") }}</span>
        <div class="filtersContainer">
          <div :class="{ filterContainer: true, active: selectedDate.length }">
            <div class="filterContent">
              <span class="filterTitle">Date</span>
              <b-form-datepicker
                v-model="selectedDate"
                :locale="getLocale"
                selected-variant="danger"
                :placeholder="
                  getLocale === 'fr' ? 'jj / mm / aaaa' : 'mm / dd / yyyy'
                "
              />
            </div>
            <div
              class="closingFilter"
              v-if="selectedDate.length"
              @click="resetFilter('selectedDate')"
            ></div>
          </div>
          <div :class="{ filterContainer: true, active: selectedTeam.length }">
            <div class="filterContent">
              <b-form-group
                :label="$tc('plural.team', 1)"
                label-for="teamHomeAddGame"
                class="text-left"
              >
                <b-form-input
                  id="teamHomeAddGame"
                  v-model="selectedTeam"
                  type="text"
                  required
                  list="teamsList"
                  autocomplete="off"
                />
                <datalist id="teamsList">
                  <option v-for="(team, index) in teamsFromApi" :key="index">
                    {{ team.Team }}
                  </option>
                </datalist>
              </b-form-group>
            </div>
            <div
              class="closingFilter"
              v-if="selectedTeam.length"
              @click="resetFilter('selectedTeam')"
            ></div>
          </div>

          <div
            v-if="getCurrentScout.isManager"
            :class="{ filterContainer: true, active: selectedScout.length }"
          >
            <div class="filterContent">
              <span class="filterTitle">Scout</span>
              <select
                v-if="getCurrentScout.isManager && currentScopeScouts.length"
                name="scoutsSelector"
                id="scoutsSelector"
                v-model="selectedScout"
              >
                <option value="" selected="selected">
                  {{ $t("allScouts") }}
                </option>
                <option
                  v-for="(scout, scoutIndex) in currentScopeScouts"
                  :key="scoutIndex"
                  :value="scout.id"
                >
                  {{ identifyScoutById(scout.id, "shortenName") }}
                  <span
                    v-if="
                      getCurrentScout.isManager &&
                      getCurrentScout.company === getCompanies[0].id
                    "
                  >
                    (
                    {{ identifyScoutById(scout.id, "status") }}
                    <span v-if="getCurrentScout.company === getCompanies[0].id">
                      - {{ identifyScoutById(scout.id, "company") }}
                    </span>
                    )
                  </span>
                </option>
              </select>
              <div
                class="closingFilter"
                v-if="selectedScout.length"
                @click="resetFilter('selectedScout')"
              ></div>
            </div>
          </div>

          <div
            :class="{
              filterContainer: true,
              active: reportState.length,
            }"
          >
            <div class="filterContent">
              <span class="filterTitle">{{ $t("reportStatus.short") }}</span>
              <select
                name="statusSelector"
                id="statusSelector"
                v-model="reportState"
              >
                <option value="" disabled>{{ $t("status") }}</option>
                <option value="noValid">
                  {{ this.$t("reportStatus.toObserve") }}
                </option>
                <option value="valid" v-if="getCurrentScout.isManager">
                  {{ this.$t("reportStatus.validatedAndToRead") }}
                </option>
                <option v-if="!getCurrentScout.isManager" value="validRed">
                  {{ this.$t("reportStatus.validated") }}
                </option>
                <option v-else value="validRed">
                  {{ this.$t("reportStatus.validatedAndRead") }}
                </option>
              </select>
              <div
                class="closingFilter"
                v-if="reportState.length"
                @click="resetFilter('reportState')"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ListGames
      :games="currentScopePassedGamesPerPage[0]"
      :readLimit="readLimit"
      @resetFilters="resetFilter"
      theme="important"
    />
    <b-pagination
      v-if="currentScopePassedGamesPerPage[0].length"
      class="pagination justify-content-center mt-0 pb-3"
      v-model="currentPage"
      :total-rows="currentScopePassedGamesPerPage[1]"
      :hideGotoEndButtons="true"
      :per-page="perPage"
      align="right"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
import db from "../db";
import mixinDates from "../mixins/mixinDates";
import mixinScouts from "../mixins/mixinScouts";
import ListGames from "../components/ListGames";
import { popinVariants } from "../enums/popinVariants";
import Popin from "../components/Popin/Popin.vue";

export default {
  name: "GamesView",
  mixins: [mixinDates, mixinScouts],
  data() {
    return {
      readLimit: 1704668400,
      listingLimit: 300,
      currentPage: 1,
      perPage: 30,
      selectedDate: "",
      selectedScout: "",
      selectedTeam: "",
      reportState: "",
      matchingGamesByStatus: [],
      currentScopeScouts: [],
      currentScopePassedGames: [],
    };
  },
  components: {
    ListGames,
    Popin,
  },
  mounted() {
    if (this.$route.params.scoutId && this.$route.params.scoutId !== "-") {
      this.selectedScout = this.$route.params.scoutId;
    }
    if (!this.getScouts.length) {
      this.bindScouts();
    }
    this.loadCurrentScopeGames();

    if (this.getCurrentScout && this.getCurrentScout.isManager) {
      this.loadCurrentScopeScouts();
    }
  },
  computed: {
    ...mapGetters([
      "getCurrentScout",
      "getScouts",
      "getCompanies",
      "getLocale",
    ]),
    ...mapState("popin", ["isPopinOpened"]),
    ...mapState(["teamsFromApi"]),
    currentScopePassedGamesPerPage() {
      return [
        this.currentScopePassedGames.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        ),
        this.currentScopePassedGames.length,
      ];
    },
  },
  methods: {
    ...mapMutations("popin", ["openPopinVariant", "closePopin"]),
    ...mapActions(["bindScouts"]),
    openPopin() {
      this.openPopinVariant({
        popinVariant: popinVariants.FIND_GAME,
      });
    },
    manageGames(gamesSnapshot) {
      const self = this;
      self.currentScopePassedGames = [];
      let toReturn = [];
      if (self.selectedTeam.length) {
        if (self.reportState.length) {
          gamesSnapshot.docs.forEach((gameDoc) => {
            if (toReturn.length < self.listingLimit) {
              const gameData = gameDoc.data();
              if (
                gameData.teamHome === self.selectedTeam ||
                gameData.teamAway === self.selectedTeam
              ) {
                if (
                  (self.reportState === "valid" &&
                    !gameData[`seenBy${self.getCurrentScout.id.toString()}`] &&
                    self.getCurrentScout.isManager &&
                    self.getCompanies[0].id === self.getCurrentScout.company &&
                    gameData.playersLength &&
                    gameData.date > self.readLimit) ||
                  (self.getCurrentScout.isManager &&
                    gameData[`${self.getCurrentScout.company}PlayersLength`]) ||
                  (self.reportState === "validRed" &&
                    (((gameData[
                      `seenBy${self.getCurrentScout.id.toString()}`
                    ] ||
                      gameData.date < self.readLimit ||
                      gameData.noteLength) &&
                      self.getCurrentScout.isManager &&
                      self.getCurrentScout.company ===
                        self.getCompanies[0].id &&
                      gameData.playersLength) ||
                      (!self.getCurrentScout.isManager &&
                        gameData[
                          `${self.getCurrentScout.id}PlayersLength`
                        ]))) ||
                  (self.reportState === "noValid" &&
                    self.getCurrentScout.isManager &&
                    ((self.getCurrentScout.company ===
                      self.getCompanies[0].id &&
                      !gameData.playersLength) ||
                      gameData[`${self.getCurrentScout.id}PlayersLength`]) &&
                    gameData.date > self.readLimit)
                ) {
                  toReturn.push(gameData);
                }
              }
            }
          });
        } else {
          gamesSnapshot.docs.forEach((gameDoc) => {
            if (toReturn.length < self.listingLimit) {
              const gameData = gameDoc.data();
              if (
                gameData.teamHome === self.selectedTeam ||
                gameData.teamAway === self.selectedTeam
              ) {
                toReturn.push(gameData);
              }
            }
          });
        }
      } else {
        if (self.matchingGamesByStatus.length) {
          let matchingGames = [];
          matchingGames = gamesSnapshot.docs.filter((gameDoc) => {
            return self.matchingGamesByStatus.includes(gameDoc.id);
          });

          matchingGames.forEach((gameDoc) => {
            if (toReturn.length < self.listingLimit) {
              const gameData = gameDoc.data();
              if (self.reportState.length) {
                if (gameData.date > self.readLimit) {
                  if (self.reportState === "valid") {
                    if (
                      !gameData[`seenBy${self.getCurrentScout.id.toString()}`]
                    ) {
                      toReturn.push(gameData);
                    }
                  } else if (self.reportState === "noValid") {
                    if (
                      !gameData.note?.length &&
                      ((self.getCurrentScout.isManager &&
                        ((self.getCurrentScout.company ===
                          self.getCompanies[0].id &&
                          !gameData.playersLength) ||
                          !gameData[
                            `${self.getCurrentScout.company}PlayersLength`
                          ])) ||
                        (!self.getCurrentScout.isManager &&
                          !gameData[`${self.getCurrentScout.id}PlayersLength`]))
                    ) {
                      toReturn.push(gameData);
                    }
                  } else {
                    toReturn.push(gameData);
                  }
                }
              } else {
                toReturn.push(gameData);
              }
            }
          });
        } else {
          gamesSnapshot.docs.forEach((gameDoc) => {
            if (toReturn.length < self.listingLimit) {
              toReturn.push(gameDoc.data());
            }
          });
        }
      }
      self.currentScopePassedGames = toReturn;
    },
    resetFilter(paramToReset) {
      switch (paramToReset) {
        case "selectedDate": {
          this.selectedDate = "";
          break;
        }
        case "selectedScout": {
          this.selectedScout = "";
          break;
        }
        case "reportState": {
          this.reportState = "";
          break;
        }
        case "selectedTeam": {
          this.selectedTeam = "";
          break;
        }
        default: {
          this.selectedDate = "";
          this.selectedScout = "";
          this.reportState = "";
          this.selectedTeam = "";
        }
      }
    },
    async loadCurrentScopeGames() {
      const self = this;
      const now = moment().unix();
      let gameRef = db.collection("V3games");
      if (
        self.getCurrentScout &&
        self.getCurrentScout.company &&
        self.getCompanies[0]?.id
      ) {
        if (
          self.getCurrentScout.company === self.getCompanies[0].id &&
          self.getCurrentScout.isManager
        ) {
          if (self.selectedDate?.length) {
            if (moment(self.selectedDate).isValid()) {
              gameRef = gameRef
                .where("date", ">=", moment(self.selectedDate).unix())
                .where(
                  "date",
                  "<",
                  moment(self.selectedDate).endOf("day").unix()
                )
                .orderBy("date", "desc");
            }
          } else {
            gameRef = gameRef.where("date", "<", now).orderBy("date", "desc");
          }
          if (self.selectedScout.length) {
            gameRef = gameRef.where("addedBy", "==", self.selectedScout);
          }
          if (self.reportState.length) {
            let statusRef = db.collection("V3games");
            if (self.reportState === "valid") {
              statusRef = statusRef.where("playersLength", ">", 0);
            } else if (self.reportState === "validRed") {
              statusRef = statusRef
                .where("playersLength", ">", 0)
                .where(`seenBy${self.getCurrentScout.id}`, "==", true);
            } else if (self.reportState === "noValid") {
              statusRef = statusRef.where("playersLength", "==", 0);
            }
            await statusRef.get().then((gamesSnapshot) => {
              self.matchingGamesByStatus = gamesSnapshot.docs.map((gameDoc) => {
                return gameDoc.id;
              });
            });
          } else {
            self.matchingGamesByStatus = [];
          }
        } else {
          if (self.getCurrentScout.isManager) {
            gameRef = gameRef
              .where("companyGame", "==", self.getCurrentScout.company)
              .where("date", "<", now);
            if (self.selectedDate?.length) {
              if (moment(self.selectedDate).isValid()) {
                gameRef = gameRef
                  .where("date", ">=", moment(self.selectedDate).unix())
                  .where(
                    "date",
                    "<",
                    moment(self.selectedDate).endOf("day").unix()
                  );
              }
            } else {
              gameRef = gameRef.where("date", "<", now);
            }
            if (self.selectedScout.length) {
              gameRef = gameRef.where("addedBy", "==", self.selectedScout);
            }
            gameRef = gameRef.orderBy("date", "desc");
          } else {
            if (self.selectedDate?.length) {
              if (moment(self.selectedDate).isValid()) {
                gameRef = gameRef
                  .where("companyGame", "==", self.getCurrentScout.company)
                  .where("addedBy", "==", self.getCurrentScout.id)
                  .where("date", ">=", moment(self.selectedDate).unix())
                  .where(
                    "date",
                    "<",
                    moment(self.selectedDate).endOf("day").unix()
                  )
                  .orderBy("date", "desc");
              }
            } else {
              gameRef = gameRef
                .where("companyGame", "==", self.getCurrentScout.company)
                .where("addedBy", "==", self.getCurrentScout.id)
                .where("date", "<", now)
                .orderBy("date", "desc");
            }
          }

          if (self.reportState.length) {
            let statusRef = db.collection("V3games");
            if (self.reportState === "valid") {
              if (self.getCurrentScout.isManager) {
                statusRef = statusRef.where(
                  `${self.getCurrentScout.company}PlayersLength`,
                  ">",
                  0
                );
              } else {
                statusRef = statusRef.where(
                  `${self.getCurrentScout.id}PlayersLength`,
                  ">",
                  0
                );
              }
            } else if (self.reportState === "validRed") {
              if (self.getCurrentScout.isManager) {
                statusRef = statusRef
                  .where(`${self.getCurrentScout.company}PlayersLength`, ">", 0)
                  .where(`seenBy${self.getCurrentScout.id}`, "==", true);
              } else {
                statusRef = statusRef.where(
                  `${self.getCurrentScout.id}PlayersLength`,
                  ">",
                  0
                );
              }
            } else if (self.reportState === "noValid") {
              if (self.getCurrentScout.isManager) {
                statusRef = statusRef.where(
                  `${self.getCurrentScout.company}PlayersLength`,
                  "==",
                  0
                );
              } else {
                statusRef = statusRef.where(
                  `${self.getCurrentScout.id}PlayersLength`,
                  "==",
                  0
                );
              }
            }
            let toReturn = [];
            await statusRef.get().then((gamesSnapshot) => {
              toReturn = gamesSnapshot.docs.map((gameDoc) => {
                return gameDoc.id;
              });
            });
            if (!this.getCurrentScout.isManager) {
              const scoutGamesHavingGlobalNote = await db
                .collection("V3games")
                .where("noteLength", ">", 0)
                .get()
                .then((gamesNotesSnapshot) => {
                  return gamesNotesSnapshot.docs.map((gameNoteDoc) => {
                    return gameNoteDoc.id;
                  });
                });
              toReturn = [...toReturn, ...scoutGamesHavingGlobalNote];
              toReturn = self.lodash.uniq(toReturn);
            }

            self.matchingGamesByStatus = toReturn;
          } else {
            self.matchingGamesByStatus = [];
          }
        }
        try {
          await gameRef.onSnapshot((gamesSnapshot) => {
            self.manageGames(gamesSnapshot);
          });
        } catch (error) {
          console.error("Firestore query error:", error);
        }
      }
    },
    async loadCurrentScopeScouts() {
      const self = this;
      if (
        self.getCurrentScout &&
        self.getCurrentScout.isManager &&
        self.getCurrentScout.company &&
        self.getCompanies[0]?.id
      ) {
        if (self.getCurrentScout.company === self.getCompanies[0].id) {
          self.currentScopeScouts = self.getScouts.filter((scout) => {
            return !scout.ghost && scout.company && !scout.isManager;
          });
        } else {
          self.currentScopeScouts = self.getScouts.filter((scout) => {
            return (
              scout.company === self.getCurrentScout.company &&
              !scout.ghost &&
              scout.company &&
              !scout.isManager
            );
          });
        }
      }
    },
  },
  watch: {
    selectedTeam: {
      handler(newValue, oldValue) {
        const self = this;
        self.currentPage = 1;
        const matchingTeam = self.teamsFromApi.filter((team) => {
          return team["Team"] === self.selectedTeam;
        });
        if ((oldValue.length && !newValue.length) || matchingTeam[0]) {
          self.loadCurrentScopeGames();
        }
      },
    },
    reportState() {
      this.currentPage = 1;
      this.loadCurrentScopeGames();
    },
    selectedScout() {
      this.currentPage = 1;
      this.loadCurrentScopeGames();
    },
    selectedDate() {
      this.currentPage = 1;
      this.loadCurrentScopeGames();
    },
    getScouts: {
      handler() {
        if (this.getCurrentScout && this.getCurrentScout.isManager) {
          this.loadCurrentScopeScouts();
        }
      },
      deep: true,
    },
    getCurrentScout: {
      handler() {
        if (this.getCurrentScout && this.getCurrentScout.isManager) {
          this.loadCurrentScopeScouts();
        }
        this.loadCurrentScopeGames();
      },
      deep: true,
    },
    getCompanies: {
      handler() {
        this.loadCurrentScopeGames();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scope>
@import "../assets/scss/variables.scss";
.games {
  background-color: #f6f5ff;
}

.listGameTitleContainer {
  background: #0b00a3;
  margin-bottom: 20px;
  display: inline-flex;
  width: 100%;
}

.filtersSectionContent {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  background-color: white;
  margin: 0px auto 30px auto;
}

.filtersSectionContent > span {
  font-weight: 700;
}

.filtersContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.filtersSectionContainer {
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
}

.filtersSectionContainer .filterContainer {
  display: flex;
  flex: 1;
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
}

.closingFilter:before {
  content: "x";
  position: absolute;
  bottom: 46px;
  right: 0px;
  background: #141414;
  color: white;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3px;
  z-index: 100;
}

.filterContent {
  flex: 1;
  padding: 0 10px;
}

.filterTitle,
.filterContent > div > label {
  margin: 10px 0;
  display: block;
}

.filtersSectionContent,
.listGameTitleContent {
  max-width: 1280px;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  color: #141414;
}

.listGameTitleContent {
  max-width: 1280px;
  padding: 30px 10px;
  margin: 0 auto;
  color: white;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.listGameTitleContent h1 {
  font-weight: 800;
}

.listGameTitleContent button {
  border: 1px solid white;
  background-color: #fff !important;
  box-shadow: none !important;
  border-radius: 3rem;
  color: $blueDark !important;
  padding: 0.75rem 2.5rem;
  font-weight: 700;
  box-shadow: 0 2px 5.3px rgba(0, 0, 0, 0.028),
    0 6.7px 17.9px rgba(0, 0, 0, 0.042), 0 30px 80px rgba(0, 0, 0, 0.07);
}

.listGameTitleContent button:hover {
  background: #1d13ab;
  border: 1px solid #1d13ab;
}

.filtersSectionContent .b-form-datepicker,
.filtersSectionContent select {
  min-height: 40px;
  height: 40px;
  width: 100%;
}

.filtersSectionContent .b-form-datepicker.focus {
  background-color: transparent;
  outline: 0;
  border-color: #141414;
  box-shadow: none;
}

.filtersSectionContent .b-form-datepicker,
.filtersSectionContent .filterContent input.form-control {
  display: inline-flex !important;
  border: 1px solid #141414;
  background: transparent;
  color: #141414 !important;
  padding: 4px 8px;
  margin-right: 15px;
}

.filtersSectionContent .filterContent input.form-control {
  height: 40px;
}

.filtersSectionContent .b-form-datepicker button.btn .b-icon.bi,
.filtersSectionContent .b-form-datepicker label {
  color: #141414 !important;
}

.filtersSectionContent select {
  border-radius: 4px;
  color: $blueDark;
  font-weight: bold;
  padding: 4px 8px;
}

.filtersSectionContent .b-form-datepicker label.form-control,
.filtersSectionContent .filterContent input.form-control {
  color: $blueDark !important;
  font-weight: bold;
  overflow: clip;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .filtersSectionContent,
  .listGameTitleContent {
    display: flex;
    flex-direction: column;
  }

  .filtersSectionContent .b-form-datepicker,
  .filtersSectionContent select {
    margin: 5px 10px;
  }

  .filtersContainer {
    flex-direction: column;
  }

  .filterContent {
    padding: 0;
  }
}
</style>
