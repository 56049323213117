<template>
  <div class="graphContainer">
    <div v-if="yLegend" class="yLegendContainer graphLegendContainer">
      {{ $t(yLegend) }}
    </div>
    <div
      :class="{ graphColumnsContainer: true, graphWithYlegend: yLegend.length }"
    >
      <div class="xContainers">
        <span
          v-for="(xItem, xItemIndex) in x"
          :key="'xContainer' + xItemIndex"
          class="xContainer"
        >
          <span class="xContent">
            <span :style="`height:${getTabLength(y[xItemIndex])}%`">
              {{ y[xItemIndex] ? y[xItemIndex] : "" }}
            </span>
          </span>
        </span>
      </div>
      <div class="xLegendsContainers">
        <span
          v-for="(xItem, xItemIndex) in x"
          :key="'xLabels' + xItemIndex"
          class="xLabel"
        >
          {{ xItem.substring(1) }}
        </span>
      </div>
    </div>
    <div v-if="xLegend" class="xLegendContainer graphLegendContainer">
      {{ $t(xLegend) }}
    </div>
  </div>
</template>
<script>
export default {
  name: "GraphComponent",
  props: {
    x: { type: Array },
    xLegend: { type: String, default: "" },
    y: { type: Array },
    yLegend: { type: String, default: "" },
  },
  data() {
    return {
      months: [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ],
    };
  },
  methods: {
    getTabLength(currentHeight) {
      return (currentHeight * 100) / this.getMaxY;
    },
  },
  computed: {
    getMaxY() {
      const self = this;
      return self.lodash.max(self.y.map((item) => item));
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/scss/variables.scss";

.graphContainer {
  position: relative;
}

.graphWithYlegend {
  padding-left: 20px;
}

.xContainers {
  display: flex;
  overflow: hidden;
  margin-top: 10px;
  font-size: 0.9rem;
}

.xLegendsContainers {
  display: flex;
  margin-top: 5px;
  font-size: 0.9rem;
}

.graphLegendContainer {
  width: 100%;
  font-size: 0.8rem;
}

.yLegendContainer {
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  display: inline-block;
  position: absolute;
  width: 15px;
  text-align: center;
  height: 100%;
}

.xLegendContainer {
  text-align: right;
}

.xContainer {
  flex: 1;
}

.xContent {
  height: 100%;
  padding: 0 3px;
  display: flex;
  align-items: flex-end;
  height: 200px;
}

.xContent span {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  font-weight: 600;
  height: 0%;
  background-color: #dbdef8;
  background: linear-gradient(0deg, #da5856 3px, #f0d1d2 3px);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  color: $blueDark;
  transition: 0.3s;
}

.xContent span:hover {
  color: black;
  background: linear-gradient(0deg, #e3605d 3px, #efd4d5 3px);
}

.xLabel {
  flex: 1;
  text-align: center;
  font-weight: 500;
}
</style>
