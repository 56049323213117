<template>
  <div class="headerContainer">
    <b-row class="headerContent">
      <b-col sm="6" md="3" class="text-left">
        <div class="text-center headerColumnTitle">
          / {{ $t("clubAndNationality") }} /
        </div>
        <div id="team" class="geographicalDatas">
          {{ player.currentTeam }}
        </div>
        <div id="nationality" class="geographicalDatas">
          {{ getPlayerNationalities }}
        </div>
        <div class="geographicalDatas">
          ({{ isCommunalPlayer(player.citizenship) }})
        </div>
      </b-col>
      <b-col xs="12" md="6" class="text-center">
        <div id="nameContainer" :class="{ isGolden: player.isInBucket }">
          {{ player.name }}
        </div>
        <div
          v-if="
            (player.price &&
              player.price !== 'undefined' &&
              player.price !== 'unknown') ||
            (player.salary &&
              player.salary !== 'undefined' &&
              player.salary !== 'unknown')
          "
          :class="{
            pricesContainer: true,
            hasManyPrices:
              player.price &&
              player.salary &&
              player.price != 'undefined' &&
              player.salary != 'undefined',
          }"
        >
          <div
            id="price"
            class="priceContainer"
            v-if="player.price && player.price != 'undefined'"
          >
            {{ player.price + "M.€" }}<br />
            <span v-if="player.bookValue && player.bookValue != 'undefined'"
              >{{ $t("bookValue") }} : {{ player.bookValue }}M.€</span
            >
          </div>
          <div
            id="salary"
            class="priceContainer"
            v-if="
              player.salary &&
              player.salary != 'undefined' &&
              player.salary != 'unknown'
            "
          >
            {{ player.salary + "M.€" }}<span>/{{ $t("year") }}</span>
          </div>
        </div>
        <div id="endContract">
          {{ $t("contractEnds") }} :
          <span>{{ formatDate(player.endContract, false, "date") }}</span>
          <span id="isLoanPlayer" v-if="player.isLoan">{{
            $t("loanPlayer")
          }}</span>
        </div>
        <b-row id="physicalDatasContainer">
          <b-col>
            <div>{{ $t("birth") }}</div>
            <div>{{ formatDate(player.dateOfBirth, false, "date") }}</div>
          </b-col>
          <b-col>
            <div>{{ $t("height") }}</div>
            <div v-if="player.height && player.height != 'undefined'">
              {{ player.height }} cm
            </div>
            <div v-else>{{ $t("undefined") }}</div>
          </b-col>
          <b-col>
            <div>{{ $t("weight") }}</div>
            <div v-if="player.weight && player.weight != 'undefined'">
              {{ player.weight }} kg
            </div>
            <div v-else>{{ $t("undefined") }}</div>
          </b-col>
          <b-col>
            <div>{{ $t("foot") }}</div>
            <div v-if="player.foot && player.foot != 'undefined'">
              {{ getFoot(player.foot) }}
            </div>
            <div v-else>{{ $t("undefined") }}</div>
          </b-col>
          <b-col
            v-if="
              player.manager &&
              player.manager != 'undefined' &&
              player.manager != 'unknown'
            "
          >
            <div>{{ $t("agent") }}</div>
            <div>{{ player.manager }}</div>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="6" md="3" class="text-center">
        <div class="headerColumnTitle">
          / {{ getPrimaryPositionTranslation(player.primaryPosition) }} /
        </div>
        <div id="pitchContainer">
          <img
            :src="getPrimaryPositionId(player.primaryPosition)"
            :alt="$t('pitchView')"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import mixinDates from "../mixins/mixinDates";
export default {
  name: "PlayerHeader",
  mixins: [mixinDates],
  props: { player: Object },
  data() {
    return {
      ueCountries: [
        "germany",
        "austria",
        "belge",
        "belgium",
        "bulgaria",
        "cyprus",
        "croatia",
        "denmark",
        "spain",
        "estonia",
        "finland",
        "france",
        "greece",
        "hungary",
        "ireland",
        "italy",
        "latvia",
        "lithuania",
        "luxembourg",
        "malta",
        "netherlands",
        "poland",
        "portugal",
        "czech republic",
        "romania",
        "slovakia",
        "slovenia",
        "sweden",
      ],
    };
  },
  methods: {
    getFoot(foot) {
      if (!foot) {
        return this.$t("undefined");
      }
      if (foot == "both") {
        return this.$t("ambidextrous");
      }
      return this.$t(foot);
    },
    isCommunalPlayer() {
      if (!this.getPlayerNationalities.length) {
        return this.$t("undefined");
      }
      let countriesToUse = this.getPlayerNationalities.split(", ");
      let playerUeNationalities = [];
      let playerNonUeNationalities = [];
      countriesToUse.forEach((playerCountry) => {
        if (
          this.lodash.indexOf(this.ueCountries, playerCountry.toLowerCase()) >
          -1
        ) {
          playerUeNationalities.push(playerCountry);
        } else {
          playerNonUeNationalities.push(playerCountry);
        }
      });

      return playerUeNationalities.length
        ? this.$t("communal")
        : this.$t("extraCommunal");
    },
    getPrimaryPositionTranslation(position) {
      if (position && position != "undefined") {
        let lowerPosition = position.split(" ").join("");
        const translatedPosition = this.$t(
          "position." +
            lowerPosition.charAt(0).toLowerCase() +
            lowerPosition.slice(1)
        );
        return translatedPosition;
      } else {
        return this.$t("undefined");
      }
    },
    getPrimaryPositionId(position) {
      if (position && position != "undefined") {
        let lowerPosition = position.split(" ").join("");
        return require("../assets/img/pitchPositions/pitch_" +
          lowerPosition.charAt(0).toLowerCase() +
          lowerPosition.slice(1) +
          ".svg")
          ? require("../assets/img/pitchPositions/pitch_" +
              lowerPosition.charAt(0).toLowerCase() +
              lowerPosition.slice(1) +
              ".svg")
          : require("../assets/img/pitchPositions/pitch_empty.svg");
      } else {
        return require("../assets/img/pitchPositions/pitch_empty.svg");
      }
    },
  },
  computed: {
    getPlayerNationalities() {
      if (!this.player.citizenship || !this.player.citizenship.length) {
        return this.$t("undefined");
      }
      let toReturn = [];
      let citizenship = this.player.citizenship;
      if (
        this.player.citizenship &&
        typeof this.player.citizenship === "string"
      ) {
        if (this.player.citizenship.includes(",")) {
          citizenship = this.player.citizenship
            .slice(2)
            .slice(0, -2)
            .split(",")
            .trim();
        } else {
          citizenship = [this.player.citizenship];
        }
      } else {
        citizenship =
          this.player.citizenship[0].split(",").map((natio) => {
            let natioToUse = natio;
            if (natioToUse.charAt(0) === "'") {
              natioToUse = natioToUse.slice(1);
            }
            if (natioToUse.charAt(natioToUse.length - 1) === "'") {
              natioToUse = natioToUse.slice(0, -1);
            }
            return natioToUse;
          }) || "";
      }
      toReturn = citizenship || [];
      return toReturn.join(", ").toString();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.headerContainer {
  background: $blueDark;
}

.headerContent {
  max-width: 1140px;
  margin: 0 auto !important;
  color: white;
}

.geographicalDatas {
  display: flex;
  align-items: center;
  margin-left: 60px;
}

.geographicalDatas:last-of-type {
  margin-bottom: 30px;
}

#team:before {
  padding-right: 10px;
  content: url("../assets/img/club.svg");
}

#nationality:before {
  padding-right: 10px;
  content: url("../assets/img/flag.svg");
}

#nameContainer {
  font-size: 2.5rem;
  text-transform: uppercase;
  padding: 15px 55px;
  border: 1px solid #8b95ea;
}

#nameContainer.isGolden {
  border: 3px solid goldenrod;
  border-radius: 7px;
}

.headerColumnTitle {
  text-transform: uppercase;
  margin-bottom: 25px;
  margin-top: 45px;
}

.pricesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 18px 0;
  margin: -10px auto 5px auto;
  color: $blueDark;
  line-height: 1.6rem;
  font-size: 1.875rem;
  border-radius: 5px;
  font-weight: bold;
  max-width: 255px;
  background-color: white;
}

.pricesContainer.hasManyPrices {
  max-width: 100%;
}

.priceContainer {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 50px;
}

.priceContainer span {
  font-size: 0.875rem;
  color: $blueDark;
  font-weight: normal;
}

.priceContainer:nth-of-type(2) {
  border-left: 2px solid $blueDark;
}

#endContract {
  color: #8b95ea;
  margin-bottom: 40px;
}

#endContract span {
  color: white;
  font-weight: bold;
}

#isLoanPlayer {
  margin-bottom: 20px;
  display: block;
}

#physicalDatasContainer {
  display: flex;
  margin-bottom: 65px;
}

#physicalDatasContainer .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#physicalDatasContainer .col:not(:first-of-type) {
  border-left: 1px solid #8b95ea;
}

#physicalDatasContainer .col > div:first-of-type {
  color: #8b95ea;
  font-size: 1.125rem;
}

#physicalDatasContainer .col > div:last-of-type {
  color: white;
  font-size: 1.125rem;
  font-weight: bold;
}

#pitchContainer {
  max-width: 115px;
  margin: 0 auto 30px auto;
}

@media screen and (max-width: 768px) {
  .geographicalDatas {
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  #physicalDatasContainer {
    margin-bottom: 0;
  }

  .headerContent > div:nth-child(2) {
    order: -1;
  }

  .pricesContainer {
    padding: 8px 0;
  }
}

@media screen and (max-width: 576px) {
  #nameContainer {
    padding: 15px 15px;
    font-size: 1.8rem;
  }
  .geographicalDatas {
    margin-left: 0;
  }

  #physicalDatasContainer {
    margin-bottom: 0;
  }

  #physicalDatasContainer .col {
    padding: 0 5px;
  }
}
</style>
