<template>
  <div class="players" v-if="getCurrentScout">
    <SidebarCreatePlayer :prop="sidebarCreatePlayer" />
    <SidebarFilterPlayers
      :displaySidebar="displayFilterPlayersSidebar"
      :currentYear="getCurrentYear()"
      :reloadData="reloadData"
      :propsDisplayCommentedPlayers="displayCommentedPlayers"
      @doMountRequest="adaptMatchingPlayersList('onMount')"
      @adaptMatchingResults="playersToRequest"
      @toggleSidebarFromSidebar="toggleSidebar"
      @clearReload="clearReload"
    />
    <section class="header">
      <b-container>
        <b-row class="profile-row py-3" align-v="start">
          <b-col>
            <h1 class="mb-3">{{ $t("players") }}</h1>
          </b-col>
          <b-col md="5" xl="6" class="text-right buttonsContainer">
            <b-button
              @click="wantCreatePlayer()"
              class="mt-5 mt-sm-4"
              v-if="!getCurrentScout.isManager"
            >
              {{ $t("create.aPlayer") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="main">
      <b-container>
        <b-row class="pt-5 pb-2 searchNavContainer">
          <b-col md="10" class="searchNavTitleContainer">
            <h2>{{ $t("playersList") }}</h2>
          </b-col>
          <b-col md="2" class="searchNavLayoutSwitcherContainer">
            <b-row>
              <b-col
                md="1"
                :class="{
                  searchNavPictoContainer: true,
                  activePicto: layout == 'bloc',
                }"
                id="switchLayoutToBlocContainer"
                @mouseover="hoverBlocPicto = true"
                @mouseleave="hoverBlocPicto = false"
                @click="switchLayout('bloc')"
              >
                <img
                  v-if="hoverBlocPicto"
                  class="pictoSwitchLayout"
                  src="../assets/img/square_hover.svg"
                />
                <img
                  v-else
                  class="pictoSwitchLayout"
                  src="../assets/img/square.svg"
                />
              </b-col>
              <b-col
                md="1"
                id="switchLayoutToListContainer"
                :class="{
                  searchNavPictoContainer: true,
                  activePicto: layout == 'list',
                }"
                @mouseover="hoverListPicto = true"
                @mouseleave="hoverListPicto = false"
                @click="switchLayout('list')"
              >
                <img
                  v-if="hoverListPicto"
                  class="pictoSwitchLayout"
                  src="../assets/img/list_hover.svg"
                />
                <img
                  v-else
                  class="pictoSwitchLayout"
                  src="../assets/img/list.svg"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="filtersSectionContainer">
          <b-col md="12" class="filtersSectionContent">
            <b-row>
              <b-col md="10" sm="10" class="matchingResultsContainer">
                <h3>{{ $t("filters.filter") }}</h3>
                <span
                  v-if="activeFiltersCounter && matchingPlayers.length"
                  class="matchingResultsContent"
                >
                  {{ matchingPlayers.length }}
                  {{ $tc("plural.matchingResult", matchingPlayers.length) }}
                </span>
              </b-col>
              <b-col md="2" sm="2">
                <div @click="toggleSidebar" class="moreFiltersContainer">
                  <span v-if="!activeFiltersCounter">
                    {{ $t("moreFilters") }}
                  </span>
                  <span v-else>
                    {{ activeFiltersCounter }}
                    {{ $tc("plural.filters", activeFiltersCounter || 1) }}
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row class="filtersContainer">
              <b-col>
                <b-row class="filterHeader">
                  <b-col>
                    {{ $t("searchBy.nameOrClub") }}
                  </b-col>
                </b-row>
                <b-row class="filterInputContainer">
                  <b-col>
                    <b-form-input
                      class="filterInputContent"
                      :disabled="!orderedDatalist.length"
                      v-model="playerNameOrClub"
                      list="listForPlayerName"
                      :placeholder="`${$t('searchBy.placeholderNameAndClub')}`"
                    >
                    </b-form-input>
                    <datalist
                      id="listForPlayerName"
                      v-if="orderedDatalist.length"
                    >
                      >
                      <option
                        v-for="(listItem, listIndex) in orderedDatalist"
                        :key="listIndex"
                        :data-index="listIndex"
                      >
                        {{ listItem.name }}
                      </option>
                    </datalist>
                    <div id="loopContainer" @click="adaptMatchingPlayersList">
                      <img src="../assets/img/loop.svg" alt="Search button" />
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="getCurrentScout.isManager">
                <b-row class="filterHeader">
                  <b-col>
                    {{ $t("searchBy.note") }}
                  </b-col>
                </b-row>
                <b-row class="filterInputContainer">
                  <b-col>
                    <div
                      @click="
                        toggleInputSelector('displayLetterSelector', $event)
                      "
                      id="lettersSelectorContainer"
                      :class="{
                        filterInputContent: true,
                        activeFilter: displayLetterSelector,
                      }"
                    >
                      <span
                        v-if="!lettersSelected.length"
                        class="filterInputPlaceholder"
                      >
                        {{ $t("instructions.selectOneOrManyNotes") }}
                      </span>
                      <div
                        class="lettersSelected"
                        v-for="(letter, letterIndex) in lettersSelected"
                        :key="letterIndex"
                      >
                        {{ letter }}
                      </div>
                    </div>
                    <div
                      v-if="displayLetterSelector"
                      class="selectorOptionsContainer"
                      @mouseleave="
                        toggleInputSelector('displayLetterSelector', $event)
                      "
                    >
                      <div
                        v-for="(letter, letterIndex) in lettersOptions"
                        :key="letterIndex"
                        :class="{
                          selectorOptionContainer: true,
                          checked: lettersSelected.includes(letter),
                        }"
                        @click="toggleItem(letter, lettersSelected, $event)"
                      >
                        {{ letter }}
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="filterHeader d-none d-md-block">
                  <b-col></b-col>
                </b-row>
                <b-row class="filterInputContainer">
                  <b-col>
                    <div class="contributedOnlyContainer filterInputContent">
                      <input
                        type="checkbox"
                        name="displayCommentedPlayers"
                        id="contributedOnlyCheckbox"
                        v-model="displayCommentedPlayers"
                      />
                      <label for="contributedOnlyCheckbox">{{
                        $t("contributesPlayersOnly")
                      }}</label>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <div class="loadingContainer" v-if="loadingMatchingPlayers">
            <p>{{ $t("loading") }}</p>
            <span class="loadingContent"></span>
          </div>
        </b-row>
        <b-row
          id="matchingPlayersStatusContainer"
          v-if="
            activeFiltersCounter &&
            !loadingMatchingPlayers &&
            !displayLimitError &&
            !orderedMatchingPlayersForCurrentPage.length
          "
        >
          <b-col md="12" class="my-2 px-4">
            <span>
              {{ $tc("no.matchingResult", 1) }}
            </span>
          </b-col>
        </b-row>
        <b-row id="filterListAndResultContainer">
          <b-row id="filterResults">
            <div v-if="matchingPlayers.length">
              <b-row
                v-if="
                  layout === 'bloc' &&
                  orderedMatchingPlayersForCurrentPage.length
                "
                id="cardsContainer"
              >
                <b-col
                  class="cardContainer mb-3"
                  lg="3"
                  md="4"
                  sm="6"
                  xs="12"
                  v-for="(
                    player, index
                  ) in orderedMatchingPlayersForCurrentPage"
                  :key="index"
                >
                  <router-link class="cardLink" :to="getPlayerRoute(player.id)">
                    <div
                      :class="{
                        cardContent: true,
                        isGolden: player.isInBucket,
                        isReferral: player.isReferral,
                      }"
                    >
                      <b-row>
                        <b-col class="cardContentName">
                          {{ player.name }}
                        </b-col>
                      </b-row>
                      <b-row class="playerCriteriiContainer">
                        <b-col v-if="player.dateOfBirth">
                          {{ $t("age") }}
                        </b-col>
                        <b-col v-if="player.primaryPosition"
                          >{{ $t("playerPosition") }}
                        </b-col>
                      </b-row>
                      <b-row class="playerCriteriiContainer">
                        <b-col>
                          {{ formatDate(player.dateOfBirth, true) }}
                        </b-col>
                        <b-col
                          v-if="
                            getPositionInitiales(player.primaryPosition) !==
                            'N/A'
                          "
                          :id="`playerInitials_${player.id}`"
                        >
                          {{ getPositionInitiales(player.primaryPosition) }}
                          <b-popover
                            :target="`playerInitials_${player.id}`"
                            triggers="hover focus"
                            placement="bottomleft"
                          >
                            <b-row
                              v-if="
                                getPrimaryPositionTranslation(
                                  player.primaryPosition &&
                                    player.primaryPosition != 'unknown' &&
                                    player.primaryPosition
                                )
                              "
                            >
                              <b-col>
                                {{
                                  getPrimaryPositionTranslation(
                                    player.primaryPosition
                                  )
                                }}
                              </b-col>
                            </b-row>
                          </b-popover>
                        </b-col>
                        <b-col v-else></b-col>
                      </b-row>
                      <b-row class="playerCriteriiContainer">
                        <b-col>
                          {{ $t("status") }}
                        </b-col>
                        <b-col>
                          {{ getPlayerStatus(player) }}
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="getCurrentScout.isManager"
                        class="notesCounterContainer playerCriteriiContainer"
                      >
                        <b-col class="noteCounterBullet">
                          A = {{ player.noteAIds?.length || 0 }}
                        </b-col>
                        <b-col class="noteCounterBullet">
                          B = {{ player.noteBIds?.length || 0 }}
                        </b-col>
                        <b-col class="noteCounterBullet">
                          C = {{ player.noteCIds?.length || 0 }}
                        </b-col>
                        <b-col class="noteCounterBullet">
                          D = {{ player.noteDIds?.length || 0 }}
                        </b-col>
                      </b-row>

                      <b-row class="playerCriteriiContainer">
                        <b-col>
                          {{ $t("filters.currentTeam") }}
                        </b-col>
                      </b-row>
                      <b-row class="playerCriteriiContainer">
                        <b-col>
                          {{ player.currentTeam }}
                        </b-col>
                      </b-row>

                      <b-row class="playerCriteriiContainer">
                        <b-col>
                          {{ $t("filters.nationality") }}
                        </b-col>
                      </b-row>
                      <b-row class="playerCriteriiContainer">
                        <b-col>
                          {{ player.nationality }}
                        </b-col>
                      </b-row>

                      <b-row class="playerCriteriiContainer">
                        <b-col
                          v-if="player.height && player.height != 'undefined'"
                        >
                          {{ $t("height") }}
                        </b-col>
                        <b-col
                          v-if="player.weight && player.weight != 'undefined'"
                          >{{ $t("weight") }}
                        </b-col>
                      </b-row>

                      <b-row class="playerCriteriiContainer">
                        <b-col
                          v-if="player.height && player.height != 'undefined'"
                        >
                          {{ player.height }} cm
                        </b-col>
                        <b-col
                          v-if="player.weight && player.weight != 'undefined'"
                          >{{ player.weight }} kg
                        </b-col>
                      </b-row>

                      <b-row class="playerCriteriiContainer">
                        <b-col v-if="player.endContract">
                          {{ $t("filters.endContract") }}
                        </b-col>
                        <b-col v-if="player.price">{{ $t("price") }} </b-col>
                      </b-row>

                      <b-row class="playerCriteriiContainer">
                        <b-col
                          v-if="
                            parseInt(player.endContract) &&
                            !isNaN(player.endContract)
                          "
                        >
                          {{ formatDate(player.endContract, false, "date") }}
                        </b-col>
                        <b-col
                          v-if="player.price && player.price !== 'undefined'"
                        >
                          {{ player.price }}k €
                        </b-col>
                      </b-row>
                    </div>
                  </router-link>
                </b-col>
              </b-row>
              <b-row
                v-if="
                  layout === 'list' &&
                  orderedMatchingPlayersForCurrentPage.length
                "
              >
                <b-row class="headerListContainer">
                  <b-col
                    class="listFilterParameters colu-10"
                    @click="changeListFilterMatchingPlayersBy('name')"
                  >
                    {{ $t("filters.name") }}
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'name' &&
                        orderMatchingPlayersBy == 'asc'
                      "
                      >&#8593;</span
                    >
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'name' &&
                        orderMatchingPlayersBy == 'desc'
                      "
                      >&#8595;</span
                    >
                  </b-col>
                  <b-col
                    class="listFilterParameters colu-2"
                    @click="changeListFilterMatchingPlayersBy('dateOfBirth')"
                    v-if="filterParameters.dateOfBirth"
                  >
                    {{ $t("filters.dateOfBirth") }}
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'dateOfBirth' &&
                        orderMatchingPlayersBy == 'asc'
                      "
                      >&#8593;</span
                    >
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'dateOfBirth' &&
                        orderMatchingPlayersBy == 'desc'
                      "
                      >&#8595;</span
                    >
                  </b-col>
                  <b-col
                    class="listFilterParameters colu-5"
                    @click="changeListFilterMatchingPlayersBy('currentTeam')"
                    v-if="filterParameters.currentTeam"
                  >
                    {{ $t("filters.currentTeam") }}
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'currentTeam' &&
                        orderMatchingPlayersBy == 'asc'
                      "
                      >&#8593;</span
                    >
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'currentTeam' &&
                        orderMatchingPlayersBy == 'desc'
                      "
                      >&#8595;</span
                    >
                  </b-col>
                  <b-col
                    class="listFilterParameters colu-5"
                    @click="changeListFilterMatchingPlayersBy('nationality')"
                    v-if="filterParameters.nationality"
                  >
                    {{ $t("filters.nationality") }}
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'nationality' &&
                        orderMatchingPlayersBy == 'asc'
                      "
                      >&#8593;</span
                    >
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'nationality' &&
                        orderMatchingPlayersBy == 'desc'
                      "
                      >&#8595;</span
                    >
                  </b-col>
                  <b-col
                    class="listFilterParameters colu-2"
                    @click="changeListFilterMatchingPlayersBy('height')"
                    v-if="filterParameters.height"
                  >
                    {{ $t("filters.height") }}
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'height' &&
                        orderMatchingPlayersBy == 'asc'
                      "
                      >&#8593;</span
                    >
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'height' &&
                        orderMatchingPlayersBy == 'desc'
                      "
                      >&#8595;</span
                    >
                  </b-col>
                  <b-col
                    class="listFilterParameters colu-2"
                    @click="changeListFilterMatchingPlayersBy('weight')"
                    v-if="filterParameters.weight"
                  >
                    {{ $t("filters.weight") }}
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'weight' &&
                        orderMatchingPlayersBy == 'asc'
                      "
                      >&#8593;</span
                    >
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'weight' &&
                        orderMatchingPlayersBy == 'desc'
                      "
                      >&#8595;</span
                    >
                  </b-col>
                  <b-col
                    class="listFilterParameters colu-5"
                    @click="changeListFilterMatchingPlayersBy('endContract')"
                    v-if="filterParameters.endContract"
                  >
                    {{ $t("filters.endContract") }}
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'endContract' &&
                        orderMatchingPlayersBy == 'asc'
                      "
                      >&#8593;</span
                    >
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'endContract' &&
                        orderMatchingPlayersBy == 'desc'
                      "
                      >&#8595;</span
                    >
                  </b-col>
                  <b-col
                    class="listFilterParameters colu-2"
                    @click="changeListFilterMatchingPlayersBy('price')"
                    v-if="filterParameters.price"
                  >
                    {{ $t("filters.price") }}
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'price' &&
                        orderMatchingPlayersBy == 'asc'
                      "
                      >&#8593;</span
                    >
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'price' &&
                        orderMatchingPlayersBy == 'desc'
                      "
                      >&#8595;</span
                    >
                  </b-col>
                  <b-col
                    class="listFilterParameters colu-2"
                    @click="
                      changeListFilterMatchingPlayersBy(
                        'commentedGamesIdsLength'
                      )
                    "
                    v-if="filterParameters.commentedGamesIdsLength"
                  >
                    {{ $t("filters.commentedGamesIdsLength") }}
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'commentedGamesIdsLength' &&
                        orderMatchingPlayersBy == 'asc'
                      "
                      >&#8593;</span
                    >
                    <span
                      v-if="
                        filterMatchingPlayersBy == 'commentedGamesIdsLength' &&
                        orderMatchingPlayersBy == 'desc'
                      "
                      >&#8595;</span
                    >
                  </b-col>
                </b-row>
                <b-row class="rowListContainer">
                  <b-col
                    class="listContainer"
                    v-for="(
                      player, index
                    ) in orderedMatchingPlayersForCurrentPage"
                    :key="index"
                  >
                    <router-link
                      :class="{
                        listContent: true,
                        isGolden: player.isInBucket,
                        isReferral: player.isReferral,
                      }"
                      :to="getPlayerRoute(player.id)"
                    >
                      <div
                        class="listContentItem text-center colu-10 uppercaseColumn"
                      >
                        {{ displayValueOrUndefined(player.name) }}
                      </div>
                      <div
                        class="listContentItem colu-2 lowercaseColumn"
                        v-if="filterParameters.dateOfBirth"
                        :id="`player_${player.id}_dateOfBirth`"
                      >
                        {{ formatDate(player.dateOfBirth, true) }}
                      </div>
                      <b-popover
                        :target="`player_${player.id}_dateOfBirth`"
                        triggers="hover focus"
                        placement="bottomleft"
                      >
                        <b-row>
                          <b-col>
                            {{ $t("playerIsBornIn") }}
                            {{ formatDate(player.dateOfBirth, false, "date") }}
                          </b-col>
                        </b-row>
                      </b-popover>
                      <div
                        class="listContentItem colu-5 uppercaseColumn"
                        v-if="filterParameters.currentTeam"
                      >
                        {{ displayValueOrUndefined(player.currentTeam) }}
                      </div>
                      <div
                        class="listContentItem colu-5 uppercaseColumn"
                        v-if="filterParameters.nationality"
                      >
                        {{ displayValueOrUndefined(player.nationality) }}
                      </div>
                      <div
                        class="listContentItem colu-2 lowercaseColumn"
                        v-if="filterParameters.height"
                      >
                        {{
                          displayValueOrUndefined(player.height) !== "-"
                            ? displayValueOrUndefined(player.height) + " cm"
                            : "-"
                        }}
                      </div>
                      <div
                        class="listContentItem colu-2 lowercaseColumn"
                        v-if="filterParameters.weight"
                      >
                        {{
                          displayValueOrUndefined(player.weight) !== "-"
                            ? displayValueOrUndefined(player.weight) + " kg"
                            : "-"
                        }}
                      </div>
                      <div
                        class="listContentItem colu-5 lowercaseColumn"
                        v-if="filterParameters.endContract"
                      >
                        <div
                          :id="`playerEndContactDate${player.id}`"
                          v-if="isNaN(player.endContract)"
                        >
                          -
                        </div>
                        <div v-else class="uppercaseColumn">
                          {{ formatDate(player.endContract, false, "date") }}
                        </div>
                        <b-popover
                          v-if="player.monthsBeforeDueDate > 0"
                          :target="`playerEndContactDate${player.id}`"
                          triggers="hover focus"
                          placement="bottom"
                        >
                          <b-row v-if="player.monthsBeforeDueDate">
                            <b-col>
                              {{ player.monthsBeforeDueDate }}
                              {{
                                $tc(
                                  "plural.pendingMonths",
                                  player.monthsBeforeDueDate
                                )
                              }}
                            </b-col>
                          </b-row>
                        </b-popover>
                      </div>
                      <div
                        class="listContentItem colu-2 uppercaseColumn"
                        v-if="filterParameters.price"
                      >
                        {{ displayValueOrUndefined(player.price) }}
                      </div>
                      <div
                        class="listContentItem bulletInfoContainer colu-2 lowercaseColumn"
                      >
                        <div
                          :id="`card_${player.id}_${getPlayerStatus(player)}`"
                          :class="{
                            playerStatus: true,
                            isManager: getCurrentScout.isManager,
                          }"
                        >
                          {{ getPlayerStatus(player) }}
                        </div>
                        <b-popover
                          :target="`card_${player.id}_${getPlayerStatus(
                            player
                          )}`"
                          triggers="hover focus"
                          placement="bottomleft"
                        >
                          <b-row
                            v-if="
                              getPlayerStatus(player) &&
                              getCurrentScout.isManager
                            "
                            class="notesCounterContainer playerCriteriiContainer"
                          >
                            <b-col class="noteCounterBullet">
                              A = {{ player.noteAIds?.length || 0 }}
                            </b-col>
                            <b-col class="noteCounterBullet">
                              B = {{ player.noteBIds?.length || 0 }}
                            </b-col>
                            <b-col class="noteCounterBullet">
                              C = {{ player.noteCIds?.length || 0 }}
                            </b-col>
                            <b-col class="noteCounterBullet">
                              D = {{ player.noteDIds?.length || 0 }}
                            </b-col>
                          </b-row>
                        </b-popover>
                      </div>
                    </router-link>
                  </b-col>
                </b-row>
              </b-row>
              <b-row id="footerResultContainer">
                <b-col id="footerResultContent">
                  <div id="selectNumberOfResultContainer">
                    <div>
                      <input
                        type="number"
                        min="1"
                        v-model="perPage"
                        @blur="checkMinValue"
                      />
                    </div>
                    <div>
                      <span> {{ $t("resultsPerPage") }} </span>
                    </div>
                  </div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="matchingPlayers.length"
                    :hideGotoEndButtons="true"
                    :per-page="perPage"
                    align="right"
                  />
                </b-col>
              </b-row>
            </div>
            <div v-if="displayLimitError">
              <b-row class="errorsContainer">
                <b-col>
                  {{ filteredPlayersLength }}
                  {{
                    $tc("plural.matchingResult", filteredPlayersLength || 1)
                  }}.
                  {{ limitError }}
                </b-col>
              </b-row>
            </div>
          </b-row>
        </b-row>
      </b-container>
    </section>
    <div
      v-if="getCurrentScout.isManager && missingPlayersIds.length"
      :class="[showAlert ? 'extendAlert' : 'collapseAlert', 'alertContainer']"
    >
      <div class="alertExit" @click="toggleAlert">
        <font-awesome-icon v-if="showAlert" icon="eye-slash" />
        <font-awesome-icon v-else icon="eye" />
      </div>
      <div
        :class="[showAlert ? 'extendAlert' : 'collapseAlert', 'alertContent']"
      >
        {{ displayAlertMessage }}
      </div>
    </div>
  </div>
</template>
<script>
import SidebarCreatePlayer from "@/components/SidebarCreatePlayer";
import SidebarFilterPlayers from "@/components/SidebarFilterPlayers";
import { mapActions, mapState, mapGetters } from "vuex";
import moment from "moment";
import db from "../db";
import mixinDates from "../mixins/mixinDates";
import mixinTranslation from "../mixins/mixinTranslation";

export default {
  name: "PlayersComponent",
  mixins: [mixinDates, mixinTranslation],
  components: {
    SidebarCreatePlayer,
    SidebarFilterPlayers,
  },
  data() {
    return {
      displayLetterSelector: false,
      lettersSelected: [],
      lettersOptions: ["A", "B", "C", "D"],
      filteredPlayersLength: 0,
      filterToUse: "",
      reloadData: false,
      activeFiltersCounter: 0,
      textFilterType: null,
      displayLimitError: false,
      orderedMatchingPlayersForCurrentPage: [],
      maxLimit: 2000,
      limitError: "",
      datalist: [],
      loadingMatchingPlayers: false,
      isActivePriceRange: false,
      isActiveYearsRange: false,
      isActiveEndContractRange: false,
      isActiveMaxSpeedRange: false,
      isActiveTotalDistRange: false,
      isActiveDistMoreThan20kmh: false,
      isActiveHeightRange: false,
      isActiveWeightRange: false,
      currentPage: 1,
      perPage: 20,
      layout: "list",
      maxPrice: 200,
      yearsRange: [1971, moment().format("YYYY")],
      maxEndContract: 96,
      maxWeight: 120,
      maxSpeedRange: [0, 100],
      totalDistRange: [0, 100],
      distMoreThan20kmhRange: [0, 100],
      accelerationMoreThan3mRange: [0, 100],
      maxHeight: 150,
      heightRange: [0, 150],
      playerNameOrClub: "",
      priceRange: [0, 150],
      endContractRange: [0, 96],
      weightRange: [0, 120],
      sidebarCreatePlayer: {
        display: false,
      },
      displayFilterPlayersSidebar: false,
      missingPlayersIds: [],
      alertMessage: "",
      showAlert: true,
      loadingBucketPlayers: false,
      bucketPercentage: "",
      filterParameters: {
        height: true,
        weight: true,
        currentTeam: true,
        nationality: true,
        dateOfBirth: true,
        price: true,
        endContract: true,
        commentedGamesIdsLength: true,
      },
      matchingPlayers: [],
      hoverBlocPicto: false,
      hoverListPicto: false,
      hoverFilterPicto: false,
      positionsSelected: [],
      displayBucketPlayers: false,
      displayNotCommentedPlayers: false,
      displayCommentedPlayers: false,
      footSelected: "",
      filterMatchingPlayersBy: "commentedGamesIdsLength",
      orderMatchingPlayersBy: "desc",
      communalFilter: "",
    };
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },

  mounted() {
    if (this.getCurrentScout.id) {
      if (Object.keys(this.getPlayersFilters).length) {
        this.checkDefaultFilters();

        this.adaptMatchingPlayersList();
      } else {
        this.adaptMatchingPlayersList("onMount");
      }
    }
    if (this.getCurrentScout?.isManager) {
      if (!this.scouts.length) {
        this.bindScouts();
      }
      if (!this.scoutsPlayers.length) {
        this.bindScoutsPlayers();
      }
    }
    if (this.playersFromApi.length) {
      this.generateDatalist();
    }
  },
  computed: {
    ...mapState(["scouts", "scoutsPlayers", "playersFromApi"]),
    ...mapGetters([
      "getCurrentScoutPlayers",
      "getCurrentScout",
      "getCompanies",
      "getPlayersFilters",
    ]),
    getCurrentCommentedFilter() {
      if (!this.getCurrentScout.isManager) {
        return this.filterParameters[
          `${this.getCurrentScout.id}CommentedGamesIdsLength`
        ];
      }

      if (this.getCurrentScout.company !== this.getCompanies[0].id) {
        return this.filterParameters[
          `${this.getCurrentScout.company}CommentedGamesIdsLength`
        ];
      }
      return this.filterParameters.commentedGamesIdsLength;
    },
    orderedDatalist() {
      return this.lodash.orderBy(
        this.lodash.uniqBy(this.datalist, "name"),
        "name",
        "asc"
      );
    },
    colorLegends() {
      return {
        yellowBullet: this.$t("legend.yellow"),
        redBullet: this.$t("legend.red"),
        blueBullet: this.$t("legend.blue"),
        emptyBullet: this.$t("legend.empty"),
      };
    },
    displayAlertMessage() {
      return (
        this.alertMessage +
        "(" +
        this.missingPlayersIds.length +
        ") " +
        this.missingPlayersIds
      );
    },
  },
  methods: {
    ...mapActions(["bindScouts", "bindScoutsPlayers"]),
    generateDatalist() {
      const toReturn = [];
      this.playersFromApi.forEach((player) => {
        toReturn.push({ name: player.currentTeam, item: "club" });
        toReturn.push({ name: player.name, item: "player" });
      });

      this.datalist = toReturn;
    },
    async queryNotesBasedOnMoment(momentFilter) {
      const toReturn = [];
      await db
        .collection("V3notes")
        .where("id", ">=", momentFilter)
        .get()
        .then((notesSnapshot) => {
          notesSnapshot.docs.forEach((noteDoc) => {
            const noteData = noteDoc.data();
            if (noteDoc.id.toString().substring(0, 10) >= momentFilter) {
              toReturn.push(noteData.playerId);
            }
          });
        });

      return toReturn;
    },
    toggleItem(itemToToggle, items) {
      if (items.includes(itemToToggle)) {
        const indexOfItem = items.indexOf(itemToToggle);
        this[items] = items.splice(indexOfItem, 1);
      } else {
        items.push(itemToToggle);
      }
    },
    toggleInputSelector(selectorToToggle, event) {
      if (event.target.classList.contains("lettersSelected")) {
        this.toggleItem(
          event.target.innerHTML.trim(),
          this.lettersSelected,
          event
        );
      } else {
        if (selectorToToggle === "displayLetterSelector") {
          this.displayLetterSelector = !this.displayLetterSelector;
        }
      }
    },
    checkDefaultFilters() {
      if (Object.keys(this.getPlayersFilters).length) {
        this.accelerationMoreThan3mRange =
          this.getPlayersFilters.accelerationMoreThan3mRange;
        this.communalFilter = this.getPlayersFilters.communalFilter;
        this.displayBucketPlayers = this.getPlayersFilters.displayBucketPlayers;
        this.displayCommentedPlayers =
          this.getPlayersFilters.displayCommentedPlayers;
        this.displayNotCommentedPlayers =
          this.getPlayersFilters.displayNotCommentedPlayers;
        this.distMoreThan20kmhRange =
          this.getPlayersFilters.distMoreThan20kmhRange;
        this.endContractRange = this.getPlayersFilters.endContractRange;
        this.footSelected = this.getPlayersFilters.footSelected;
        this.heightRange = this.getPlayersFilters.heightRange;
        this.isActiveAccelerationMoreThan3m =
          this.getPlayersFilters.isActiveAccelerationMoreThan3m;
        this.isActiveDistMoreThan20kmh =
          this.getPlayersFilters.isActiveDistMoreThan20kmh;
        this.isActiveEndContractRange =
          this.getPlayersFilters.isActiveEndContractRange;
        this.isActiveHeightRange = this.getPlayersFilters.isActiveHeightRange;
        this.isActiveMaxSpeedRange =
          this.getPlayersFilters.isActiveMaxSpeedRange;
        this.isActivePriceRange = this.getPlayersFilters.isActivePriceRange;
        this.isActiveTotalDistRange =
          this.getPlayersFilters.isActiveTotalDistRange;
        this.isActiveWeightRange = this.getPlayersFilters.isActiveWeightRange;
        this.isActiveYearsRange = this.getPlayersFilters.isActiveYearsRange;
        this.maxSpeedRange = this.getPlayersFilters.maxSpeedRange;
        this.positionsSelected = this.getPlayersFilters.positionsSelected;
        this.priceRange = this.getPlayersFilters.priceRange;
        this.totalDistRange = this.getPlayersFilters.totalDistRange;
        this.weightRange = this.getPlayersFilters.weightRange;
        this.yearsRange = this.getPlayersFilters.yearsRange;
      }
    },
    setFilterToUse() {
      const self = this;
      if (self.filterMatchingPlayersBy !== "commentedGamesIdsLength") {
        self.filterToUse = self.filterMatchingPlayersBy;
      } else if (self.getCurrentScout?.isManager) {
        if (self.getCurrentScout.company !== self.getCompanies[0].id) {
          self.filterToUse = `${self.getCurrentScout.company}CommentedGamesIdsLength`;
        } else {
          self.filterToUse = "commentedGamesIdsLength";
        }
      } else if (!self.getCurrentScout?.isManager) {
        if (self.getCurrentScout) {
          self.filterToUse = `${self.getCurrentScout.id}CommentedGamesIdsLength`;
        }
      }
    },
    clearReload() {
      this.reloadData = false;
    },
    toggleSidebar() {
      this.displayFilterPlayersSidebar = !this.displayFilterPlayersSidebar;
    },
    getCurrentYear() {
      return parseInt(moment().format("YYYY"));
    },
    playersToRequest(valuesToUse) {
      const self = this;
      Object.keys(valuesToUse).forEach((item) => {
        switch (item) {
          case "displayBucketPlayers":
            self.displayBucketPlayers = valuesToUse["displayBucketPlayers"];
            break;
          case "displayNotCommentedPlayers":
            self.displayNotCommentedPlayers =
              valuesToUse["displayNotCommentedPlayers"];
            break;
          case "displayCommentedPlayers":
            self.displayCommentedPlayers =
              valuesToUse["displayCommentedPlayers"];
            break;
          case "positionsSelected":
            self.positionsSelected = valuesToUse["positionsSelected"];
            break;
          case "footSelected":
            self.footSelected = valuesToUse["footSelected"];
            break;
          case "priceRange":
            self.priceRange[0] = valuesToUse["priceRange"][0];
            self.priceRange[1] = valuesToUse["priceRange"][1];
            break;
          case "endContractRange":
            self.endContractRange[0] = valuesToUse["endContractRange"][0];
            self.endContractRange[1] = valuesToUse["endContractRange"][1];
            break;
          case "heightRange":
            self.heightRange[0] = valuesToUse["heightRange"][0];
            self.heightRange[1] = valuesToUse["heightRange"][1];
            break;
          case "weightRange":
            self.weightRange[0] = valuesToUse["weightRange"][0];
            self.weightRange[1] = valuesToUse["weightRange"][1];
            break;
          case "maxSpeedRange":
            self.maxSpeedRange[0] = valuesToUse["maxSpeedRange"][0];
            self.maxSpeedRange[1] = valuesToUse["maxSpeedRange"][1];
            break;
          case "totalDistRange":
            self.totalDistRange[0] = valuesToUse["totalDistRange"][0];
            self.totalDistRange[1] = valuesToUse["totalDistRange"][1];
            break;
          case "distMoreThan20kmhRange":
            self.distMoreThan20kmhRange[0] =
              valuesToUse["distMoreThan20kmhRange"][0];
            self.distMoreThan20kmhRange[1] =
              valuesToUse["distMoreThan20kmhRange"][1];
            break;
          case "accelerationMoreThan3mRange":
            self.accelerationMoreThan3mRange[0] =
              valuesToUse["accelerationMoreThan3mRange"][0];
            self.accelerationMoreThan3mRange[1] =
              valuesToUse["accelerationMoreThan3mRange"][1];
            break;
          case "yearsRange":
            self.yearsRange[0] = valuesToUse["yearsRange"][0];
            self.yearsRange[1] = valuesToUse["yearsRange"][1];
            break;
          case "communalFilter":
            self.communalFilter = valuesToUse["communalFilter"];
            break;
          case "isActivePriceRange":
            self.isActivePriceRange = valuesToUse["isActivePriceRange"];
            break;
          case "isActiveEndContractRange":
            self.isActiveEndContractRange =
              valuesToUse["isActiveEndContractRange"];
            break;
          case "isActiveHeightRange":
            self.isActiveHeightRange = valuesToUse["isActiveHeightRange"];
            break;
          case "isActiveWeightRange":
            self.isActiveWeightRange = valuesToUse["isActiveWeightRange"];
            break;
          case "isActiveMaxSpeedRange":
            self.isActiveMaxSpeedRange = valuesToUse["isActiveMaxSpeedRange"];
            break;
          case "isActiveTotalDistRange":
            self.isActiveTotalDistRange = valuesToUse["isActiveTotalDistRange"];
            break;
          case "isActiveDistMoreThan20kmh":
            self.isActiveDistMoreThan20kmh =
              valuesToUse["isActiveDistMoreThan20kmh"];
            break;
          case "isActiveAccelerationMoreThan3m":
            self.isActiveAccelerationMoreThan3m =
              valuesToUse["isActiveAccelerationMoreThan3m"];
            break;
          case "isActiveYearsRange":
            self.isActiveYearsRange = valuesToUse["isActiveYearsRange"];
            break;
        }
      });

      this.adaptMatchingPlayersList();
    },
    setMatchingPlayers(matchingPlayers = []) {
      const self = this;
      self.loadingMatchingPlayers = false;
      self.setFilterToUse();
      let filteredPlayers = matchingPlayers.filter((player) => {
        if (
          player[self.filterToUse] === 0 ||
          (player[self.filterToUse] !== undefined &&
            player[self.filterToUse] !== null &&
            player[self.filterToUse] !== "undefined" &&
            player[self.filterToUse] !== "unknown")
        ) {
          return true;
        }
        return false;
      });

      if (self.contributedOnly) {
        if (!self.getCurrentScout.isManager) {
          filteredPlayers = filteredPlayers.filter((player) => {
            return player[`${self.getCurrentScout.id}CommentedGamesIdsLength`];
          });
        } else {
          if (self.getCurrentScout.company && self.getCompanies[0].id) {
            filteredPlayers = filteredPlayers.filter((player) => {
              return player.commentedGamesIdsLength;
            });
          } else {
            filteredPlayers = filteredPlayers.filter((player) => {
              return player[
                `${self.getCurrentScout.company}CommentedGamesIdsLength`
              ];
            });
          }
        }
      }
      self.filteredPlayersLength = filteredPlayers.length || 0;
      if (filteredPlayers.length <= self.maxLimit) {
        self.displayLimitError = false;
        self.matchingPlayers = filteredPlayers;

        const playersToReturn = self.lodash.orderBy(
          filteredPlayers,
          self.filterToUse,
          self.orderMatchingPlayersBy
        );
        self.orderedMatchingPlayersForCurrentPage = playersToReturn.slice(
          (self.currentPage - 1) * self.perPage,
          self.currentPage * self.perPage
        );
      } else {
        self.limitError = this.$t("error.limitExceeded");
        self.displayLimitError = true;
      }
    },
    async getPlayersAccodingURLFilters(
      queryType,
      paramId,
      scoutFilter,
      thirdParam
    ) {
      const self = this;
      let momentFilter = "";
      if (scoutFilter == "week") {
        momentFilter = moment().subtract(1, "weeks").startOf("day").unix();
      } else if (scoutFilter == "month") {
        momentFilter = moment().subtract(1, "months").startOf("day").unix();
      } else if (scoutFilter == "year") {
        momentFilter = moment().subtract(1, "years").startOf("day").unix();
      } else if (scoutFilter == "all") {
        momentFilter = 1577836800; //1er janvier 2020
      }
      let playersId = [];
      if (queryType === "scout") {
        if (momentFilter == "" && paramId !== "-") {
          await db
            .collection("V3notes")
            .where("authorId", "==", paramId.toString())
            .get()
            .then((notesSnapshot) => {
              notesSnapshot.docs.forEach((noteDoc) => {
                const noteData = noteDoc.data();
                playersId.push(noteData.playerId);
              });
            });
        } else if (paramId !== "-") {
          await db
            .collection("V3notes")
            .where("authorId", "==", paramId.toString())
            .where("id", ">=", momentFilter)
            .get()
            .then((notesSnapshot) => {
              notesSnapshot.docs.forEach((noteDoc) => {
                const noteData = noteDoc.data();
                if (noteDoc.id.toString().substring(0, 10) >= momentFilter) {
                  playersId.push(noteData.playerId);
                }
              });
            });
        } else {
          playersId = this.queryNotesBasedOnMoment(momentFilter);
        }
      } else if (queryType === "letter") {
        if (momentFilter == "" && paramId !== "-") {
          if (thirdParam !== "-") {
            await db
              .collection("V3notes")
              .where("letterNote", "==", paramId.toString())
              .where("authorId", "==", thirdParam.toString())
              .get()
              .then((notesSnapshot) => {
                notesSnapshot.docs.forEach((noteDoc) => {
                  const noteData = noteDoc.data();
                  playersId.push(noteData.playerId);
                });
              });
          } else {
            await db
              .collection("V3notes")
              .where("letterNote", "==", paramId.toString())
              .get()
              .then((notesSnapshot) => {
                notesSnapshot.docs.forEach((noteDoc) => {
                  const noteData = noteDoc.data();
                  playersId.push(noteData.playerId);
                });
              });
          }
        } else if (paramId !== "-") {
          if (thirdParam !== "-") {
            await db
              .collection("V3notes")
              .where("letterNote", "==", paramId.toString())
              .where("authorId", "==", thirdParam.toString())
              .where("id", ">=", momentFilter)
              .get()
              .then((notesSnapshot) => {
                notesSnapshot.docs.forEach((noteDoc) => {
                  const noteData = noteDoc.data();
                  if (noteDoc.id.toString().substring(0, 10) >= momentFilter) {
                    playersId.push(noteData.playerId);
                  }
                });
              });
          } else {
            await db
              .collection("V3notes")
              .where("letterNote", "==", paramId.toString())
              .where("id", ">=", momentFilter)
              .get()
              .then((notesSnapshot) => {
                notesSnapshot.docs.forEach((noteDoc) => {
                  const noteData = noteDoc.data();
                  if (noteDoc.id.toString().substring(0, 10) >= momentFilter) {
                    playersId.push(noteData.playerId);
                  }
                });
              });
          }
        } else {
          playersId = this.queryNotesBasedOnMoment(momentFilter);
        }
      }

      const playersToUse = [];
      const chunkedPlayers = self.lodash.chunk(self.lodash.uniq(playersId), 10);

      for (let i = 0; i < chunkedPlayers.length; i++) {
        await db
          .collection("V3players")
          .where("id", "in", chunkedPlayers[i])
          .get()
          .then((playersSnapshot) => {
            playersSnapshot.docs.forEach((playerDoc) => {
              playersToUse.push(playerDoc.data());
            });
          });
      }
      return playersToUse;
    },
    resetFiltersForSearch() {
      const self = this;
      self.reloadData = true;
      self.playerNameOrClub = "";
      self.positionsSelected = [];
      self.isActivePriceRange = false;
      self.isActiveYearsRange = false;
      self.isActiveMaxSpeedRange = false;
      self.isActiveTotalDistRange = false;
      self.isActiveDistMoreThan20kmh = false;
      self.isActiveAccelerationMoreThan3m = false;
      self.isActiveEndContractRange = false;
      self.communalFilter = "";
      self.displayBucketPlayers = false;
      self.displayNotCommentedPlayers = false;
      self.displayCommentedPlayers = false;
      self.footSelected = "";
      self.isActiveHeightRange = false;
      self.isActiveWeightRange = false;
      self.activeFiltersCounter = 0;
      self.adaptMatchingPlayersList();
    },
    displayValueOrUndefined(value) {
      if (value != "undefined" && value != "unknown") {
        return value;
      }
      return "-";
    },
    getPrimaryPositionTranslation(position) {
      if (position && position != "undefined") {
        let lowerPosition = position.split(" ").join("");
        if (lowerPosition == "unknown") {
          return;
        }
        const translatedPosition = this.$t(
          "position." +
            lowerPosition.charAt(0).toLowerCase() +
            lowerPosition.slice(1)
        );
        return translatedPosition;
      } else {
        return;
      }
    },
    getPositionInitiales(value) {
      if (!value || value == "undefined") {
        return "N/A";
      }
      return (
        this.getPrimaryPositionTranslation(value) &&
        this.getPrimaryPositionTranslation(value)
          .split(" ")
          .map((n) => n[0])
          .join(" ")
      );
    },
    resizeHandler(e) {
      if (e.target.innerWidth <= 768) {
        this.switchLayout("bloc");
      }
    },
    getPlayerRoute(playerId) {
      return this.getCurrentScout.isManager
        ? `/globalReportForPlayer/${playerId}`
        : `/player/${playerId}`;
    },
    changeListFilterMatchingPlayersBy(target) {
      const self = this;
      if (target === self.filterMatchingPlayersBy) {
        self.orderMatchingPlayersBy =
          self.orderMatchingPlayersBy == "asc" ? "desc" : "asc";
      } else {
        self.filterMatchingPlayersBy = target;
        self.orderMatchingPlayersBy = "asc";
      }
    },
    async adaptMatchingPlayersList(param = false) {
      if (!this.getCompanies.length) {
        return;
      }
      const self = this;
      self.setMatchingPlayers([]);
      if (!self.getCurrentScout?.id) {
        return;
      }
      if (self.perPage == "0") {
        self.perPage = 20;
      } else {
        self.perPage = parseInt(self.perPage);
      }
      if (isNaN(self.currentPage)) {
        self.currentPage = 0;
      } else {
        self.currentPage = parseInt(self.currentPage);
      }
      self.loadingMatchingPlayers = true;

      let getPlayersRef = db
        .collection("V3players")
        .where("isArchivePlayer", "==", false)
        .where("gender", "==", "male");

      const url = window.location.href.split("/players/");
      const usableOnly = (url[1] && url[1].slice(1, url[1].length)) || "";
      const usableSplitted = usableOnly.split("&");
      const queryParameters = {};
      usableSplitted.forEach((itemToUse) => {
        const splittedItem = itemToUse.split("=");
        queryParameters[splittedItem[0]] = splittedItem[1];
      });
      const scoutId = url[1] && url[1].split("/")[0];
      const scoutFilter = url[1] && url[1].split("/")[1];
      if (param == "onMount") {
        getPlayersRef = getPlayersRef
          .where("commentedGamesIdsLength", ">", 0)
          .orderBy("commentedGamesIdsLength", "desc");

        const toReturn = [];

        await getPlayersRef
          .limit(100)
          .get()
          .then((playersSnapshot) => {
            playersSnapshot.docs.forEach((playerDoc) => {
              toReturn.push(playerDoc.data());
            });
          });

        self.setMatchingPlayers(toReturn);
        // }
      } else if (
        !self.playerNameOrClub.length &&
        !self.positionsSelected.length &&
        !self.isActivePriceRange &&
        !self.isActiveYearsRange &&
        !self.isActiveMaxSpeedRange &&
        !self.isActiveTotalDistRange &&
        !self.isActiveDistMoreThan20kmh &&
        !self.isActiveAccelerationMoreThan3m &&
        !self.isActiveEndContractRange &&
        self.communalFilter == "" &&
        !self.displayBucketPlayers &&
        !self.displayNotCommentedPlayers &&
        !self.displayCommentedPlayers &&
        !self.footSelected.length &&
        !self.isActiveHeightRange &&
        !self.isActiveWeightRange &&
        !self.lettersSelected.length
      ) {
        self.activeFiltersCounter = 0;
        const usablePlayers = [];
        if (self.getCurrentScout.isManager) {
          await getPlayersRef
            .where(self.filterToUse, "!=", "undefined")
            .orderBy(self.filterToUse, self.orderMatchingPlayersBy)
            .limit(self.maxLimit)
            .get()
            .then(async (playersSnapshot) => {
              return playersSnapshot.docs.map((playerDoc) => {
                usablePlayers.push(playerDoc.data());
              });
            });
        } else {
          if (self.filterToUse !== "commentedGamesIdsLength") {
            await getPlayersRef
              .where(self.filterToUse, "!=", "undefined")
              .orderBy(self.filterToUse, self.orderMatchingPlayersBy)
              .limit(self.maxLimit)
              .get()
              .then(async (playersSnapshot) => {
                return playersSnapshot.docs.map((playerDoc) => {
                  usablePlayers.push(playerDoc.data());
                });
              });
          } else {
            await getPlayersRef
              .where(self.filterToUse, "!=", "undefined")
              .orderBy(self.filterToUse, self.orderMatchingPlayersBy)
              .limit(self.maxLimit)
              .get()
              .then(async (playersSnapshot) => {
                return playersSnapshot.docs.map((playerDoc) => {
                  usablePlayers.push(playerDoc.data());
                });
              });
          }
        }
        self.setMatchingPlayers(usablePlayers);
      } else {
        let currentScoutPlayersForCurrentPeriod = [];
        let playersForSearchBar = [];
        let playersForSelectedPosition = [];
        let playersForPriceRange = [];
        let playersForYearsRange = [];
        let playersForAvailability = [];
        let playersForMaxSpeed = [];
        let playersForTotalDist = [];
        let playersForDistMoreThan20kmh = [];
        let playersForAccelerationMoreThan3m = [];
        let playersForSelectedGeolocalisation = [];
        let bucketPlayers = [];
        let playersWithNoComment = [];
        let playersWithComments = [];
        let playersByFoot = [];
        let playersForHeightRange = [];
        let playersForWeightRange = [];
        self.activeFiltersCounter = 0;

        if (self.lettersSelected.length) {
          self.activeFiltersCounter++;
        }

        if (self.playerNameOrClub.length > 3 && self.textFilterType) {
          self.activeFiltersCounter++;
        }

        if (scoutId?.length && scoutFilter?.length) {
          self.activeFiltersCounter++;
        }

        if (self.positionsSelected.length) {
          self.activeFiltersCounter++;
        }

        if (self.isActivePriceRange) {
          self.activeFiltersCounter++;
        }

        if (self.isActiveYearsRange) {
          self.activeFiltersCounter++;
        }

        if (self.isActiveMaxSpeedRange) {
          self.activeFiltersCounter++;
        }

        if (self.isActiveTotalDistRange) {
          self.activeFiltersCounter++;
        }

        if (self.isActiveDistMoreThan20kmh) {
          self.activeFiltersCounter++;
        }

        if (self.isActiveAccelerationMoreThan3m) {
          self.activeFiltersCounter++;
        }

        if (self.isActiveEndContractRange) {
          self.activeFiltersCounter++;
        }

        if (self.communalFilter == "ue") {
          self.activeFiltersCounter++;
        }

        if (
          self.communalFilter &&
          self.communalFilter != "" &&
          self.communalFilter != "ue"
        ) {
          self.activeFiltersCounter++;
        }

        if (self.displayBucketPlayers) {
          self.activeFiltersCounter++;
        }

        if (self.displayNotCommentedPlayers) {
          self.activeFiltersCounter++;
        }

        if (self.displayCommentedPlayers) {
          self.activeFiltersCounter++;
        }
        if (
          self.footSelected == "left" ||
          self.footSelected == "right" ||
          self.footSelected == "both"
        ) {
          self.activeFiltersCounter++;
        }

        if (self.isActiveHeightRange) {
          self.activeFiltersCounter++;
        }

        if (self.isActiveWeightRange) {
          self.activeFiltersCounter++;
        }

        if (!self.activeFiltersCounter) {
          if (self.filterToUse !== "commentedGamesIdsLength") {
            getPlayersRef = getPlayersRef.where(
              self.filterToUse,
              "!=",
              "undefined"
            );
          }
        }

        if (scoutId?.length && scoutFilter?.length) {
          currentScoutPlayersForCurrentPeriod =
            await self.getPlayersAccodingURLFilters(
              "scout",
              scoutId,
              scoutFilter
            );
        }
        if (self.textFilterType) {
          if (self.textFilterType === "player") {
            await getPlayersRef
              .where("name", "==", self.playerNameOrClub)
              .get()
              .then((playersSnapshot) => {
                playersForSearchBar = playersSnapshot.docs.map((playerDoc) =>
                  playerDoc.data()
                );
              });
          } else {
            await getPlayersRef
              .where("currentTeam", "==", self.playerNameOrClub)
              .get()
              .then((playersSnapshot) => {
                playersForSearchBar = playersSnapshot.docs.map((playerDoc) =>
                  playerDoc.data()
                );
              });
          }
        }

        if (self.positionsSelected.length) {
          if (self.activeFiltersCounter === 1) {
            await getPlayersRef
              .where("primaryPosition", "in", self.positionsSelected)
              .orderBy(self.filterToUse, "desc")
              .get()
              .then((playersSnapshot) => {
                playersForSelectedPosition = playersSnapshot.docs.map(
                  (playerDoc) => playerDoc.data()
                );
              });
          } else {
            await getPlayersRef
              .where("primaryPosition", "!=", "undefined")
              .where("primaryPosition", "in", self.positionsSelected)
              .get()
              .then((playersSnapshot) => {
                playersForSelectedPosition = playersSnapshot.docs.map(
                  (playerDoc) => playerDoc.data()
                );
              });
          }
        }

        if (self.isActivePriceRange) {
          await getPlayersRef
            .where("price", ">=", parseFloat(self.priceRange[0]))
            .where("price", "<=", parseFloat(self.priceRange[1]))
            .where("price", "!=", "undefined")
            .orderBy("price")
            .get()
            .then((playersSnapshot) => {
              playersForPriceRange = playersSnapshot.docs.map((playerDoc) =>
                playerDoc.data()
              );
            });
        }

        if (self.isActiveYearsRange) {
          const lowestDate = moment(self.yearsRange[0], "YYYY").unix();
          const biggestDate = moment(self.yearsRange[1], "YYYY").unix();
          await getPlayersRef
            .where("dateOfBirth", "!=", "undefined")
            .where("dateOfBirth", ">=", lowestDate)
            .where("dateOfBirth", "<=", biggestDate)
            .orderBy("dateOfBirth")
            .get()
            .then((playersSnapshot) => {
              playersForYearsRange = playersSnapshot.docs.map((playerDoc) =>
                playerDoc.data()
              );
            });
        }
        if (self.isActiveMaxSpeedRange) {
          await getPlayersRef
            .where("physicalStats.rkSpeed", "!=", "undefined")
            .where("physicalStats.rkSpeed", ">=", self.maxSpeedRange[0] / 100)
            .where("physicalStats.rkSpeed", "<=", self.maxSpeedRange[1] / 100)
            .orderBy("physicalStats.rkSpeed")
            .get()
            .then((playersSnapshot) => {
              playersForMaxSpeed = playersSnapshot.docs.map((playerDoc) =>
                playerDoc.data()
              );
            });
        }
        if (self.isActiveTotalDistRange) {
          await getPlayersRef
            .where("physicalStats.rkDist", "!=", "undefined")
            .where("physicalStats.rkDist", ">=", self.totalDistRange[0] / 100)
            .where("physicalStats.rkDist", "<=", self.totalDistRange[1] / 100)
            .orderBy("physicalStats.rkDist")
            .get()
            .then((playersSnapshot) => {
              playersForTotalDist = playersSnapshot.docs.map((playerDoc) =>
                playerDoc.data()
              );
            });
        }
        if (self.isActiveDistMoreThan20kmh) {
          await getPlayersRef
            .where("physicalStats.rkHighSpeedRunning", "!=", "undefined")
            .where(
              "physicalStats.rkHighSpeedRunning",
              ">=",
              self.distMoreThan20kmhRange[0] / 100
            )
            .where(
              "physicalStats.rkHighSpeedRunning",
              "<=",
              self.distMoreThan20kmhRange[1] / 100
            )
            .orderBy("physicalStats.rkHighSpeedRunning")
            .get()
            .then((playersSnapshot) => {
              playersForDistMoreThan20kmh = playersSnapshot.docs.map(
                (playerDoc) => playerDoc.data()
              );
            });
        }
        if (self.isActiveAccelerationMoreThan3m) {
          await getPlayersRef
            .where("physicalStats.rkAccelerationMoreThan3m", "!=", "undefined")
            .where(
              "physicalStats.rkAccelerationMoreThan3m",
              ">=",
              self.accelerationMoreThan3mRange[0] / 100
            )
            .where(
              "physicalStats.rkAccelerationMoreThan3m",
              "<=",
              self.accelerationMoreThan3mRange[1] / 100
            )
            .orderBy("physicalStats.rkAccelerationMoreThan3m")
            .get()
            .then((playersSnapshot) => {
              playersForAccelerationMoreThan3m = playersSnapshot.docs.map(
                (playerDoc) => playerDoc.data()
              );
            });
        }
        if (self.isActiveEndContractRange) {
          await getPlayersRef
            .where("yearsLeft", "!=", "undefined")
            .where("yearsLeft", ">=", self.endContractRange[0] / 12)
            .where("yearsLeft", "<=", self.endContractRange[1] / 12)
            .orderBy("yearsLeft")
            .get()
            .then((playersSnapshot) => {
              playersForAvailability = playersSnapshot.docs.map((playerDoc) =>
                playerDoc.data()
              );
            });
        }
        if (self.communalFilter == "ue") {
          await getPlayersRef
            .where("isUePlayer", "==", true)
            .where("isUePlayer", "!=", "undefined")
            .get()
            .then((playersSnapshot) => {
              playersForSelectedGeolocalisation = playersSnapshot.docs.map(
                (playerDoc) => playerDoc.data()
              );
            });
        } else if (
          self.communalFilter &&
          self.communalFilter != "" &&
          self.communalFilter != "ue"
        ) {
          await getPlayersRef
            .where("isUePlayer", "==", false)
            .where("isUePlayer", "!=", "undefined")
            .get()
            .then((playersSnapshot) => {
              playersForSelectedGeolocalisation = playersSnapshot.docs.map(
                (playerDoc) => playerDoc.data()
              );
            });
        }

        if (self.displayBucketPlayers) {
          await getPlayersRef
            .where("isInBucket", "==", true)
            .where("isInBucket", "!=", "undefined")
            .get()
            .then((playersSnapshot) => {
              bucketPlayers = playersSnapshot.docs.map((playerDoc) =>
                playerDoc.data()
              );
            });
        }

        if (self.displayNotCommentedPlayers) {
          await getPlayersRef
            .where(self.filterToUse, "==", 0)
            .get()
            .then((playersSnapshot) => {
              playersSnapshot.docs.forEach((playerDoc) =>
                playersWithNoComment.push(playerDoc.data())
              );
            });
        }

        if (self.displayCommentedPlayers) {
          const getCurrentCommentedScope = "commentedGamesIdsLength";
          await getPlayersRef
            .where(getCurrentCommentedScope, ">", 0)
            .where(getCurrentCommentedScope, "!=", "undefined")
            .orderBy(getCurrentCommentedScope)
            .get()
            .then((playersSnapshot) => {
              playersWithComments = playersSnapshot.docs.map((playerDoc) =>
                playerDoc.data()
              );
            });
        }
        if (self.displayBucketPlayers && self.displayNotCommentedPlayers) {
          self.activeFiltersCounter--;
          bucketPlayers = [...bucketPlayers, ...playersWithNoComment];
          playersWithNoComment = [];
        } else if (self.displayBucketPlayers && self.displayCommentedPlayers) {
          self.activeFiltersCounter--;
          bucketPlayers = [...bucketPlayers, ...playersWithComments];
          playersWithComments = [];
        }

        if (
          self.footSelected == "left" ||
          self.footSelected == "right" ||
          self.footSelected == "both"
        ) {
          await getPlayersRef
            .where("foot", "==", self.footSelected)
            .where("foot", "!=", "undefined")
            .get()
            .then((playersSnapshot) => {
              playersByFoot = playersSnapshot.docs.map((playerDoc) =>
                playerDoc.data()
              );
            });
        }

        if (self.isActiveHeightRange) {
          await getPlayersRef
            .where("height", "!=", "undefined")
            .where("height", ">=", self.heightRange[0])
            .where("height", "<=", self.heightRange[1])
            .orderBy("height")
            .get()
            .then((playersSnapshot) => {
              playersForHeightRange = playersSnapshot.docs.map((playerDoc) =>
                playerDoc.data()
              );
            });
        }

        if (self.isActiveWeightRange) {
          await getPlayersRef
            .where("weight", "!=", "undefined")
            .where("weight", ">=", self.weightRange[0])
            .where("weight", "<=", self.weightRange[1])
            .orderBy("weight")
            .get()
            .then((playersSnapshot) => {
              playersForWeightRange = playersSnapshot.docs.map((playerDoc) =>
                playerDoc.data()
              );
            });
        }

        const ABCDPlayers = [];
        if (self.lettersSelected.length) {
          for (let i = 0; i < self.lettersSelected.length; i++) {
            await db
              .collection("V3players")
              .where(`note${self.lettersSelected[i]}IdsLength`, ">", 0)
              .get()
              .then((playersSnapshot) => {
                playersSnapshot.docs.forEach((playerDoc) => {
                  ABCDPlayers.push(playerDoc.data());
                });
              });
          }
        }

        let matchingPlayers = [
          ...playersForSearchBar,
          ...playersForSelectedPosition,
          ...playersForSelectedGeolocalisation,
          ...bucketPlayers,
          ...playersWithNoComment,
          ...playersWithComments,
          ...playersByFoot,
        ];

        if (scoutId?.length && scoutFilter?.length) {
          matchingPlayers = [
            ...currentScoutPlayersForCurrentPeriod,
            ...matchingPlayers,
          ];
        }

        if (self.isActivePriceRange) {
          matchingPlayers = [...matchingPlayers, ...playersForPriceRange];
        }

        if (self.isActiveYearsRange) {
          matchingPlayers = [...matchingPlayers, ...playersForYearsRange];
        }
        if (self.isActiveEndContractRange) {
          matchingPlayers = [...matchingPlayers, ...playersForAvailability];
        }
        if (self.isActiveMaxSpeedRange) {
          matchingPlayers = [...matchingPlayers, ...playersForMaxSpeed];
        }
        if (self.isActiveTotalDistRange) {
          matchingPlayers = [...matchingPlayers, ...playersForTotalDist];
        }
        if (self.isActiveDistMoreThan20kmh) {
          matchingPlayers = [
            ...matchingPlayers,
            ...playersForDistMoreThan20kmh,
          ];
        }
        if (self.isActiveAccelerationMoreThan3m) {
          matchingPlayers = [
            ...matchingPlayers,
            ...playersForAccelerationMoreThan3m,
          ];
        }
        if (self.isActiveHeightRange) {
          matchingPlayers = [...matchingPlayers, ...playersForHeightRange];
        }
        if (self.isActiveWeightRange) {
          matchingPlayers = [...matchingPlayers, ...playersForWeightRange];
        }

        if (self.lettersSelected.length) {
          matchingPlayers = [...matchingPlayers, ...ABCDPlayers];
        }

        const groupedPlayers = self.lodash.groupBy(matchingPlayers, "id");

        const usefullPlayers = [];
        Object.keys(groupedPlayers).forEach((groupIndex) => {
          if (groupedPlayers[groupIndex].length === self.activeFiltersCounter) {
            usefullPlayers.push(groupedPlayers[groupIndex][0]);
          }
        });

        self.setMatchingPlayers(usefullPlayers);
      }
    },
    getCurrentPlayerColorLegend(player) {
      return player.id + "_" + this.getPlayerStatus(player)[0];
    },
    checkMinValue(e) {
      if (e.target.value < e.srcElement.min) {
        e.target.value = e.srcElement.min;
      }
    },
    getPlayerStatus(player) {
      const self = this;
      if (player) {
        let checkedCommentedGamesIdsLength = 0;
        if (!self.getCurrentScout.isManager) {
          checkedCommentedGamesIdsLength =
            player[`${self.getCurrentScout.id}CommentedGamesIdsLength`] || 0;
        } else {
          if (self.getCurrentScout.company !== self.getCompanies[0].id) {
            checkedCommentedGamesIdsLength =
              player[
                `${self.getCurrentScout.company}CommentedGamesIdsLength`
              ] || 0;
          } else {
            checkedCommentedGamesIdsLength =
              player.commentedGamesIdsLength || 0;
          }
        }
        return checkedCommentedGamesIdsLength;
      }
    },
    switchLayout(newLayout) {
      if (newLayout == "bloc") {
        this.perPage = 20;
      } else {
        this.perPage = 40;
      }
      this.layout = newLayout;
    },
    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    wantCreatePlayer() {
      this.sidebarCreatePlayer.display = true;
    },
  },
  filters: {
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
  },
  watch: {
    playersFromApi: {
      handler() {
        this.generateDatalist();
      },
      deep: true,
    },
    lettersSelected: {
      deep: true,
      handler() {
        this.adaptMatchingPlayersList();
      },
    },
    displayCommentedPlayers() {
      this.adaptMatchingPlayersList();
    },
    getCompanies: {
      handler(newValue) {
        if (newValue.length && !this.matchingPlayers.length) {
          this.adaptMatchingPlayersList("onMount");
        }
      },
    },
    textFilterType() {
      if (this.textFilterType !== "club") {
        this.lettersSelected = [];
      }
    },
    playerNameOrClub() {
      const self = this;

      if (self.playerNameOrClub && self.playerNameOrClub !== "") {
        const matchingIndex = self.lodash.findIndex(self.orderedDatalist, {
          name: self.playerNameOrClub,
        });
        if (matchingIndex === -1) {
          return;
        }
        self.textFilterType = self.orderedDatalist[matchingIndex].item;
      }
    },
    getCurrentScout: {
      handler(updatedScout, initialScout) {
        if (initialScout?.id !== updatedScout?.id) {
          this.adaptMatchingPlayersList("onMount");
        }
      },
      deep: true,
    },
    currentPage() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.setMatchingPlayers(this.matchingPlayers);
    },
    perPage() {
      this.currentPage = 1;
      this.setMatchingPlayers(this.matchingPlayers);
    },
    getCurrentScoutPlayers() {
      this.adaptMatchingPlayersList("onMount");
    },
    orderMatchingPlayersBy() {
      const self = this;
      self.currentPage = 1;
      self.setMatchingPlayers(self.matchingPlayers);
    },
    filterMatchingPlayersBy() {
      const self = this;
      self.currentPage = 1;
      self.setMatchingPlayers(self.matchingPlayers);
    },
    scouts() {
      if (this.scouts.length && !this.scoutsPlayers.length) {
        this.bindScoutsPlayers();
      }
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/variables.scss";

.players .container > .row {
  width: 100%;
  margin: 0 auto;
}

.playerStatus {
  position: relative;
  cursor: default;
}

.playerStatus.isManager:after {
  position: absolute;
  left: 100%;
  margin-left: 5px;
  width: 10px;
  cursor: pointer;
  content: url(../assets/img/info.svg);
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.loadingContainer p {
  margin-bottom: 0;
  margin-right: 30px;
}

.loadingContainer .loadingContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingContent {
  position: relative;
  width: 9px;
  height: 9px;
  border-radius: 5px;
  background-color: #2c1fd6;
  color: #2c1fd6;
  animation: dot-flashing 0.4s infinite linear alternate;
  animation-delay: 0.5s;
}

.loadingContent::before,
.loadingContent::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.loadingContent::before {
  left: -15px;
  width: 9px;
  height: 9px;
  border-radius: 5px;
  background-color: #2c1fd6;
  color: #2c1fd6;
  animation: dot-flashing 0.4s infinite alternate;
  animation-delay: 0.3s;
}

.loadingContent::after {
  left: 15px;
  width: 9px;
  height: 9px;
  border-radius: 5px;
  background-color: #2c1fd6;
  color: #2c1fd6;
  animation: dot-flashing 0.4s infinite alternate;
  animation-delay: 0.8s;
}

@keyframes dot-flashing {
  0% {
    background-color: #2c1fd6;
  }

  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.noteCounterBullet {
  border-radius: 100px;
  padding: 5px 0 !important;
  flex: 1;
  margin: 0 3px;
  text-align: center;
}

.noteCounterBullet:nth-child(1) {
  background-color: #060052;
  color: white;
  margin-left: 0;
}

.noteCounterBullet:nth-child(2) {
  background-color: #2c1fd6;
  color: white;
}

.noteCounterBullet:nth-child(3) {
  background-color: #e3e0ff;
  color: #2c1fd6;
}

.noteCounterBullet:nth-child(4) {
  background-color: #f9f9f9;
  color: #060052;
  margin-right: 0;
}

.playerCriteriiContainer:nth-child(even) {
  font-weight: 700;
  margin-bottom: 0;
}

.playerCriteriiContainer:nth-child(odd):after {
  content: "";
  height: 1px;
  width: calc(100% - 30px);
  margin: 0 auto;
  display: block;
  background: #f0f0f0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.playerCriteriiContainer.notesCounterContainer:nth-child(odd):after {
  width: 100%;
}

.playerCriteriiContainer:not(.notesCounterContainer) > .col:last-of-type {
  text-align: right;
  padding-right: 0;
}

.playerCriteriiContainer:not(.notesCounterContainer) > .col:first-of-type {
  text-align: left;
  padding-left: 0;
}

.playerCriteriiContainer {
  display: flex;
  word-break: break-word;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  font-family: "Proxima Nova", sans-serif;
  min-height: 21px;
}

.playerCriteriiContainer.notesCounterContainer {
  font-size: 0.7rem;
  text-align: center;
  margin: 5px auto !important;
  width: 100%;
  min-width: 300px;
  display: flex !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 40px;
}

.cardContent .playerCriteriiContainer.notesCounterContainer {
  min-width: initial;
  height: 41px;
  align-items: center;
  justify-content: center;
  margin-top: 0 !important;
  padding-bottom: 5px !important;
}

.popover .playerCriteriiContainer.notesCounterContainer {
  height: 20px;
}

.playerCriteriiContainer:last-of-type:after {
  display: none;
}

.contributedOnlyContainer {
  font-size: 0.6rem;
  background-color: #f6f5ff;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  color: #495057;
}

.contributedOnlyContainer input[type="checkbox"] {
  -ms-transform: scale(1.5);
  /* IE */
  -moz-transform: scale(1.5);
  /* FF */
  -webkit-transform: scale(1.5);
  /* Safari and Chrome */
  -o-transform: scale(1.5);
  /* Opera */
  transform: scale(1.5);
  padding: 10px;
  margin-right: 10px;
}

.contributedOnlyContainer label {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.filterHeader {
  height: 2rem;
}

.filterInputContainer {
  height: 2rem;
  flex: 1;
}

#lettersSelectorContainer {
  position: relative;
}

#lettersSelectorContainer:after {
  position: absolute;
  right: 10px;
  content: url("../assets/img/arrowSelector.svg");
  width: 14px;
  height: 10px;
  display: flex;
  align-items: center;
  transition: 0.5s;
}

#lettersSelectorContainer.activeFilter:after {
  transform: rotate(180deg) translate(0px, 3px);
}

.filterInputContainer .filterInputContent {
  display: flex;
  border: 1px solid #dbdbdb;
  height: 2rem;
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  width: 100%;
  position: relative;
  height: 48px;
  align-items: center;
}

.filterInputContainer .filterInputContent.activeFilter {
  border: 2px solid black;
}

.filterInputPlaceholder {
  font-size: 0.7rem;
  width: 100%;
  cursor: pointer;
}

.lettersSelected {
  border-radius: 3px;
  font-size: 0.8rem;
  padding: 8px 12px;
  background: #141464;
  color: white;
  position: relative;
  cursor: pointer;
}

.lettersSelected:not(:first-of-type) {
  margin-left: 10px;
}

.lettersSelected:after {
  content: "X";
  color: white;
  margin-left: 10px;
}

.selectorOptionsContainer {
  position: absolute;
  top: calc(100% + 10px);
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  left: 0;
  right: 0;
  z-index: 100;
  margin: 0 auto;
}

.selectorOptionContainer {
  position: relative;
  flex: 1;
  border: 1px solid #dbdbdb;
  background-color: #f1f1f1;
  padding: 16px 16px 16px 32px;
  cursor: pointer;
}

.selectorOptionContainer:hover {
  background-color: rgb(228, 228, 228);
}

.selectorOptionContainer:before {
  position: absolute;
  content: url("../assets/img/checkbox_unchecked.svg");
  width: 10px;
  height: 10px;
  border-radius: 2px;
  left: 10px;
}

.selectorOptionContainer.checked {
  background-color: white;
}

.selectorOptionContainer.checked:before {
  content: url("../assets/img/checkbox_checked.svg");
}

.filterInputContent[type="text"] {
  font-size: 0.6rem;
}

.searchByTextContainer {
  display: flex;
  align-items: center;
  position: relative;
}

#loopContainer {
  position: absolute;
  right: 22px;
  top: 0;
  width: 20px;
  height: 100%;
  cursor: pointer;
}

#loopContainer img {
  width: 100%;
  height: 100%;
}

.errorsContainer {
  color: #060052;
}

.searchPlayerInputContainer[disabled="disabled"] {
  background-color: grey;
  cursor: not-allowed;
}

.topSearchCTAContainer {
  display: flex;
}

.disableButtonContainer {
  background-color: grey;
  cursor: not-allowed;
}

#matchingPlayersStatusContainer {
  color: black;
}

.ratingStar {
  width: 15px;
  margin: 0 10px 0 5px;
}

#footerResultContent {
  display: inline-flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
  color: $blueDark;
}

#selectNumberOfResultContainer {
  display: none;
  margin: 0 20px 0 0;
}

#selectNumberOfResultContainer div {
  padding: 0 3px;
  display: inline-flex;
  align-items: center;
}

#selectNumberOfResultContainer input {
  max-width: 60px;
  border-radius: 5px;
  border: 0;
  padding: 3px 5px;
}

.activator-wrapper > label {
  display: none;
}

.mdrp-root input {
  text-align: center;
  border-bottom: 0 !important;
}

.mdrp-root,
.mdrp-root input,
.mdrp__activator .activator-wrapper .bar {
  font-family: "Montserrat", sans-serif !important;
  max-width: 100%;
}

.mdrp__activator .activator-wrapper .bar:after,
.mdrp__activator .activator-wrapper .bar:before {
  background: $blueDark !important;
}

.mdrp__panel .ranges {
  display: none;
}

.searchNavTitleContainer h2 {
  font-weight: bold;
}

.searchNavTitleContainer .col-md-3 {
  flex: 0 0 22% !important;
  max-width: 22% !important;
  margin-right: 5px;
}

.searchNavContainer {
  width: 100%;
}

.searchNavContainer .col-md-4 {
  display: flex !important;
  align-items: center !important;
}

.searchNavContainer input {
  border-radius: 8px;
  padding: 5px 10px;
  border: 0;
  width: 100%;
}

.searchNavLayoutSwitcherContainer {
  display: flex;
  justify-content: flex-end;
}

.searchNavLayoutSwitcherContainer > .row {
  justify-content: space-evenly;
}

.filtersSectionContainer {
  margin-left: 0;
  margin-right: 0;
}

.filtersSectionContent {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e3e0ff;
  padding: 24px;
}

.filtersSectionContent h3 {
  font-size: 1rem;
  font-weight: 700;
  margin-right: 10px;
}

.filtersSectionContent > .row {
  width: 100%;
  margin: 0;
}

#orderBy {
  border-radius: 8px;
  padding: 5px 10px;
  border: 0;
}

.searchNavPictoContainer {
  opacity: 0.25;
  transition: 0.2s;
}

.searchNavPictoContainer:hover {
  opacity: 1;
}

.searchNavPictoContainer.activePicto {
  opacity: 1;
}

.moreFiltersContainer {
  text-align: center;
  font-size: 0.8rem;
  font-weight: 700;
  color: #060052;
  border-bottom: 1px solid #060052;
  cursor: pointer;
}

.moreFiltersContainer:hover {
  color: rgba(5, 0, 82, 0.8);
}

#moreFiltersContainer {
  justify-content: flex-start;
}

#moreFiltersContainer span {
  text-decoration: underline;
}

.searchNavPictoContainer,
.filtersPictoContainer {
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.filtersPictoContainer {
  justify-content: flex-end;
  padding-right: 20px !important;
}

.searchNavPictoContainer img {
  cursor: pointer;
}

.redBullet {
  background: #e93939;
  color: white;
}

.yellowBullet {
  background: #f8e770;
  color: black;
}

.blueBullet {
  background: #4949db;
  color: white;
}

.bulletInfoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.colu-2 {
  flex: 2 !important;
}

.colu-5 {
  flex: 5 !important;
}

.colu-8 {
  flex: 8 !important;
}

.colu-10 {
  flex: 10 !important;
}

.listContentItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: #060052;
  cursor: pointer;
}

.uppercaseColumn {
  text-transform: uppercase;
}

.lowercaseColumn {
  text-transform: lowercase;
}

.listContentItem:hover {
  text-decoration: none;
}

.rowListContainer {
  flex-direction: column;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0 15px !important;
  padding: 0 15px !important;
  background: white;
}

.rowListContainer > .col {
  padding: 0;
}

.headerListContainer:first-of-type {
  max-width: 100% !important;
  width: 100% !important;
  margin: 0 15px;
  background: #141414;
  padding: 10px 20px !important;
  color: #ffffff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.headerListContainer .col {
  text-align: center;
  padding: 0;
}

.listContainer {
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  border-radius: 4px;
}

.listContent {
  display: flex;
  flex-direction: revert;
  justify-content: space-around;
  text-align: center;
  margin: 10px 0px !important;
  width: 100%;
  border: 1px solid #f0f0f0;
  padding: 3px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  height: 50px;
}

.cardContainer .listContent {
  margin: 0 !important;
}

.listContent:hover {
  text-decoration: none !important;
}

.listFilterParameters {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  cursor: pointer;
}

.filterParameters {
  margin-bottom: 10px;
}

.filterParameters label,
.filterParameters input {
  cursor: pointer;
}

.popover {
  max-width: fit-content;
  min-width: fit-content;
  width: fit-content;
  border: 1px solid #060052 !important;
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #060052 !important;
}

.popover input {
  max-width: 100%;
}

.popover-header {
  background-color: rgba(10, 0, 143, 0.305) !important;
  color: white;
}

#filterResults {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  font-size: 0.9rem;
}

.page-link {
  color: $blueDark !important;
}

.page-item.active .page-link {
  background-color: $blueDark !important;
  color: white !important;
  border-color: transparent !important;
}

#filterResults .pagination {
  display: inline-flex;
  margin-bottom: 0;
}

.matchingResultsContainer {
  display: flex;
}

.matchingResultsContent {
  color: black;
  font-size: 0.8rem;
  margin: 2px 0;
  text-decoration: underline;
}

#cardsContainer {
  width: 100%;
  margin: 0px auto;
}

.cardContainer {
  display: flex;
  padding: 0 12px !important;
}

.cardContentMainPositionContainer {
  position: relative;
  color: white;
  font-weight: bold;
  z-index: 1;
  overflow: hidden;
}

.cardContentMainPositionContainer:before {
  content: "";
  display: flex;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background: #8b95ea;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: 50%;
}

.cardContentName {
  margin: 10px 0;
  font-weight: 500;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: left;
  font-family: "Proxima Nova", sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.5rem;
}

.pictoSwitchLayout {
  width: 20px;
  height: 20px;
}

.vue-slider-dot-tooltip-inner {
  background-color: $blueDark !important;
}

.vue-slider-dot-handle {
  border-radius: 0 !important;
  background-color: rgb(51, 44, 145) !important;
}

.vue-slider-process {
  background-color: rgba(10, 0, 143, 0.5) !important;
}

.vue-slider-dot-handle::after {
  background-color: rgba(10, 0, 143, 0.205) !important;
}

.cardContentItem {
  text-align: left;
  margin: 7px 0;
  color: #060052;
}

.cardContentItem span {
  font-weight: bold;
}
</style>
<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.players .b-pagination li {
  display: none;
}

.pagination .page-item.disabled .page-link {
  display: flex !important;
  height: 30px !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 5px !important;
  background-color: #e6e6e6 !important;
  color: #383838 !important;
  font-weight: bold !important;
}

.pagination button.page-link {
  margin: 0 5px !important;
  border-radius: 5px !important;
}

.pagination li.page-item {
  margin: 0 5px !important;
}

.pagination li.page-item button {
  border-radius: 6px !important;
  font-size: 0.7rem !important;
  height: 30px !important;
}

.header {
  padding-bottom: 0;
}

.main {
  background-color: #f6f5ff;
  margin-top: 0;
}

.resetButtonContainer {
  width: 20px;
  margin-bottom: 0px;
  margin-left: 15px;
}

.resetButtonContainer img {
  width: 100%;
}

.resetButtonContainer {
  text-align: center;
}

.resetButton {
  border: 1px solid $blueDark;
  color: $blueDark;
  border-radius: 0px;
  padding: 2px 15px;
  margin: 10px auto 0 auto;
  background: transparent;
  font-weight: 500;
}

.resetButton:hover {
  background: #d7d7f2;
}

.cardLink {
  display: flex;
  flex-direction: revert;
  justify-content: space-around;
  text-align: center;
  margin: 10px 0px !important;
  width: 100%;
  padding: 3px;
  position: relative;
  overflow: hidden;
}

.cardLink:hover {
  text-decoration: none !important;
}

.cardContent {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  padding: 0px 15px 5px 15px;
  border-radius: 5px;
  background: white;
  color: $blueDark;
  flex: 1;
  position: relative;
  width: 100%;
}

.isGolden {
  border: 2px solid #c88c28;
}

.filtersTitleContainer {
  font-weight: bold;
  padding-bottom: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.filtersTitleContainer:after {
  position: absolute;
  display: block;
  bottom: 0;
  height: 1px;
  background: #8b95ea;
  content: "";
  width: 100%;
}

.filterKeyboardSelector > .col {
  display: flex;
  flex-direction: column;
}

.filterKeyboardSelector > .col:first-of-type {
  padding-left: 0;
}

.filterKeyboardSelector > .col:last-of-type {
  padding-right: 0;
}

.filterKeyboardSelector span {
  border: 1px solid #8b95ea;
  text-align: center;
  color: $blueDark;
  min-width: 60px;
  padding: 5px 10px;
}

.playerSelectContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.playerSelectContainer label {
  padding-left: 5px;
  padding-right: 10px;
  cursor: pointer;
  margin-bottom: 0;
}

.playerSelectContainer input {
  cursor: pointer;
}

.playerSelectContainer img {
  margin: 0 3px;
}

#filterListAndResultContainer {
  display: flex;
  margin-top: 0;
  padding: 0;
  width: calc(100% - 30px);
}

.playerPositionsContainer {
  max-height: 200px;
  overflow-y: scroll;
  padding: 0;
}

.playerPositionsContainer::-webkit-scrollbar {
  width: 10px;
}

.playerPositionsContainer::-webkit-scrollbar-thumb {
  background: $blueDark;
  border-radius: 0.75rem;
}

.profile-row .buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1rem;
}

.alertContainer {
  bottom: 30px;
  position: fixed;
  right: 40px;
  display: flex;
  flex-direction: column;
  word-break: break-all;
  background: white;
  border-radius: 20px;
  box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 2%), 0 6.7px 5.3px rgb(0 0 0 / 3%),
    0 12.5px 10px rgb(0 0 0 / 4%), 0 22.3px 17.9px rgb(0 0 0 / 4%),
    0 41.8px 33.4px rgb(0 0 0 / 5%), 0 100px 80px rgb(0 0 0 / 7%);
  overflow: hidden;
  z-index: 100;
}

.alertContainer.extendAlert {
  width: 400px;
  height: auto;
  padding: 20px;
}

.alertContainer.collapseAlert {
  width: 60px;
  height: 50px;
  padding: 15px 20px 20px 20px;
}

.alertExit {
  display: block;
  text-align: right;
  width: 100%;
  color: #d62828;
  font-weight: bold;
  cursor: pointer;
}

.alertContent {
  max-height: 80vh;
  overflow-y: scroll;
  word-break: break-word;
}

.alertContent::-webkit-scrollbar {
  width: 10px;
}

.alertContent::-webkit-scrollbar-thumb {
  background: $blueDark;
  border-radius: 0.75rem;
}

.alertContent.collapseAlert {
  display: none;
}

.filtersContainer > .col {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 992px) {
  .contributedOnlyContainer label,
  .filterInputPlaceholder,
  .filterInputContent[type="text"],
  .moreFiltersContainer {
    font-size: 0.6rem;
  }

  .profile-row .buttonsContainer button {
    font-size: 0.8rem;
  }

  .listContent {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .profile-row .buttonsContainer button {
    font-size: 0.6rem;
  }

  .filterHeader:not(:first-of-type),
  .filterHeader:not(:last-of-type) {
    margin-top: 20px;
  }

  .filterInputContainer {
    height: auto;
  }

  .filtersContainer {
    flex-direction: column;
    font-size: 0.8rem;
  }

  .filtersContainer > .col:last-of-type > .filterInputContainer {
    margin-top: 20px;
  }

  .filtersPictoContainer {
    margin: 10px auto;
  }

  .searchNavContainer {
    flex-direction: column-reverse;
  }

  .searchNavContainer input {
    margin-bottom: 10px;
  }

  #switchLayoutToListContainer,
  #switchLayoutToBlocContainer {
    display: none;
  }

  #filterResults {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
  }

  #cardsContainer {
    display: flex !important;
  }

  .headerListContainer,
  .rowListContainer {
    display: none;
  }

  #selectNumberOfResultContainer {
    display: none;
  }

  .listContent {
    font-size: 0.7rem;
  }

  .headerListContainer .col {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 576px) {
  .cardContainer {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
  }
}
</style>
